<template>
    <div class="cointainer">
        <div class="row" >
            <div class="col-12">

                <div class="v17_34"></div>
                
            </div>
        </div>
    </div>
    
    <div>
        <p>test jwt</p>
        <button @click="dataGet" >Get Data</button> <!-- harus ada jeda supaya tidak error (lihat nbf di server) -->
        <button @click="dataPost" >Post Data</button> <!-- harus ada jeda supaya tidak error (lihat nbf di server) -->
    </div>
    
</template>
<script>
import $ from 'jquery'
import { nytMixin } from "../assets/js/myMixin.js";

export default {
  
  mixins: [nytMixin],
  data() {
    return{
        myToken : [],
        isDisabled: 'disabled',
    }
  },
  beforeMount() {
    this.GetToken();
  },
  mounted(){ 
    
  },
  updated() {
    
  },
  methods: { 
    async GetToken(){
      
        const resToken = await this.FuncGetToken();
        this.myToken = resToken.data;
        console.log(this.myToken);
        
        
    },
    async dataPost()
    {
        if(this.myToken)
        {
            //console.log(this.myToken.data.token);
            var fuckingVariable = this.myToken.data.token;
            //var fuckingVariable = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhcHByZXN0c2VydmljZSIsImF1ZCI6InBlbmdndW5hIiwiaWF0IjoxNjc5Mjc5MDc1LCJuYmYiOjE2NzkyNzkwNzYsImV4cCI6MTY3OTI3OTEzNX0.rRbBPKIfFumDeLSeKfLw0g-1fugs97FyZsR_THYbFgw';
            var form_data = [];
            form_data["param1"] = "inparam1";
            form_data["param2"] = "inparam2";
            const resData = await this.testNewPost("https://admin.kartikasari.co.id/TestJWT/data_post", 
                              form_data, fuckingVariable);
            console.log("resData", resData);
        }
        else
        {
            console.log("tidak dapat token");
        }
    },
    async dataGet()
    {
        if(this.myToken)
        {
            //console.log(this.myToken.data.token);
            var fuckingVariable = this.myToken.data.token;
            //var fuckingVariable = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhcHByZXN0c2VydmljZSIsImF1ZCI6InBlbmdndW5hIiwiaWF0IjoxNjc5Mjc5MDc1LCJuYmYiOjE2NzkyNzkwNzYsImV4cCI6MTY3OTI3OTEzNX0.rRbBPKIfFumDeLSeKfLw0g-1fugs97FyZsR_THYbFgw';
            var form_data = [];
            const resData = await this.FuncGetJwt("https://kartikasari.co.id/TestJWT/data_get", 
                              form_data, fuckingVariable);
            console.log("resData", resData);
        }
        else
        {
            console.log("tidak dapat token");
        }
    }
    
    
  }
  
};
</script>

<style>
* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v17_34 {
  width: 100px;
  height: 70px;
  background: url("../assets/images/v2_17.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
</style>