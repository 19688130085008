<template>
   
    <!-- ***** Main Banner Area Start ***** -->
    <section :class="this.isMobile() ? '' : 'mt-5'" class="section pt-0"
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 110px !important;' : ''">
        <div class="container text-left">
            <div class="row">
                <!-- Sales person -->
                <div class="col-12 w-100 p-0 m-0">
                    <div class="card w-100 px-0 mx-0 py-2 my-2">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-9 "> 
                                    <input v-model="valueSearch" @change="btnSearch" 
                                        class="form-control form-control-sm roundedAll" 
                                        type="text" name="searchSales" placeholder="Search" />
                                </div>
                                <div class="col-3 ml-0 pl-0"> 
                                    <button @click="btnSearch" class="form-control form-control-sm btn-ksm">Search</button>
                                </div>

                                <div class="col-12 my-2" style="height: 450px; overflow: scroll;" > 

                                    <div v-for="(person, index) in employes" :key="index" 
                                        class="card shadows bg-white rounded w-100 p-0 mx-0 my-2" 
                                        
                                        @click="showQr(person.Nama)">
                                        <div class="row w-100">  
                                            <div class="col-3 d-flex justify-content-center">
                                                <img v-if="person.GambarPath" class="colorCar d-block w-75 m-2" style="border-radius: 50%;" 
                                                    :data-image="person.GambarPath"
                                                    :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+ person.GambarPath +'&size=1000'">                             
                                                <img v-else class="colorCar d-block w-75 m-2" 
                                                    style="border-radius: 50%;" 
                                                    src="https://kartikasari.co.id/assets/img/ic_profile_picture.png">                            
                                                </div> 
                                                
                                            <div class="col-9 d-flex justify-content-left">
                                                <div class="row">
                                                    <label class="col-12 w-100 m-0 p-0 text-left" ><small>{{person.Nama}}</small></label>
                                                    <p class="col-12 w-100 m-0 p-0  text-left" ><small><em>{{person.Telp}}</em></small></p>
                                                </div>
                                            </div>     
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
    </section>

    <!-- Modal -->
    <div class="modal fade " id="qrModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <qrcode-vue :value="valueQr" :size="size" level="H" />
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
import QrcodeVue from 'qrcode.vue';
import $ from 'jquery';
export default {
    components: {
        QrcodeVue
    },
    mixins: [nytMixin],
    data(){
        return{
            employes:[],
            valueSearch: '',
            rawEmployes:[],
            valueQr: '',
            size: 300,
        }
    },
    setup() {
        
    },
    beforeMount() {
        this.GetSalesPerson();
    },
    methods:{
        async GetSalesPerson()
        {
            var form_data = {};   
            const resultData = await this.FuncGet("/Branch", form_data);
            this.employes = resultData.data.results;
            this.rawEmployes = resultData.data.results;
        },
        btnSearch: function(){
            if(this.valueSearch == "")
            {
                this.employes = this.rawEmployes;
            }
            else
            {
                var search = new RegExp(this.valueSearch , 'i');
                this.employes = this.rawEmployes.filter(x => search.test(x.Nama));
            }
        },
        showQr(Nama)
        {
            $("#qrModal").modal("show");
            this.valueQr = Nama;
        }
    }
}
</script>