<template>
    <nav aria-label="breadcrumb" :class="curScreen > 991 ? 'mt-5' : ''" 
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 110px;' : ''">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a class="removeBlueLink" href="#">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{parameter}}</li>
        </ol>
    </nav>
    <div v-if="resultNews.length > 0" class="row m-0 p-0 w-100">
        <div class="col-sm-4 col-md-3" >
            <div class="panel panel-default">
                <div class="panel-body ">
                    <a v-if="parameter == 'News And Event'" href="#"
                        class=" d-flex justify-content-center">
                        <img 
                            class="img-responsive" style="height: auto; width: 80%;"
                            :src="this.urlSite + resultNews[0].LinkImage"
                        >
                    </a>
                    <a v-if="parameter == 'Promo'" href="#"
                        class=" d-flex justify-content-center">
                        <img 
                            class="img-responsive" style="height: auto; width: 80%;"
                            :src="this.urlSite + resultNews[0].Link"
                        >
                    </a>
                    <a v-if="parameter == 'TipsTrick'" href="#"
                        class=" d-flex justify-content-center">
                        <img 
                            class="img-responsive" style="height: auto; width: 80%;"
                            :src="this.urlSite + resultNews[0].LinkImage"
                        >
                    </a>
                </div>
            </div>
        </div>
        <div class="col-sm-8 col-md-8 h-100" >
            <div  class="panel panel-default text-left">
                <div class="panel-footer">
                    <h6 >{{resultNews[0].CreateDate.substring(0,10)}}</h6>
                    <a class="removeBlueLink" v-if="parameter == 'News And Event'"  :href="'/DetailNews/'+resultNews[0].IdNews+'/'+parameter" >
                        <h5>{{resultNews[0].Judul}}</h5> 
                    </a>
                    <a class="removeBlueLink" v-if="parameter == 'Promo'" :href="'/DetailNews/'+resultNews[0].IdPromo+'/'+parameter" >
                        <h5>{{resultNews[0].Judul}}</h5> 
                    </a>
                    <a class="removeBlueLink" v-if="parameter == 'TipsTrick'" :href="'/DetailNews/'+resultNews[0].IdTipsTrick+'/'+parameter" >
                        <h5>{{resultNews[0].Judul}}</h5> 
                    </a>
                    
                    <div class="col-12 m-0 p-0">
                        <p v-if="parameter == 'News And Event' || parameter == 'TipsTrick'" class="d-inline" 
                            v-html="resultNews[0].Detail.substring(0,100) + '... '"></p>
                        <a v-if="parameter == 'News And Event'" :href="'/DetailNews/'+resultNews[0].IdNews+'/'+parameter" 
                            class="btn btn-sm btn-danger d-inline">Read More</a>
                        <a v-if="parameter == 'Promo'" :href="'/DetailNews/'+resultNews[0].IdPromo+'/'+parameter" 
                            class="btn btn-sm btn-danger d-inline">Read More</a>
                        <a v-if="parameter == 'TipsTrick'" :href="'/DetailNews/'+resultNews[0].IdTipsTrick+'/'+parameter" 
                            class="btn btn-sm btn-danger d-inline">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="resultNews.length > 0" class="row w-100 mx-0 my-2 p-0">
        <div class="col-12">
            <table id="dataNewsAndEvent" class="table w-100">
                <thead>
                <tr>
                    <th>Image</th>
                </tr>
                </thead>
                <tbody  class="row ml-0 mr-0 pl-0 pr-0">
                    <tr v-for="(news, index) in shiftNews" :key="index" 
                        class="odd col-sm-6 col-md-4 col-lg-3 col-xl-3 ml-0 mr-0 pl-0 pr-0">
                        <td>
                            <a class="removeBlueLink" :href="'/DetailNews/'+news.IdNews+'/'+parameter">
                                <div class="card shadow ml-0 mr-0 h-100 w-100">
                                    <img v-if="parameter == 'News And Event' || parameter == 'TipsTrick'" class="card-img-top" style="max-heigh: 250px;" 
                                        :src="this.urlSite +news.LinkImage" alt="Card image cap">
                                    <img v-if="parameter == 'Promo'" class="card-img-top" style="max-heigh: 250px;" 
                                        :src="this.urlSite + news.Link" alt="Card image cap">
                                    <div class="card-body text-center pt-0 mt-0 d-flex flex-column h-100">
                                        <p class="card-title"><small>{{news.CreateDate.substring(0,10)}}</small></p>
                                        <p class="card-text mt-auto">
                                            <strong>{{news.Judul}}</strong>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </td>
                    </tr>
                  </tbody>
            </table>
        </div>
    </div>
    <div v-if="resultNews.length <= 0" class="row w-100 m-0 p-0">
        <div class="col-12 " style="height: 300px;">
            <p class="text-center"><em>
                Data Kosong    
            </em></p>
        </div>
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
import $ from 'jquery'

export default {
    mixins: [nytMixin],
    data(){
        return{
            parameter: this.$route.params.param,
            resultNews: [],
            shiftNews: [],
            curScreen: screen.width,
        }
    },
    setup() {
        
    },
    beforeMount(){
        this.GetNews();
    },
    methods:{
        async GetNews()
        {
            var form_data = {};
            if(this.parameter == 'News And Event')
            {
                form_data['param'] = "News";
            }
            if(this.parameter == 'Promo')
            {
                form_data['param'] = "Promo";
            }
            if(this.parameter == 'TipsTrick')
            {
                form_data['param'] = "TipsTrick";
            }
            var resData = await this.FuncGet('/GetNews', form_data);
            if(resData.data.length > 0)
            {
                this.resultNews = resData.data;
                this.shiftNews = this.resultNews.slice(1);
            }
            //console.log(this.resultNews);
        }
    },
    updated()
    {
        $("#dataNewsAndEvent thead").hide();
        setTimeout(function() { $("#dataNewsAndEvent").DataTable({
            "bPaginate": false,
            "bInfo": false,
        }); }, 2000);
    }
}
</script>