<template>
    <div class="container-fluid">
        <div v-if="!isLoading" class="w-100">
            <facebook-loader :width="375" :speed="1" :animate="true" ></facebook-loader>
            <facebook-loader  :speed="1" :animate="true" ></facebook-loader>
            <facebook-loader  :speed="1" :animate="true" ></facebook-loader>
            <facebook-loader  :speed="1" :animate="true" ></facebook-loader>
            <facebook-loader  :speed="1" :animate="true" ></facebook-loader>
        </div>
        <div v-else class="row w-100 m-0 p-0 text-left">
            <div v-if="unitWashing.length > 0" class="col-12 w-100 mx-0 my-1 p-0 overflow-auto" 
                style="max-height: 150px;">
                <div v-for="(wash, index) in unitWashing" :key="index" class="card " style="background-color: #dea0a3;">
                    <div class="card-body m-1 p-1 shadow">
                        <small><label class="m-0 p-0"><strong>Free Voucher Washing Untuk :</strong></label><br>
                        <label class="m-0 p-0">{{ wash.Keterangan }} </label>
                        <label class="m-0 p-0" v-if="wash.NoRangka != '' && wash.NoRangka != null">({{ wash.NoRangka }}) </label>
                        <label class="m-0 p-0" v-if="wash.Nopol != '' && wash.Nopol != null">({{ wash.Nopol }})</label></small>
                    </div>
                </div>
                
            </div>
            <div class="col-12 w-100 mx-0 my-1 p-0 ">
                <label><strong>Voice Of Customer</strong></label>
                <form id="vocService" name="vocService" method="post" > 
                    <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0">Nomor Polisi 
                            <label class="float-right m-0 px-2 py-0 bg-ksm text-white roundedAll"
                                @click="showModal">Add Unit</label>
                        </legend>
                        <div class="col-sm-10">
                            <!--
                            <select class="form-control form-control-sm" name = "Nopol" id="Nopol" v-model="voc.NoRangka" >
                                <option v-for="(unit, index) in units" :key="index" :value="unit.NoRangka" >
                                    {{ unit.NamaKendaraan + ' (' + unit.NoPol + ')' }}
                                </option>

                            </select> -->

                            <input type="text" placeholder="-" class="form-control " id="Nopol" name = "Nopol"
                                list="units" @change="selectedUnit" v-model="voc.NoPol"/>
                            <datalist id="units">
                                <option v-for="(unit, index) in units" :key="index"  >
                                    {{ unit.NamaKendaraan + ' - ' + unit.NoPol }}
                                </option>
                            </datalist>
                        </div>
                    </div>
                    <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0">Domisili</legend>
                        <div class="col-sm-10">
                            <input class="form-control form-control-sm" name = "Domisili" id="Domisili" 
                                type="text" placeholder="Domisili Saat Ini" v-model="voc.Domisili" required>
                        </div>
                    </div>
                    <fieldset class="form-group" id="group2">
                        <div class="row">
                            <legend class="col-form-label col-sm-2 pt-0">Apa yang menjadi pertimbangan ketika melakukan servis ? </legend>
                            <div class="col-sm-10">
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" type="radio" v-model="voc.Pertimbangan" name="pertimbanganService" 
                                        id="gridRadios111" value="Periode Waktu (6 Bulan)" >
                                    <label class="form-check-label " for="gridRadios111">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Periode Waktu (6 Bulan)
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" type="radio" v-model="voc.Pertimbangan" name="pertimbanganService" 
                                        id="gridRadios112" value="Jarak Tempuh (kilometer)">
                                    <label class="form-check-label" for="gridRadios112">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Jarak Tempuh (kilometer)
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" type="radio" v-model="voc.Pertimbangan" name="pertimbanganService" 
                                        id="gridRadios113" value="Adanya keluhan pada kendaraan" >
                                    <label class="form-check-label" for="gridRadios113">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Adanya keluhan pada kendaraan
                                    </label>
                                </div>
                                
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class="form-group" id="group2">
                        <div class="row">
                            <legend class="col-form-label col-sm-2 pt-0">Manakah layanan Toyota yang anda butuhkan ?</legend>
                            <div class="col-sm-10">
                            
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" type="radio" name="layanan" v-model="voc.Layanan"
                                        id="gridRadios221" 
                                        value="Datang servis ke bengkel" required>
                                    <label class="form-check-label" for="gridRadios221">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Datang servis ke bengkel
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" type="radio" name="layanan" id="gridRadios222" v-model="voc.Layanan"
                                        value="Servis di bengkel menggunakan layanan antar jemput (Pick Up Delivery Service)" required>
                                    <label class="form-check-label" for="gridRadios222">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Servis di bengkel menggunakan layanan antar jemput (Pick Up Delivery Service)
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" type="radio" name="layanan" id="gridRadios223" v-model="voc.Layanan"
                                        value="Service dari rumah menggunakan Kartika Sari Care" required>
                                    <label class="form-check-label" for="gridRadios223">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Service dari rumah menggunakan Kartika Sari Care
                                    </label>
                                </div>
                                
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class="form-group">
                        <div class="row">
                            <legend class="col-form-label col-sm-2 pt-0">Apakah anda puas dengan layanan kami?</legend>
                            <div class="col-sm-10">
                            
                                <div class="form-check mx-0 px-0"> 
                                    <input class="form-check-input mx-0 px-0" type="radio" name="kepuasan" id="gridRadios1" 
                                        v-model="voc.Kepuasan" value="Puas" checked>
                                    <label class="form-check-label" for="gridRadios1">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Puas
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                <input class="form-check-input mx-0 px-0" type="radio" name="kepuasan" id="gridRadios2" 
                                    value="Tidak Puas" v-model="voc.Kepuasan">
                                <label class="form-check-label" for="gridRadios2">
                                    &nbsp;&nbsp;&nbsp;&nbsp; Tidak Puas
                                </label>
                            </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0">Berapa Nilai Tingkat Kepuasan Anda?
                            
                        </legend>
                        
                        <div class="col-sm-10 ml-2">
                            <div class="col-sm-6 col-lg-12">
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="1" id="defaultCheck1" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck1">
                                    1
                                </label>
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="2" id="defaultCheck2" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck2">
                                    2
                                </label>
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="3" id="defaultCheck3" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck3">
                                    3
                                </label>
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="4" id="defaultCheck4" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck4">
                                    4
                                </label>
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="5" id="defaultCheck5" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck5">
                                    5
                                </label>
                            </div>
                            <div class="col-sm-6 col-lg-12 mt-3">
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="6" id="defaultCheck6" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck6">
                                    6
                                </label> 
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="7" id="defaultCheck7" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck7">
                                    7
                                </label>
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="8" id="defaultCheck8" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck8">
                                    8
                                </label>
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="9" id="defaultCheck9" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck9">
                                    9
                                </label>
                                <input class="form-check-input" v-model="voc.TingkatKepuasan" type="radio" value="10" id="defaultCheck10" name="tingkatKepuasan">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck10">
                                    10
                                </label>
                            </div>
                            <div class="col-sm-6 col-lg-12 mt-3 border border-danger">
                                <small><em class="text-danger">Tidak Merekomendasikan (0 - 6)</em></small><br>
                                <small><em class="text-danger">Biasa (7 - 8)</em></small><br>
                                <small><em class="text-danger">Merekomendasikan (9 - 10)</em></small><br>
                            </div>
                        </div>
                    </div>
                    </fieldset>
                    <fieldset class="form-group" id="group2">
                        <div class="row">
                            <legend class="col-form-label col-sm-2 pt-0">Pada Bagian mana anda puas/tidak puas selama service?</legend>
                            <div class="col-sm-10">
                            
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.KeteranganKepuasan" type="radio" name="kepuasanService" id="gridRadios3" value="Kemudahan menghubungi bengkel" >
                                    <label class="form-check-label" for="gridRadios3">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Kemudahan menghubungi bengkel
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.KeteranganKepuasan" type="radio" name="kepuasanService" id="gridRadios4" value="Kemudahan mendapatkan jadwal Service">
                                    <label class="form-check-label" for="gridRadios4">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Kemudahan mendapatkan jadwal Service
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.KeteranganKepuasan" type="radio" name="kepuasanService" id="gridRadios5" value="Lama waktu di layani SA" >
                                    <label class="form-check-label" for="gridRadios5">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Lama waktu di layani SA
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.KeteranganKepuasan" type="radio" name="kepuasanService" id="gridRadios6" value="Fasilitas Bengkel">
                                    <label class="form-check-label" for="gridRadios6">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Fasilitas Bengkel
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.KeteranganKepuasan" type="radio" name="kepuasanService" id="gridRadios7" value="Lama waktu Service" >
                                    <label class="form-check-label" for="gridRadios7">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Lama waktu Service
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.KeteranganKepuasan" type="radio" name="kepuasanService" id="gridRadios8" value="Estimasi biaya service">
                                    <label class="form-check-label" for="gridRadios8">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Estimasi biaya service
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.KeteranganKepuasan" type="radio" name="kepuasanService" id="gridRadios9" value="Pelayanan petugas bengkel">
                                    <label class="form-check-label" for="gridRadios9">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Pelayanan petugas bengkel
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.KeteranganKepuasan" type="radio" name="kepuasanService" id="gridRadios10" value="Kondisi kendaraan service">
                                    <label class="form-check-label" for="gridRadios10">
                                    &nbsp;&nbsp;&nbsp;&nbsp; Kondisi kendaraan service
                                </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0">Melanjutkan pertanyaan di atas, berikan alasan anda?</legend>
                        <div class="col-sm-10">
                            
                            <input class="form-control form-control-sm" name="alasan" id="Alasan" 
                                type="text" placeholder="Alasan Anda" autocomplete="off" v-model="voc.Alasan" >
                        </div>
                    </div>
                    <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0">Seberapa Besar Anda Merekomendasikan KARTIKASARI 
                            
                        </legend>
                        
                        <div class="col-sm-10 ml-2">
                            <div class="col-sm-6 col-lg-12">
                                <input class="form-check-input" v-model="voc.NilaiRekom" type="radio" value="1" id="defaultCheck1" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck1">
                                    1
                                </label>
                                <input class="form-check-input" v-model="voc.NilaiRekom" type="radio" value="2" id="defaultCheck2" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck2">
                                    2
                                </label>
                                <input class="form-check-input" v-model="voc.NilaiRekom" type="radio" value="3" id="defaultCheck3" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck3">
                                    3
                                </label>
                                <input class="form-check-input" v-model="voc.NilaiRekom" type="radio" value="4" id="defaultCheck4" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck4">
                                    4
                                </label>
                                <input class="form-check-input" v-model="voc.NilaiRekom" type="radio" value="5" id="defaultCheck5" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck5">
                                    5
                                </label>
                            </div>
                            <div class="col-sm-6 col-lg-12 mt-3">
                                <input class="form-check-input" v-model="voc.NilaiRekom" type="radio" value="6" id="defaultCheck6" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck6">
                                    6
                                </label> 
                                <input class="form-check-input " v-model="voc.NilaiRekom" type="radio" value="7" id="defaultCheck7" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck7">
                                    7
                                </label>
                                <input class="form-check-input " v-model="voc.NilaiRekom" type="radio" value="8" id="defaultCheck8" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck8">
                                    8
                                </label>
                                <input class="form-check-input " v-model="voc.NilaiRekom" type="radio" value="9" id="defaultCheck9" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck9">
                                    9
                                </label>
                                <input class="form-check-input " v-model="voc.NilaiRekom" type="radio" value="10" id="defaultCheck10" name="rekomendasi">
                                <label class="form-check-label ml-1 mr-2 pr-4" for="defaultCheck10">
                                    10
                                </label>
                            </div>
                            <div class="col-sm-6 col-lg-12 mt-3 border border-danger">
                                <small><em class="text-danger">Tidak Merekomendasikan (0 - 6)</em></small><br>
                                <small><em class="text-danger">Biasa (7 - 8)</em></small><br>
                                <small><em class="text-danger">Merekomendasikan (9 - 10)</em></small><br>
                            </div>
                        </div>
                        
                    </div>
                    <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0">Profesi Anda</legend>
                        <div class="col-sm-10">
                            
                            <div class="inputwrapper align-middle">
                                <div class="input-group input-group-merge input-group-alternative">
                                    <input name="profesi" id="profesiId" class="form-control form-control-sm" 
                                        type="text" placeholder="Staff IT" required v-model="voc.Profesi">
                                </div>
                               
                            </div>
                        </div>
                    </div> 
                    <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0">Tuliskan nama Akun sosmed anda?</legend>
                        <div class="col-sm-10">
                            
                            <div class="inputwrapper align-middle">
                                <div class="input-group input-group-merge input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-at"></i></span>
                                    </div>
                                    <input name="sosmed" id="instagramId" class="form-control form-control-sm"
                                        v-model="voc.Sosmed" placeholder="Instagram" type="text">
                                </div>
                                <small id="instagramId" class="form-text text-muted">*Dapatkan voucher diskon jasa service 
                                    sebesar 50% untuk service berikutnya yang akan dikirim melalui DM oleh Instagram kami <a href="https://www.instagram.com/kartikasarimulia/" target="_blank">@kartikasarimulia</a></small>

                            </div>
                        </div>
                    </div>
                    <fieldset class="form-group" id="group3">
                        <div class="row">
                            <legend class="col-form-label col-sm-2 pt-0">Apakah anda berencana untuk membeli kendaraan Toyota dalam waktu dekat?</legend>
                            <div class="col-sm-10">
                            
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.BeliWaktuDekat" type="radio" name="pembelian" id="gridRadios11" value="Ya">
                                    <label class="form-check-label" for="gridRadios11">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Ya
                                    </label>
                                </div>
                                <div class="form-check mx-0 px-0">
                                    <input class="form-check-input mx-0 px-0" v-model="voc.BeliWaktuDekat" type="radio" name="pembelian" id="gridRadios12" value="Tidak">
                                    <label class="form-check-label" for="gridRadios12">
                                        &nbsp;&nbsp;&nbsp;&nbsp; Tidak
                                    </label> 
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    
                    <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0">Hadiah atau promo program seperti apa yang anda sukai?</legend>
                        <div class="col-sm-10">
                            <textarea class="form-control form-control-sm" v-model="voc.RekomendasiDariCust" name="promoFromCust" id="promoFromCust" type="text" rows="3" ></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0">Kritik Dan Saran</legend>
                        <div class="col-sm-10">
                            <textarea class="form-control form-control-sm" v-model="voc.KritikSaran" name="kritikSaran" id="kritikAlasan" type="text" rows="3" placeholder="Kritik Dan Saran"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <legend class="col-form-label col-sm-2 pt-0"></legend>
                        <div class="col-sm-12"> 
                            
                            <a href="javascript:void(0)" class="btn btn-sm btn-info btn-block "
                                @click="SubmitVoc" id="addVoc" name="addVoc">Submit</a>
                        </div>
                    </div> 
                </form>
            </div>
        </div>
    </div>
    <modal ref="mymodal" />
    <div style="min-height: 75px;"></div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
import modal from "./ModalAddUnit.vue";
export default {
  components: { modal },
  mixins: [nytMixin],
  data(){
      return {
          units: [],
          resMetodeBooking : '',
          screenWidht: screen.width,
          isLoading: false,
          voc: {
            NoRangka: '',
            NoPol: '',
            Domisili: '',
            Pertimbangan: '',
            Layanan: '',
            Kepuasan: 'Puas',
            TingkatKepuasan: '',
            KeteranganKepuasan: '',
            Alasan: '',
            NilaiRekom: '',
            Profesi: '',
            Sosmed: '',
            BeliWaktuDekat: '',
            RekomendasiDariCust: '',
            KritikSaran: '',
            CreateBy: '',
          },
          unitWashing: [],
      }
  },
  setup() {
  },
  beforeMount(){
        this.getUnitCust();
        this.voc.CreateBy = localStorage.Kode;
  },
  methods: {
    selectedUnit(event)
    {
        const thisEvent = event.target.value;
        var resUnit = this.units.filter(x => (x.NamaKendaraan + ' - ' + x.NoPol) == thisEvent);
        if(resUnit.length > 0)
        {
            this.voc.NoRangka = resUnit[0].NoRangka;
            this.voc.NoPol = resUnit[0].NoPol;
        }
        else
        {
            this.voc.NoRangka = '';
        }

    },
    async getUnitCust(){
        
        if(localStorage.Kode)
        {
            var form_data = {};   
            form_data['codeCust'] = localStorage.Kode;
            const resultData = await this.FuncPost(this.urlSite + '/HttpApi/GetUnitCustomer', form_data);
            this.units = resultData.data.filter( x => x.ReleasedByOwner == '0' );
            if(this.units.length > 0)
            {
                var data_rangka = {};
                data_rangka["codeCust"] = localStorage.Kode;
                const resRangka = await this.FuncPost(this.urlSite + '/HttpApi/GetVoucherVoc', data_rangka);
                
                this.unitWashing = resRangka.data;

            }
            else
            {
                console.log("NoRangka Kosong");
            }

            this.isLoading = true;
        }

    },
    showModal() {
        this.$refs.mymodal.showModal();
    },
    closeModal() {
        this.$refs.mymodal.hideModal();
    },
    async SubmitVoc()
    {
        if(this.voc.NoPol == '' )
        {
            alert("Isi NoPol Kendaraan Anda");
        }
        else
        {
            var objAnyEmpty = this.isObjAnyEmpty();
            if(objAnyEmpty == false)
            {
                const resultData = await this.FuncPost(this.urlSite + '/HttpApi/InsertVoc', this.voc);
                if(resultData.data.search("Success") >= 0)
                {
                    alert("Terimakasih telah mengisi VOC Kartikasari");
                    window.location.href="/";
                }
                else
                {
                    alert("Gagal Insert VOC, Periksa Koneksi Anda dan Ulangi Lagi");
                }
            }
            else
            {
                alert("Lengkapi Form Anda");
            }
        }
        
        
    },
    isObjAnyEmpty()
    {
        var adaKosong = false;
        Object.keys(this.voc).forEach(key => {
            
            if(this.voc[key] == '' && key != "NoRangka")
            {
                adaKosong = true;
                //console.log("kosong " + key);
            }
        });
        return adaKosong;
    }
  },
  created(){
    
  },
  updated()
  {
  },
  mounted()
  {
  }
};
</script>