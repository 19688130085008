<template>
 
  <div class="onTop">

    <div style="
    position:fixed; !important 
    width:200px;
    height:350px;
    bottom:75px;
    right:10px;
    background: #ffff;"  >
      <p >asdf asdfasd </p>
    </div>
  
  </div>
  <div id="app" ref="document">
    <div @click="generatePDF()" class="np-btn">Generate PDF</div>
    <div @click="egs2pdf()" class="np-btn">Generate PDF</div>

    <div>
      <p>
        <strong
          ><a href="https://www.nightprogrammer.com/" target="_blank"
            >Nightprogrammer.com</a
          ></strong
        >: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
        posuere, tellus lobortis posuere tempor, elit sem varius libero, ac
        vulputate ante orci vel odio. Nam sit amet tortor malesuada tellus
        rutrum varius vel a mauris. Integer volutpat neque mauris, vel imperdiet
        mi aliquet ac. Proin sed iaculis ipsum. Vivamus tincidunt egestas
        sapien, vitae faucibus velit ultricies eget. Donec mattis ante arcu, a
        pretium tortor scelerisque et. Morbi sed dui tempor, consectetur turpis
        sed, tristique arcu.
      </p>
      <p>
        Quisque nec ante faucibus, lobortis neque eget, placerat massa. Mauris
        tincidunt ante faucibus dui semper eleifend. Morbi sit amet velit lacus.
        Maecenas suscipit pulvinar lacus, ac feugiat erat venenatis porta. Donec
        at turpis ultrices, vehicula justo eu, laoreet magna. Ut consequat
        eleifend posuere. Quisque nec ante faucibus, lobortis neque eget,
        placerat massa. Mauris tincidunt ante faucibus dui semper eleifend.
        Morbi sit amet velit lacus. Maecenas suscipit pulvinar lacus, ac feugiat
        erat venenatis porta. Donec at turpis ultrices, vehicula justo eu,
        laoreet magna.
      </p>
    </div>
  </div>
  <div id="testingaja">
    <p>asdf</p>
  </div>

  <div ref="document2" class="container-fluid" >
    <div class="row ">
      <div class="col-4 text-left">
        <h5 class="my-0 py-0">PT.KARTIKA SARI MULIA</h5>
        <p class="my-0 py-0">JL. PUNCAK BOROBUDUR NO.1 MALANG</p>
        <p class="my-0 py-0">Telp: 0341-479000 - Fax: 0341-480280</p>
        <p class="my-0 py-0">NPWP: 03.325.926.8-652.000,</p>
      </div>
      <div class="col-4">
        <img style="width: 100%; max-width: 175px" 
          :src="this.urlSite + '/assets/MasterMobileKSM/img/invoicelogo.png'" />
      </div>
      <div class="col-4 text-left">
        <div class="row ">
          <div class="col-6"> <p class="my-0 py-0">No. Invoice</p> </div> <div class="col-6"> <p class="my-0 py-0">Invoice</p> </div>
          <div class="col-6"> <p class="my-0 py-0">No. PKB:</p> </div> <div class="col-6"> <p class="my-0 py-0">pkb</p> </div>
          <div class="col-6"> <p class="my-0 py-0">Tgl. PKB:</p> </div> <div class="col-6"> <p class="my-0 py-0">tgl</p> </div>
          <div class="col-6"> <p class="my-0 py-0">Warna/ Tahun:</p> </div> <div class="col-6"> <p class="my-0 py-0">warna</p> </div>
          <div class="col-6"> <p class="my-0 py-0">Kilometer:</p> </div> <div class="col-6"> <p class="my-0 py-0">kl</p> </div>
          <div class="col-6"> <p class="my-0 py-0">Nama SA:</p> </div> <div class="col-6"> <p class="my-0 py-0">sa</p> </div>
          <div class="col-6"> <p class="my-0 py-0">No SPK:</p> </div> <div class="col-6"> <p class="my-0 py-0">SPK</p> </div>
        </div>
      </div>
      <div class="col-6">
        <p class="my-0 py-0 text-left">NOTA JASA</p>
      </div>
      <div class="col-6">
        <p class="my-0 py-0 text-right">Mekanik: ALFAN BAKHTIAR</p>
      </div>
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">No.</th>
              <th scope="col">Kode.</th>
              <th scope="col">Jenis Pekerjaan</th>
              <th scope="col">Flat Rate</th>
              <th scope="col">Hrg Sat.</th>
              <th scope="col">Disc.</th>
              <th scope="col">Total Disc.</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody >
            <tr >
              <td>Saran service berikutnya: GANTI AKI</td>
            </tr>
            <tr>
              <td>Terbilang: # NOL RUPIAH #</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <p>Billing, </p>
        <br>
        <p>WIDYA</p>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-6 text-right"> <p class="my-0 py-0">Total Rp.</p> </div> <div class="col-6 text-right"> <p class="my-0 py-0">0</p> </div>
          <div class="col-6 text-right"> <p class="my-0 py-0">Diskon Rp.</p> </div> <div class="col-6 text-right"> <p class="my-0 py-0">0</p> </div>
          <div class="col-6 text-right"> <p class="my-0 py-0">DPP Rp.</p> </div> <div class="col-6 text-right"> <p class="my-0 py-0">0</p> </div>
          <div class="col-6 text-right"> <p class="my-0 py-0">PPN Rp.</p> </div> <div class="col-6 text-right"> <p class="my-0 py-0">0</p> </div>
          <div class="col-6 text-right"> <p class="my-0 py-0">Total Bayar Rp.</p> </div> <div class="col-6 text-right"> <p class="my-0 py-0">0</p> </div>
        </div>
      </div>
      <div class="col-12">
        <p class="text-right my-0 py-0">* Harga Diatas sudah PPN</p>
        <p class="text-right my-0 py-0">Printed: 29 Agustus 2022 by WIDYA</p>
      </div>
    </div>
  </div>

</template>

<style>
td {
  text-align: left;
  font-size: 11px;
}
th {
  font-size: 11px;
}
</style>

<script>
import html2pdf from 'html2pdf.js'
import { Capacitor,
  Plugins,
  CameraResultType,
  FilesystemDirectory,
  FilesystemEncoding } from '@capacitor/core';

const { Filesystem, FileDownloader } = Plugins;

	export default {
    name: 'app',
    
		methods: {
      
			generatePDF () {
				html2pdf(this.$refs.document, {
					margin: 1,
					filename: 'document.pdf',
					image: { type: 'jpeg', quality: 0.98 },
					html2canvas: { dpi: 192, letterRendering: true },
					jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
				}).save();
			},
      async egs2pdf () 
      {
        html2pdf(this.$refs.document, {
					margin: 1,
					filename: 'myFile.pdf',
          //image: { type: 'jpeg', quality: 0.98 },
					html2canvas: { dpi: 192, letterRendering: true },
					jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
				})
        .outputPdf()
        .then(pdf =>
        {
          Filesystem.requestPermissions()
          .then(permission => {
            if(permission)
            {
              this.writeCapacitorFile(pdf)
              .then(() => {
                this.$toast.success("success lek");
                window.location.href="/ORDERSTATUS";
              })
              .catch(error => {
                console.log(error);
                this.$toast.success("gagal lek");

              });
            }
          })
        });
      },
      async writeCapacitorFile(pdf) {
        const newPdf = btoa(pdf); //convert raw to base64
        console.log(newPdf);
        await Filesystem.writeFile({
          path: '/Download/myFile.pdf',
          data: newPdf,
          directory: FilesystemDirectory.ExternalStorage,
          //encoding: FilesystemEncoding.UTF8,
          recursive: true
        })
        
        /*
        .then(() => {
          Filesystem.getUri({
            directory: FilesystemDirectory.ExternalStorage,
            path: '/Download/myFile.jpeg'
          }).then(
            result => {
              let path = Capacitor.convertFileSrc(result.uri);
              console.log("path nya di sini");
              console.log(path);
            },
            err => {
              console.log(err);
            }
          );
          return true
        })
        .catch(error => {
          return error
        })
        */
      },
       
    },
    mounted(){
      //this.egs2pdf ();
    }
  }
  
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.np-btn {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #da1010;
  width: 110px;
  background: #da1010;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
}
</style>