<template>
    <div class="row " style="margin-top: 15px;"></div>
    <div class="row m-0 p-0 w-100">
        <div class="col-12 m-0 p-0 w-100 text-center">
            <h5><strong>Therm Of Service</strong></h5>
        </div>
        <div class="col-12 mt-3 mx-0 p-0 w-100 text-center"> 
            <p>These privacy policy create a contract between you and Toyota Kartikasari (the Agreement). 
                Please read the agreement carefully. 
                To confirm your understanding and acceptance of the agreement, 
                click <a href="#">Agree</a></p>
        </div>
    </div>
</template>