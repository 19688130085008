<template>
<div v-if="this.isMobile()">
    <!-- menu car or sparepart -->
    <div class="row ml-0 mr-0 d-flex flex-row flex-nowrap overflow-auto  w-100">
        <div class="col-5 px-1">
            <button :class="activeList == 'Sparepart List'? 'btn-ksm': 'btn-secondary'"
                @click="setActive('Sparepart List')"
                class="btn btn-sm btn-block text-light btnList btnRounded">Sparepart List</button>
        </div>
        <div class="col-5 px-1"> 
            <button :class="activeList == 'Car List'? 'btn-ksm': 'btn-secondary'"
                @click="setActive('Car List')"
                class="btn btn-sm btn-block text-light btnList btnRounded">Car List</button>
        </div>
        <div class="col-5 px-1"> 
            <button :class="activeList == 'Status Pembelian'? 'btn-ksm': 'btn-secondary'"
                @click="setActive('Status Pembelian')"
                class="btn btn-sm  btn-block text-light btnList btnRounded">Status Pembelian</button>
        </div>
        <div class="col-5 px-1"> 
            <button :class="activeList == 'Status Service'? 'btn-ksm': 'btn-secondary'"
                @click="setActive('Status Service')"
                class="btn btn-sm  btn-block text-light btnList btnRounded">Status Service</button>
        </div>
        <div class="col-5 px-1"> 
            <button :class="activeList == 'Pesanan Selesai'? 'btn-ksm': 'btn-secondary'"
                @click="setActive('Pesanan Selesai')"
                class="btn btn-sm btn-block text-light btnList btnRounded">Pesanan Selesai</button>
        </div>
    </div>

    <!-- wishlis sparepart -->
    <div v-if="activeList == 'Sparepart List'" class="row w-100 m-0 p-0">
        <div v-if="sparepartLists.length > 0" class="col-12 w-100 m-0 p-0" >
            <div v-for="(spare, index) in sparepartLists" :key="index" class="row mx-1 my-3 shadow p-1 mb-3 bg-white rounded" :id="'list' + index" >
                <div class="col-2 m-0">
                    <img class="mt-1" style="max-height: 65px; max-width: 65px; border-radius: 15px;" 
                        :src="this.urlSite + spare.Image">
                </div>
                <div class="col-6 pl-4 text-left"> 
                    <p class="m-0"><strong> {{spare.Nama}} </strong></p>
                    <p class="m-0 pull-right" :id="'hargaSatuan'+index">
                        <strong> 
                            Rp.{{parseInt(spare.Harga).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}} 
                        </strong>
                    </p>
                </div>   
                <div class="col-4 m-0 text-left">
                    <button class="close pull-right" aria-label="Close" @click="CancelOrder(spare.IdWishList)">
                        <span aria-hidden="true">&times;</span>
                    </button>  
                    <div class="input-group pt-3">
                        <div class="input-group-prepend">
                            <input type="button" class="btn btn-dark btn-sm minus-btn" 
                                style="border-radius: 15px 0px 0px 15px" value="-"
                                @click="EditQuantity(index, 'dec')">
                        </div>
                        <input readonly type="number" name="qty_input" 
                            style="text-align: center;" class="form-control form-control-sm" 
                            :value="spare.Quantity" min="1">
                        <div class="input-group-prepend"> 
                            <input type="button" class="btn btn-dark btn-sm plus-btn" 
                                style="border-radius: 0px 15px 15px 0px" value="+"
                                @click="EditQuantity(index, 'inc')">
                        </div>
                    </div>
                </div>  
            </div>   
        </div>
        <div v-else class="col-12 w-100 m-0 p-0">
            <p class='d-flex justify-content-center mt-3' id='sparepartKosong'>Data Kosong</p>
        </div>
    </div>

    <!-- wish list car -->
    <div v-if="activeList == 'Car List'" class="row w-100 m-0 p-0"> 
        <div v-if="carLists.length > 0" class="col-12 w-100 m-0 p-0" >
            <div v-for="(car, index) in carLists" :key="index" 
                class="row mx-1 my-3 shadow p-1 mb-3 bg-white rounded" :id="'listCar'+index" >
                <div class="col-2 m-0 p-0 "> 
                    <img class="mt-1" style="max-height: 65px; max-width: 65px; " 
                        :src="this.urlSite + '/picture.php?source=api-integration/images/promoAltius/'+car.Gambar+'&size=500'">
                </div>
                <div class="col-9 pl-4 pr-0 m-0 text-left"> 
                    <p class="m-0"><strong> {{car.CarName}} </strong></p>
                    <!--<p class="m-0"> detail ... </p>-->
                    <p class="m-0 pull-right" :id="'hargaSatuanCar'+index">
                        <strong> Rp.{{ parseInt(car.CarPrice).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}} </strong>
                        <small class="float-right">[Cashback: {{ car.KodePoint }}]</small>
                    </p>
                    
                </div>   
                <div class="col-1 m-0 p-0 pull-left">
                    <button type="button" @click="CancelOrder(car.IdWishListCar)" 
                        class="close pull-right" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>  
                    
                </div>  
            </div>   
        </div>
        <div v-else class="col-12 w-100 d-flex justify-content-center">
            <p class="mt-3">Data Kosong</p>
        </div>
    </div>

    <!-- status order -->
    <div v-if="activeList == 'Status Pembelian'" class="row w-100 m-0 p-0 "> 
        <div v-if="services.length > 0" class="col-12 w-100 m-0 p-0 " >
            <div v-for="(service, index) in services" :key="index" > 
                <div 
                    class="card shadow bg-white rounded listOrderServices w-100 mx-0 px-0 my-2 py-2" 
                    :data-invoice="service.KodeNota" data-tipe="service" >
                    <div class="card-header h-100">
                        <div class="row align-items-center h-100">
                            <div class="col-2 mx-auto text-left">
                                <img class="" style="max-height: 30px;" :src="this.urlSite + '/assets/img/iconservice.png'">
                            </div> 
                            <div class="col-4 mx-auto text-left">
                                <p class="p-0 m-0"><strong>Service</strong></p>
                                <p class="p-0 m-0"><small><em>{{service.CreateDate}}</em></small></p>
                            </div>
                            <div  class="col-6 mx-auto text-left">
                                <p class="mt-0 mb-0 float-right bg-ksm rounded text-light "><small class="ml-2 mr-2">{{service.MetodeBooking}}</small></p>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div class="ml-3 mt-2 mb-2 text-left">
                        <p class="d-inline"><strong>{{service.NoPol}}</strong></p>
                        <p class="mt-0 mb-0">{{service.NamaKendaraan}}</p>
                        
                    </div>
                </div> 
            
            </div>
        </div>
        <div v-if="sparepartOrders.length > 0" class="col-12 w-100 m-0 p-0">
            <div v-for="(sparepart, index) in sparepartOrders" :key="index" 
                class="card shadow bg-white rounded w-100 mx-0 px-0 my-2 py-2 listStatusPembelian"
                @click="showDetailOrder(sparepart.KodeNotaSO)" >
                <div class="card-header h-100">
                    <div class="row align-items-center h-100">
                        <div class="col-2 mx-auto">
                            <img class="" style="max-height: 30px;" 
                                :src="this.urlSite + '/assets/img/iconbag.png'">
                        </div>
                        <div class="col-4 mx-auto text-left">
                            <p class="p-0 m-0"><strong>Sparepart</strong></p>
                        </div>
                        <div  class="col-6 mx-auto text-left">
                            <p class="mt-0 mb-0 float-right bg-ksm rounded text-light ">
                                <small class="ml-2 mr-2">{{sparepart.Status}}</small>
                            </p>
                        </div>
                        <div class="col-8 offset-4 mx-auto text-left">
                            <p class="p-0 m-0"><small><em>{{sparepart.CreateDateSO}}</em></small></p>
                        </div>
                    </div>
                    
                </div>
                <div class="ml-3 mt-2 mb-2 text-left">
                    <p class="d-inline"><strong>{{sparepart.KodeNotaSO}}</strong></p>
                    <p class="mt-0 mb-0">{{ sparepart.NamaBarang+ ' dan ' +sparepart.Quantity + ' item lainya'}}</p>
                    <p class="mt-0 mb-0 mr-2 float-right"><strong>RP. {{ parseInt(sparepart.TotalBayar).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</strong></p>
                    
                </div>
            </div>
        </div>
        <div v-if="unitOrders.length > 0" class="col-12 w-100 m-0 p-0 ">
            <div v-for="(unit, index) in unitOrders" :key="index" 
                class="card shadow bg-white rounded listStatusPembelianCar w-100 mx-0 px-0" 
                @click="showDetailOrder(unit.Invoice)" >
                <div class="card-header h-100 ">
                    <div class="row align-items-center h-100 ">
                        <div class="col-2 mx-auto ">
                            <img class="" style="max-height: 45px;" :src="this.urlSite + '/assets/img/iconcar.png'">
                        </div>
                        <div class="col-4 mx-auto text-left">
                            <p class="p-0 m-0"><strong>Unit</strong></p>
                        </div>
                        <div  class="col-6 mx-auto text-left">
                            <p class="mt-0 mb-0 float-right bg-ksm rounded text-light ">
                                <small class="ml-2 mr-2 StatusSPK">{{unit.StatusSPK}}</small></p>
                        </div>
                        <div class="col-8 offset-4 mx-auto text-left">
                            <p class="p-0 m-0"><small><em>{{unit.CreateDate}}</em></small></p>
                        </div>
                    </div>
                    
                </div>
                <div class="ml-3 mt-2 mb-2 text-left">
                    <p class="d-inline"><strong>{{unit.Invoice}}</strong></p>
                    <p class="mt-0 mb-0">{{unit.NamaKendaraan}}</p>
                    <p class="mt-0 mb-0 mr-2 float-right"><strong>RP. {{ parseInt(unit.PriceList).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</strong></p>
                    
                </div>
            </div>
        </div>
        
        <div v-if="unitOrders.length == 0 && sparepartOrders.length == 0 && services.length == 0" 
            class="col-12 w-100 d-flex justify-content-center">
            <p class='mt-3' >Data Kosong</p>
        </div>
    </div>

    <!-- status service -->
    <div v-if="activeList == 'Status Service'" class="row w-100 m-0 p-0"> 
        <div v-if="statusServices.length > 0" class="col-12 w-100 m-0 p-0" >
            <div v-for="(row, index) in statusServices" :key="index" 
                class="card shadow bg-white rounded mx-1 px-1 my-2 py-2 text-left"
                @click="detailSelesai(row.KodeNotaWO, 'Service')">
                <div class="card-header h-100 ">
                    <div class="row align-items-center h-100">
                        <div class="col-2 mx-auto">
                            <img class="" style="max-height: 30px;" 
                                :src="this.urlSite + '/assets/img/iconservice.png'">
                        </div>
                        <div class="col-10 mx-auto ">
                            <p class="p-0 m-0">
                                <strong>Unit</strong>
                                <small class="pl-2 pr-2 mr-2 bg-ksm rounded text-light float-right">{{ row.KodeNota }}</small>
                            </p>
                        </div>
                        <div class="col-10 offset-2 ">
                            <p class="p-0 m-0"><small><em>{{row.CreateDate}}</em></small></p>
                        </div>
                    </div>
                    
                </div>
                <div class="ml-3 mt-2 mb-2">
                    <p class="d-inline"><strong>{{ row.NoPol }}</strong></p>
                    <p class="mt-0 mb-0">{{ row.Keterangan }}</p>
                    <p class="mt-0 mb-0">{{ row.Keluhan }}</p>
                    <p class="mt-0 mb-0 mr-2 float-right"> 
                        <strong >{{ row.NoRangka }}</strong>
                    </p>
                    
                </div>
            </div>
        </div>
        <div v-else class="col-12 d-flex justify-content-center"> 
            <p class='mt-3' >Data Kosong</p>
        </div>
    </div>
    <!-- finish order -->
    <div v-if="activeList == 'Pesanan Selesai'" class="row w-100 m-0 p-0"> 
        <div v-if="finishOrders.length > 0" class="col-12 w-100 m-0 p-0" >
            <div v-for="(finish, index) in finishOrders" :key="index" 
                class="card shadow bg-white rounded mx-1 px-1 my-2 py-2 text-left"
                @click="detailSelesai(finish.Kodenota, finish.Tipe)">
                <div class="card-header h-100 ">
                    <div class="row align-items-center h-100">
                        <div class="col-2 mx-auto">
                            <img v-if="finish.Tipe == 'Service'" class="" style="max-height: 30px;" 
                                :src="this.urlSite + '/assets/img/iconservice.png'">
                            <img v-else class="" style="max-height: 30px;" 
                                :src="this.urlSite + '/assets/img/iconbag.png'">
                        </div>
                        <div class="col-10 mx-auto ">
                            <p class="p-0 m-0">
                                <strong>Selesai</strong>
                                <small class="pl-2 pr-2 mr-2 bg-ksm rounded text-light float-right">{{finish.Tipe}}</small>
                            </p>
                        </div>
                        <div class="col-10 offset-2 ">
                            <p class="p-0 m-0"><small><em>{{finish.CreateDate}}</em></small></p>
                        </div>
                    </div>
                    
                </div>
                <div class="ml-3 mt-2 mb-2">
                    <p class="d-inline"><strong>{{finish.Kodenota}}</strong></p>
                    <p class="mt-0 mb-0">{{finish.NamaKendaraan}}</p>
                    <p class="mt-0 mb-0 mr-2 float-right"> 
                        <strong v-if="isNaN(parseInt(finish.totalBayar))">RP. 0</strong>
                        <strong v-else>RP. {{ parseInt(finish.totalBayar).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</strong>
                    </p>
                    
                </div>
            </div>
        </div>
        <div v-else class="col-12 d-flex justify-content-center"> 
            <p class='mt-3' >Data Kosong</p>
        </div>
    </div>

    <!-- alamat pengiriman -->
    <div v-if="activeAddr == true" class="contentAlamat "> 
        <div class="card rounded w-100 m-0 p-0 cardAlamatOuter ">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <p class="float-left">Alamat Pengiriman</p>
                        <p class="float-right text-info" @click="changeAddress"> Pilih Alamat Lain</p>
                    </div> 
                    
                    <!-- input alamat -->
                    <div class="col-12 form-inline bottomContain w-100 p-0 m-0" >
                        <div class="form-group col-6 ">
                            <input type="text" class="form-control d-inline" id="alamatPenerima" 
                                placeholder="Alamat Penerima" v-model="alamatPenerima">
                        </div>
                        <div class="form-group col-6">
                            <input type="phone" class="form-control d-inline" id="phonePenerima" 
                                placeholder="Phone Penerima" v-model="phonePenerima">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Select Sales if car shoping -->
        <div class="card rounded w-100 mx-0 mt-2 mb-0 p-0 cardAlamatOuter ">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <p class="float-left">Nama Sales</p>
                        <p class="float-right text-info" @click="GetSalesPerson"> Pilih Nama Sales</p>
                    </div> 
                    
                    <!-- input sales -->
                    <div v-if="salesSelected.length > 0" class="row bottomContain w-100 p-0 m-0" >
                        <div class="col-6 p-0 m-0">
                            <img v-if="salesSelected[0].GambarPath" class="colorCar d-block w-25 mx-2 mr-5 float-right " 
                                style="border-radius: 50%; " 
                                :data-image="salesSelected[0].GambarPath"
                                :src="this.urlSite + '/picture.php?source=api-integration/images/promoAltius/'+ salesSelected[0].GambarPath +'&size=1000'">                             
                            <img v-else class="colorCar d-block w-25 mx-2 mr-5 float-right" 
                                style="border-radius: 50%;" 
                                :src="this.urlSite + '/assets/img/ic_profile_picture.png'">                            
                              
                        </div>
                        <div class="col-6 p-0 m-0 text-left">
                            <div class="row">
                                <label class="col-12 w-100 m-0 p-0 text-left" ><small>{{salesSelected[0].Nama}}</small></label>
                                <p class="col-12 w-100 m-0 p-0  text-left" ><small><em>{{salesSelected[0].Telp}}</em></small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- subtotal -->
        <div class="col-12 bottomContain w-100 p-0 mx-0 mt-2 text-left" >
            <div class="d-inline ml-2"><strong>Total</strong></div> 
            <div class="d-inline pull-right mr-2 float-right">
                <strong >
                    Rp. {{ parseInt(totalPayment).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }}
                </strong>
            </div>
        </div> 
        <div class="col-12 bottomContain text-right">
            <p><small><em class="text-danger">* Nb: Belum Termasuk Ongkir</em></small></p>
        </div> 
        
        <!-- button bayar -->
        <div class="col-12 bottomContain mt-2">
            <button class="btn btn-block btn-warning text-light btn-ksm btnCheckout" @click="btnCheckOut">
                Process to Checkout
            </button>
        </div>
    </div>
</div>

<!-- order status web -->
<div v-else class="container-fluid ">
    <div class="row mx-2 mt-5" :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 120px !important;' : 'margin-top: 65px !important;'">
        <div class="col-3 text-left">
            <div class="card shadow">
                <div class="card-header">
                    <h5> <strong>Info Keranjang</strong> </h5>
                </div>
                <div class="card-body">
                    <ul class="list-group list-group-flush ">
                        <li :class="activeList == 'Sparepart List'? 'active': ''" @click="setActive('Sparepart List')" class="list-group-item rounded">Keranjang Sparepart</li>
                        <li :class="activeList == 'Car List'? 'active': ''" @click="setActive('Car List')" class="list-group-item rounded">Keranjang Mobil</li>
                        <li :class="activeList == 'Status Pembelian'? 'active': ''" @click="setActive('Status Pembelian')" class="list-group-item rounded">Status Pembelian</li>
                        <li :class="activeList == 'Status Service'? 'active': ''" @click="setActive('Status Service')" class="list-group-item rounded">Status Service</li>
                        <li :class="activeList == 'Pesanan Selesai'? 'active': ''" @click="setActive('Pesanan Selesai')" class="list-group-item rounded">Pesanan Selesai</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-9 ">
            <!-- wishlis sparepart -->
            <div v-if="activeList == 'Sparepart List'" class="row ">
                <h5 class="text-left  ml-4"><strong>List Order Sparepart</strong></h5>
                <div v-if="sparepartLists.length > 0" class="col-12 " >
                    <div v-for="(spare, index) in sparepartLists" :key="index" class="row mx-1 my-3 shadow p-1 mb-3 bg-white rounded" :id="'list' + index" >
                        <div class="col-2 m-0">
                            <img class="mt-1" style="max-height: 65px; max-width: 65px; border-radius: 15px;" 
                                :src="this.urlSite + spare.Image">
                        </div>
                        <div class="col-8 pl-4 text-left"> 
                            <p class="m-0"><strong> {{spare.Nama}} </strong></p>
                            <p class="m-0 pull-right" :id="'hargaSatuan'+index">
                                <strong> 
                                    Rp.{{parseInt(spare.Harga).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}} 
                                </strong>
                            </p>
                        </div>   
                        <div class="col-2 m-0 text-left">
                            <button class="close pull-right" aria-label="Close" @click="CancelOrder(spare.IdWishList)">
                                <span aria-hidden="true">&times;</span>
                            </button>  
                            <div class="input-group pt-3">
                                <div class="input-group-prepend">
                                    <input type="button" class="btn btn-dark btn-sm minus-btn" 
                                        style="border-radius: 15px 0px 0px 15px" value="-"
                                        @click="EditQuantity(index, 'dec')">
                                </div>
                                <input readonly type="number" name="qty_input" 
                                    style="text-align: center;" class="form-control form-control-sm" 
                                    :value="spare.Quantity" min="1">
                                <div class="input-group-prepend"> 
                                    <input type="button" class="btn btn-dark btn-sm plus-btn" 
                                        style="border-radius: 0px 15px 15px 0px" value="+"
                                        @click="EditQuantity(index, 'inc')">
                                </div>
                            </div>
                        </div>  
                    </div>   
                </div>
                <div v-else class="col-12 w-100 m-0 p-0">
                    <p class='d-flex justify-content-center mt-3' id='sparepartKosong'>Data Kosong</p>
                </div>
            </div>
            <!-- wish list car -->
            <div v-if="activeList == 'Car List'" class="row "> 
                <h5 class="text-left  ml-4"><strong>List Order Car</strong></h5>
                <div v-if="carLists.length > 0" class="col-12" >
                    <div v-for="(car, index) in carLists" :key="index" 
                        class="row mx-1 my-3 shadow p-1 mb-3 bg-white rounded" :id="'listCar'+index" >
                        <div class="col-2 m-0 p-0 "> 
                            <img class="mt-1" style="max-height: 150px; max-width: 150px; " 
                                :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+car.Gambar+'&size=500'">
                        </div>
                        <div class="col-9 pl-4 pr-0 m-0 text-left"> 
                            <p class="m-0"><strong> {{car.CarName}} </strong></p>
                            <!--<p class="m-0"> detail ... </p>-->
                            <p class="m-0 pull-right" :id="'hargaSatuanCar'+index">
                                <strong> Rp.{{ parseInt(car.CarPrice).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}} </strong></p>
                        </div>   
                        <div class="col-1 m-0 p-0 pull-left">
                            <button type="button" @click="CancelOrder(car.IdWishListCar)" 
                                class="close pull-right" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>  
                            
                        </div>  
                    </div>   
                </div>
                <div v-else class="col-12 w-100 d-flex justify-content-center">
                    <p class="mt-3">Data Kosong</p>
                </div>
            </div>
            <!-- status order -->
            <div v-if="activeList == 'Status Pembelian'" class="row "> 
                <h5 class="text-left ml-4"><strong>List Status Order</strong></h5>
                <div v-if="services.length > 0" class="col-12 " >
                    <div v-for="(service, index) in services" :key="index" > 
                        <div 
                            class="card shadow bg-white rounded listOrderServices w-100 mx-0 px-0 my-2 py-2" 
                            :data-invoice="service.KodeNota" data-tipe="service" >
                            <div class="card-header h-100">
                                <div class="row align-items-center h-100">
                                    <div class="col-2 mx-auto text-left">
                                        <img class="" style="max-height: 30px;" :src="this.urlSite + '/assets/img/iconservice.png'">
                                    </div> 
                                    <div class="col-4 mx-auto text-left">
                                        <p class="p-0 m-0"><strong>Service</strong></p>
                                        <p class="p-0 m-0"><small><em>{{service.CreateDate}}</em></small></p>
                                    </div>
                                    <div  class="col-6 mx-auto text-left">
                                        <p class="mt-0 mb-0 float-right bg-ksm rounded text-light "><small class="ml-2 mr-2">{{service.MetodeBooking}}</small></p>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div class="ml-3 mt-2 mb-2 text-left">
                                <p class="d-inline"><strong>{{service.NoPol}}</strong></p>
                                <p class="mt-0 mb-0">{{service.NamaKendaraan}}</p>
                                
                            </div>
                        </div> 
                    
                    </div>
                </div>
                <div v-if="sparepartOrders.length > 0" class="col-12 w-100 m-0 p-0">
                    <div v-for="(sparepart, index) in sparepartOrders" :key="index" 
                        class="card shadow bg-white rounded w-100 mx-0 px-0 my-2 py-2 listStatusPembelian"
                        @click="showDetailOrder(sparepart.KodeNotaSO)" >
                        <div class="card-header h-100">
                            <div class="row align-items-center h-100">
                                <div class="col-2 mx-auto">
                                    <img class="" style="max-height: 30px;" 
                                        :src="this.urlSite + '/assets/img/iconbag.png'">
                                </div>
                                <div class="col-4 mx-auto text-left">
                                    <p class="p-0 m-0"><strong>Sparepart</strong></p>
                                </div>
                                <div  class="col-6 mx-auto text-left">
                                    <p class="mt-0 mb-0 float-right bg-ksm rounded text-light ">
                                        <small class="ml-2 mr-2">{{sparepart.Status}}</small>
                                    </p>
                                </div>
                                <div class="col-8 offset-4 mx-auto text-left">
                                    <p class="p-0 m-0"><small><em>{{sparepart.CreateDateSO}}</em></small></p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="ml-3 mt-2 mb-2 text-left">
                            <p class="d-inline"><strong>{{sparepart.KodeNotaSO}}</strong></p>
                            <p class="mt-0 mb-0">{{ sparepart.NamaBarang+ ' dan ' +sparepart.Quantity + ' item lainya'}}</p>
                            <p class="mt-0 mb-0 mr-2 float-right"><strong>RP. {{ parseInt(sparepart.TotalBayar).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</strong></p>
                            
                        </div>
                    </div>
                </div>
                <div v-if="unitOrders.length > 0" class="col-12 w-100 m-0 p-0 ">
                    <div v-for="(unit, index) in unitOrders" :key="index" 
                        class="card shadow bg-white rounded listStatusPembelianCar w-100 mx-0 px-0" 
                        @click="showDetailOrder(unit.Invoice)" >
                        <div class="card-header h-100 ">
                            <div class="row align-items-center h-100 ">
                                <div class="col-2 mx-auto ">
                                    <img class="" style="max-height: 45px;" :src="this.urlSite + '/assets/img/iconcar.png'">
                                </div>
                                <div class="col-4 mx-auto text-left">
                                    <p class="p-0 m-0"><strong>Unit</strong></p>
                                </div>
                                <div  class="col-6 mx-auto text-left">
                                    <p class="mt-0 mb-0 float-right bg-ksm rounded text-light ">
                                        <small class="ml-2 mr-2 StatusSPK">{{unit.StatusSPK}}</small></p>
                                </div>
                                <div class="col-8 offset-4 mx-auto text-left">
                                    <p class="p-0 m-0"><small><em>{{unit.CreateDate}}</em></small></p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="ml-3 mt-2 mb-2 text-left">
                            <p class="d-inline"><strong>{{unit.Invoice}}</strong></p>
                            <p class="mt-0 mb-0">{{unit.NamaKendaraan}}</p>
                            <p class="mt-0 mb-0 mr-2 float-right"><strong>RP. {{ parseInt(unit.PriceList).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</strong></p>
                            
                        </div>
                    </div>
                </div>
                
                <div v-if="unitOrders.length == 0 && sparepartOrders.length == 0 && services.length == 0" 
                    class="col-12 w-100 d-flex justify-content-center">
                    <p class='mt-3' >Data Kosong</p>
                </div>
            </div>
            <!-- service on progress -->
            <div v-if="activeList == 'Status Service'" class="row "> 
                <h5 class="text-left ml-4"><strong>List Service On Progress</strong></h5>
                <div v-if="statusServices.length > 0" class="col-12" >
                    <div v-for="(row, index) in statusServices" :key="index" 
                        class="card shadow bg-white rounded mx-1 px-1 my-2 py-2 text-left"
                        @click="detailSelesai(row.KodeNotaWO, 'Service')"> 
                        <div class="card-header h-100 ">
                            <div class="row align-items-center h-100">
                                <div class="col-2 mx-auto">
                                    <img class="" style="max-height: 30px;" 
                                        :src="this.urlSite + '/assets/img/iconservice.png'">
                                </div>
                                <div class="col-10 mx-auto ">
                                    <p class="p-0 m-0">
                                        <strong>Unit</strong>
                                        <small class="pl-2 pr-2 mr-2 bg-ksm rounded text-light float-right">
                                            {{row.KodeNota}}
                                        </small>
                                    </p>
                                </div>
                                <div class="col-10 offset-2 ">
                                    <p class="p-0 m-0"><small><em>{{row.CreateDate}}</em></small></p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="ml-3 mt-2 mb-2">
                            <p class="d-inline"><strong>{{row.NoPol}}</strong></p>
                            <p class="mt-0 mb-0">{{row.Keterangan}}</p>
                            <p class="mt-0 mb-0">{{row.Keluhan}}</p>
                            <p class="mt-0 mb-0 mr-2 float-right"> 
                                <strong>{{ row.NoRangka }}</strong>
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div v-else class="col-12 d-flex justify-content-center"> 
                    <p class='mt-3' >Data Kosong</p>
                </div>
            </div>
            <!-- finish order -->
            <div v-if="activeList == 'Pesanan Selesai'" class="row "> 
                <h5 class="text-left ml-4"><strong>List Pesanan Selesai</strong></h5>
                <div v-if="finishOrders.length > 0" class="col-12" >
                    <div v-for="(finish, index) in finishOrders" :key="index" 
                        class="card shadow bg-white rounded mx-1 px-1 my-2 py-2 text-left"
                        @click="detailSelesai(finish.Kodenota, finish.Tipe)">
                        <div class="card-header h-100 ">
                            <div class="row align-items-center h-100">
                                <div class="col-2 mx-auto">
                                    <img v-if="finish.Tipe == 'Service'" class="" style="max-height: 30px;" 
                                        :src="this.urlSite + '/assets/img/iconservice.png'">
                                    <img v-else class="" style="max-height: 30px;" 
                                        :src="this.urlSite + '/assets/img/iconbag.png'">
                                </div>
                                <div class="col-10 mx-auto ">
                                    <p class="p-0 m-0">
                                        <strong>Selesai</strong>
                                        <small class="pl-2 pr-2 mr-2 bg-ksm rounded text-light float-right">{{finish.Tipe}}</small>
                                    </p>
                                </div>
                                <div class="col-10 offset-2 ">
                                    <p class="p-0 m-0"><small><em>{{finish.CreateDate}}</em></small></p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="ml-3 mt-2 mb-2">
                            <p class="d-inline"><strong>{{finish.Kodenota}}</strong></p>
                            <p class="mt-0 mb-0">{{finish.NamaKendaraan}}</p>
                            <p class="mt-0 mb-0 mr-2 float-right"> 
                                <strong v-if="isNaN(parseInt(finish.totalBayar))">RP. 0</strong>
                                <strong v-else>RP. {{ parseInt(finish.totalBayar).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</strong>
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div v-else class="col-12 d-flex justify-content-center"> 
                    <p class='mt-3' >Data Kosong</p>
                </div>
            </div>
            <!-- alamat pengiriman -->
            <div v-if="activeAddr == true" class="contentAlamat "> 
                <div class="card rounded w-100 m-0 p-0 cardAlamatOuter ">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p class="float-left">Alamat Pengiriman</p>
                                <p class="float-right text-info" @click="changeAddress"> Pilih Alamat Lain</p>
                            </div> 
                            
                            <!-- input alamat -->
                            <div class="col-12 form-inline bottomContain w-100 p-0 m-0" >
                                <div class="form-group col-6 ">
                                    <input type="text" class="form-control d-inline w-100" id="alamatPenerima" 
                                        placeholder="Alamat Penerima" v-model="alamatPenerima">
                                </div>
                                <div class="form-group col-6">
                                    <input type="phone" class="form-control d-inline w-100" id="phonePenerima" 
                                        placeholder="Phone Penerima" v-model="phonePenerima">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Select Sales if car shoping -->
                <div class="card rounded w-100 mx-0 mt-2 mb-0 p-0 cardAlamatOuter ">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p class="float-left">Nama Sales</p>
                                <p class="float-right text-info" @click="GetSalesPerson"> Pilih Nama Sales</p>
                            </div> 
                            
                            <!-- input sales -->
                            <div v-if="salesSelected.length > 0" class="row bottomContain w-100 p-0 m-0" >
                                <div class="col-6 p-0 m-0">
                                    <img v-if="salesSelected[0].GambarPath" class="colorCar d-block mx-2 mr-5 float-right " 
                                        style="border-radius: 50%; width: 10vh !important;" 
                                        :data-image="salesSelected[0].GambarPath"
                                        :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+ salesSelected[0].GambarPath +'&size=1000'">                             
                                    <img v-else class="colorCar d-block mx-2 mr-5 float-right" 
                                        style="border-radius: 50%; width: 10vh !important;" 
                                        :src="this.urlSite + '/assets/img/ic_profile_picture.png'">                            
                                    
                                </div>
                                <div class="col-6 p-0 m-0 text-left">
                                    <div class="row">
                                        <label class="col-12 w-100 m-0 p-0 text-left" >{{salesSelected[0].Nama}}</label>
                                        <p class="col-12 w-100 m-0 p-0  text-left" ><em>{{salesSelected[0].Telp}}</em></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- subtotal -->
                <div class="col-12 bottomContain w-100 p-0 mx-0 mt-2 text-left" >
                    <div class="d-inline ml-2"><strong>Total</strong></div> 
                    <div class="d-inline pull-right mr-2 float-right">
                        <strong >
                            Rp. {{ parseInt(totalPayment).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }}
                        </strong>
                    </div>
                </div> 
                <div class="col-12 bottomContain text-right">
                    <p><small><em class="text-danger">* Nb: Belum Termasuk Ongkir</em></small></p>
                </div> 
                
                <!-- button bayar -->
                <div class="col-12 bottomContain mt-2">
                    <button class="btn float-right btn-warning text-light btn-ksm btnCheckout" @click="btnCheckOut">
                        Process to Checkout
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<modal @address="ReturnAlamatPhone" ref="modalAlamat" />
<modalDetailOrder ref="modalDetailOrder" />
<modalDetailFinish ref="modalDetailFinish" />

<!-- Modal Sales Person-->
<div class="modal fade" id="modalSalesPerson" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header my-0 py-0">
        <h5 class="modal-title" id="exampleModalLabel">Pilih Sales</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-9 "> 
                <input v-model="valueSearch" @change="btnSearch" 
                    class="form-control form-control-sm roundedAll" 
                    type="text" name="searchSales" placeholder="Search" />
            </div>
            <div class="col-3 ml-0 pl-0"> 
                <button @click="btnSearch" class="form-control form-control-sm btn-ksm">Search</button>
            </div>
            <div class="col-12 overflow-auto mt-2" style="height: 80vh !important" > 
                <div v-for="(person, index) in sales" :key="index" 
                    class="card shadows bg-white rounded w-100 p-0 mx-0 my-2">
                    <div class="row w-100" @click="selectedSales(person.kodesales)">  
                        <div class="col-3 d-flex justify-content-center">
                            <img v-if="person.GambarPath" class="colorCar d-block w-75 m-2" style="border-radius: 50%;" 
                                :data-image="person.GambarPath"
                                :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+ person.GambarPath +'&size=1000'">                             
                            <img v-else class="colorCar d-block w-75 m-2" 
                                style="border-radius: 50%;" 
                                :src="this.urlSite + '/assets/img/ic_profile_picture.png'">                            
                            </div> 
                            
                        <div class="col-9 d-flex justify-content-left">
                            <div class="row">
                                <label class="col-12 w-100 m-0 p-0 text-left" ><small>{{person.Nama}}</small></label>
                                <p class="col-12 w-100 m-0 p-0  text-left" ><small><em>{{person.Telp}}</em></small></p>
                            </div>
                        </div>     
                    </div>
                </div>
            </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

<div style="min-height: 150px;" > </div>

</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
import modal from "./ModalAlamat.vue";
import modalDetailOrder from "./ModalDetailOrder.vue";
import modalDetailFinish from "./ModalDetailFinish.vue";
import $ from 'jquery';
export default {
    components: { modal, modalDetailOrder, modalDetailFinish },
    mixins: [nytMixin],
    setup() {
        
    },
    data(){
        return{
            activeList : 'Sparepart List',
            sparepartLists: [],
            carLists: [],
            services: [],
            sparepartOrders: [],
            unitOrders: [],
            finishOrders: [],
            addrCusts : [],
            activeAddr: false,
            totalPayment: 0,
            alamatPenerima: '',
            phonePenerima: '',
            curScreen: screen.width,
            sales: [],
            salesSelected: [],
            statusServices: [],
        }
    },
    beforeMount() {
        //console.log(localStorage.Kode);
        this.getAllContent();
    },
    methods:{
        selectedSales(kodesales = null){
            this.salesSelected = this.sales.filter( x => x.kodesales == kodesales );
            console.log(this.salesSelected);
            $('#modalSalesPerson').modal("hide");
        },
        btnSearch: function(){
            if(this.valueSearch == "")
            {
                this.GetSalesPerson();
            }
            else
            {
                var search = new RegExp(this.valueSearch , 'i');
                this.sales = this.sales.filter(x => search.test(x.Nama));
            }
        },
        async GetSalesPerson()
        {
            var form_data = {};   
            const resultData = await this.FuncGet("/Branch", form_data);
            this.sales = resultData.data.results;

            $('#modalSalesPerson').modal("show");
        },
        setActive: function(param){
            this.activeList = param;
            if(param == 'Sparepart List' && this.sparepartLists.length > 0)
            {
                this.calculateSparpart();
                this.activeAddr = true;
            }
            else if(param == 'Car List' && this.carLists.length > 0)
            {
                for(var index=0; index < this.carLists.length; index++)
                {
                    this.totalPayment += parseInt(this.carLists[index].CarPrice);
                }
                this.activeAddr = true;
            }
            else if(param == 'Status Pembelian' && 
                (this.services.length > 0 || this.sparepartOrders.length > 0 || this.unitOrders.length > 0 ))
            {
                this.activeAddr = false;
            }
            else if(param == 'Status Service' && this.statusServices.length > 0)
            {
                this.activeAddr = false;
            }
            else if(param == 'Pesanan Selesai' && this.finishOrders.length == 0)
            {
                this.activeAddr = false;
            }
            else
            {
                this.activeAddr = false;
            }
           
        },
        async getAllContent() {
            var form_data = {};   
            form_data['idCust'] = localStorage.Kode;
            //console.log(form_data);
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/OrderStatus", form_data);
            
            console.log(resultData.data);
            
            this.sparepartLists = resultData.data.myLists;
            this.carLists = resultData.data.carLists;
            this.services = resultData.data.serviceOrders.result.filter(x => x.MetodeBooking == "APP-WORK").filter(x => x.MetodeBooking == "APP-HOME");
            this.sparepartOrders = resultData.data.sparepartOrders.result;
            this.unitOrders = resultData.data.unitOrders.result;
            this.finishOrders = resultData.data.selesaiOrdersAlt.result;
            this.addrCusts = resultData.data.addrCust;
            this.statusServices = resultData.data.serviceOnProg;
            
            if(this.sparepartLists.length > 0)
            {
                this.activeAddr = true;
            }
            else 
            {
                this.activeAddr = false;
            }

            if(localStorage.context)
            {
                this.setActive(localStorage.context);
                localStorage.context = '';
            }
            else
            {
                this.setActive('Sparepart List');
            }
            
        },
        changeAddress(){
            this.$refs.modalAlamat.showModal();
        },
        async btnCheckOut()
        {
            if(this.activeList == 'Sparepart List')
            {
                if(this.alamatPenerima == "" || this.phonePenerima == "")
                {
                    alert("Lengkapi Alamat dan Nomer Penerima");
                }
                else
                {
                    var myJSON = JSON.stringify(this.sparepartLists);
                    var form_data = {};   
                    form_data['listData'] = myJSON;
                    form_data['alamatPenerima'] = this.alamatPenerima;
                    form_data['phonePenerima'] = this.phonePenerima;
                    const resultData = await this.FuncPost(this.urlSite + "/HttpApi/CheckJsonDecode", form_data);
                    if(resultData.data.search("Success") >= 0 ) 
                    { 
                        this.sparepartLists = [];
                        this.activeAddr = false;
                        localStorage.context = 'Status Pembelian';
                        window.location.href="/ORDERSTATUS";
                    }
                    else
                    {
                        alert("Oops... Something wrong, pleace check your connection!!!");
                    }
                }
            }
            if(this.activeList == 'Car List')
            {
                if(this.alamatPenerima == "" || this.phonePenerima == "" || this.salesSelected.length <= 0)
                {
                    alert("Lengkapi Alamat, Nomer Penerima dan Sales");
                }
                else
                {
                    console.log(this.salesSelected[0].kodesales);
                    var myJSON = JSON.stringify(this.carLists);
                    var form_data = {};   
                    form_data['listData'] = myJSON;
                    form_data['alamatPenerima'] = this.alamatPenerima;
                    form_data['phonePenerima'] = this.phonePenerima;
                    form_data['kodesales'] = this.salesSelected[0].kodesales;
                    const resultData = await this.FuncPost(this.urlSite + "/HttpApi/CheckJsonDecodeCar", form_data);
                    if(resultData.data.search("Success") >= 0)
                    {
                        this.carLists = [];
                        this.activeAddr = false;
                        localStorage.context = 'Status Pembelian';
                        window.location.href="/ORDERSTATUS";
                    }
                    else
                    {
                        alert("Ooops!!! Failed Delete Order, Please Check Your Connection");
                    }
                }
            }
        },
        EditQuantity: function(index, status)
        {
            if(status == 'inc')
            {
                this.sparepartLists[index].Quantity = parseInt(this.sparepartLists[index].Quantity) + 1;
                this.calculateSparpart();
            }
            else
            {
                if(this.sparepartLists[index].Quantity > 1)
                {
                    this.sparepartLists[index].Quantity = parseInt(this.sparepartLists[index].Quantity) - 1;
                    this.calculateSparpart();
                }
            }
            
        },
        calculateSparpart()
        {
            this.totalPayment = 0;
            for(var index = 0; index < this.sparepartLists.length; index++)
            {
                this.totalPayment += parseInt(this.sparepartLists[index].Harga) * parseInt(this.sparepartLists[index].Quantity);
            }
        },
        async CancelOrder(idWishList)
        {
            var form_data = {};   
            form_data['idWishList'] = idWishList;
            if(this.activeList == 'Sparepart List')
            {
                form_data['active'] = 'Sparepart List';
            }
            else 
            {
                form_data['active'] = 'Car List';
            }
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/CancelOrder", form_data);
            if(resultData.data.search("Failed") >= 0)
            {
                alert("Ooops!!! Failed Delete Order, Please Check Your Connection");
            }
            else
            {
                if(this.activeList == 'Sparepart List')
                {
                    this.sparepartLists = this.sparepartLists.filter(function(spare){
                        return spare.IdWishList != idWishList;
                    });
                    if(this.sparepartLists.length == 0)
                    {
                        this.activeAddr = false;
                    }    
                }
                else
                {
                    this.carLists = this.carLists.filter(function(car){
                        return car.IdWishListCar != idWishList;
                    });
                    if(this.carLists.length == 0)
                    {
                        this.activeAddr = false;
                    }
                }
                
            }
        },
        ReturnAlamatPhone(returnData)
        {
            this.alamatPenerima = returnData.alamat;
            this.phonePenerima = returnData.phone;
            this.$refs.modalAlamat.hideModal();
        },
        showDetailOrder(kodeNotaSo){
            this.$refs.modalDetailOrder.showModal();
            this.$refs.modalDetailOrder.setKodeSO(kodeNotaSo);
        },
        detailSelesai(invoice, type)
        {
            this.$refs.modalDetailFinish.showModal();
            this.$refs.modalDetailFinish.getDetailFinish(invoice, type);
        }
    }
}
</script>