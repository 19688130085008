<template>
    <div class="row w-100 mx-0 my-5 p-0 " 
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 110px !important;' : ''">
        <div class="col-12 w-100"> 
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="removeBlueLink" href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Test Drive</li>
                </ol>
            </nav>
        </div>
       
        <div class="col-12 w-100">
            <form method="post" action="#" >
                <input type="hidden" name="refby" v-model="refby" />
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Nama"><strong>Nama Lengkap</strong></label> 
                                <input type="text" class="form-control Nama" 
                                    id="Nama" name="username" placeholder="Nama" 
                                    v-model="custs.username" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Nik"><strong>NIK</strong></label> 
                                <input type="number" class="form-control Nik" id="Nik" name="nik" 
                                    v-model="custs.nik" placeholder="NIK" >
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Email"><strong>Email</strong></label> 
                                <input type="email" class="form-control Email" id="Email" 
                                    v-model="custs.email" name="email" placeholder="Email" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Phone"><strong>Phone</strong></label> 
                                <input type="tel" class="form-control Phone" id="Phone" 
                                    v-model="custs.phone" name="phone" placeholder="Phone" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Gender"><strong>Gender</strong></label> 
                                <select class="form-control Gener" id="Gender" v-model="custs.gender" name="gender" >
                                    <option value="Laki-Laki">Laki-Laki</option>
                                    <option value="Perempuan">Perempuan</option>
                                </select>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <input type="hidden" class="form-control Ttl" v-model="custs.ttl" 
                            id="Ttl" name="tgl" >
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Model"><strong>Model</strong></label> 
                                <select class="form-control Model" id="Model" 
                                    v-model="custs.model" name="model" >
                                    <option v-for="(unit, index) in units" :key="index" 
                                        :value="unit.Unit">
                                        {{unit.Keterangan}}
                                    </option>
                                </select>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group form-row">
                                <label for="TglTestDrive"><strong>Tanggal Test Drive</strong></label> 
                                <input type="date" class="form-control TglTestDrive" id="TglTestDrive" 
                                    name="TglTestDrive" v-model="custs.TglTestDrive" :min="minDate" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group form-row">
                                <label for="TimeTestDrive"><strong>Jam Test Drive</strong></label> 
                                <input type="time" class="form-control TimeTestDrive" 
                                    v-model="custs.TimeTestDrive" id="TimeTestDrive" @change="changeTime"
                                    name="TimeTestDrive" min="08:00" max="16:30" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div class="form-group form-row">
                                <label for="Alamat"><strong>Alamat</strong></label> 
                                <textarea type="text" class="form-control Alamat" id="Alamat" 
                                    v-model="custs.alamat" name="alamat" placeholder="Alamat" rows="3" required></textarea>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div class="form-group form-row">
                                <label for="Pertanyaan"><strong>Pertanyaan</strong></label> 
                                <textarea type="text" class="form-control Pertanyaan" id="Pertanyaan" name="Pertanyaan" 
                                    placeholder="Pertanyaan" rows="3" v-model="custs.pertanyaan" ></textarea>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-ksm float-right btnKonsul" @click="insertTestDrive" >Submit</button>
			</form>
        </div>
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    data(){
        return{
            units: [],
            custs: {
                username: '',
                email: '',
                phone: '',
                nik: '',
                kota: '',
                tgl: '',
                gender: 'Laki-Laki',
                model: '',
                lokasi: '',
                ttl: '',
                TglTestDrive: '',
                TimeTestDrive: '',
                alamat: '',
                pertanyaan: '',
                refby: '99/99/SA'

            },
            minDate: '',
            curScreen: screen.width,
        }
    },
    setup() {
        
    },
    beforeMount(){
        this.GetUnitsTestDrive();
        
        /* set min date test drive */
        var d = new Date();
        var mm = String(d.getMonth() + 1).padStart(2, '0');
        var dd = String(d.getDate() + 1).padStart(2, '0');
        var yy = d.getFullYear();
        var myDateString = yy + '-' + mm + '-' + dd ;
        this.minDate = myDateString;
    },
    methods:{
        changeTime: function()
        {
            var getMin = document.getElementById("TimeTestDrive").min;
            var getMax = document.getElementById("TimeTestDrive").max;
            
            var currentD = new Date();
            currentD.setHours(parseInt(this.custs.TimeTestDrive.substring(0,2)),parseInt(this.custs.TimeTestDrive.substring(3,5)),0);
            var startHappyHourD = new Date();
            startHappyHourD.setHours(parseInt(getMin.toString().substring(0,2)),parseInt(getMin.toString().substring(3,5)),0); 
            var endHappyHourD = new Date();
            endHappyHourD.setHours(parseInt(getMax.toString().substring(0,2)),parseInt(getMax.toString().substring(3,5)),0);
            if(currentD < startHappyHourD || currentD > endHappyHourD)
            {
                this.custs.TimeTestDrive = "08:00";
                alert("Jam Service Dimulia 08:00 - 16:30");
            }
        },
        async GetUnitsTestDrive()
        {
            var form_data = {};
            const resUnits = await this.FuncGet("/GetUnitTestDrive", form_data);
            this.units = resUnits.data;
        },
        async insertTestDrive()
        {
            if(this.custs.username != "" && this.custs.email != "" && this.custs.phone != ""
                && this.custs.gender != "" && this.custs.model != "" && this.custs.TglTestDrive != ""
                && this.custs.TimeTestDrive != "" && this.custs.alamat != "")
            {
                const retTestDrive = await this.FuncPost(this.urlSite + '/HttpApi/AddTestDrive', this.custs);
                if(retTestDrive.data.search("Success") >= 0)
                {
                    window.location.href="/";
                }
                else
                {
                    alert("Gagal Booking, Ulangi Lagi");
                }
            }
            else
            {
                alert("Lengkapi Form Anda");
            }
        }
        
    }
}
</script>