<template>
    <div class="row w-100 mx-0 my-4 p-0 " 
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 110px !important;' : ''">
        
        <div class="col-12 w-100">
            <form method="post" action="#" >
                <input type="hidden" name="refby" v-model="refby" />
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Nama"><strong>Nama Lengkap</strong></label> 
                                <input type="text" class="form-control Nama" 
                                    id="Nama" name="username" placeholder="Nama" 
                                    v-model="custs.username" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Ttl"><strong>Tanggal Lahir</strong></label> 
                                <input type="date" class="form-control Ttl" id="Ttl" name="Ttl" 
                                    v-model="custs.ttl"  >
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Email"><strong>Email</strong></label> 
                                <input type="email" class="form-control Email" id="Email" 
                                    v-model="custs.email" name="email" placeholder="Email" required>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Phone"><strong>Phone</strong></label> 
                                <input type="tel" class="form-control Phone" id="Phone" 
                                    v-model="custs.phone" name="phone" placeholder="Phone" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Gender"><strong>Gender</strong></label> 
                                <select class="form-control Gener" id="Gender" v-model="custs.gender" name="gender" >
                                    <option value="Laki-Laki">Laki-Laki</option>
                                    <option value="Perempuan">Perempuan</option>
                                </select>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Model"><strong>Model</strong></label> 
                                <select class="form-control Model" id="Model" 
                                    v-model="custs.model" name="model" >
                                    <option value="AVANZA">AVANZA</option>
                                    <option value="RAIZE">RAIZE</option>
                                    <option value="VELOZ">VELOZ</option>
                                    <option value="FORTUNER">FORTUNER</option>
                                    <option value="ZENIX">ZENIX</option>
                                </select>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div class="form-group form-row">
                                <label for="Model"><strong>Lokasi Test Drive</strong></label> 
                                <select class="form-control Model" id="Model" 
                                    v-model="custs.lokasi" name="model" >
                                    <option value="SHOW ROOM">SHOW ROOM</option>
                                    <option value="MOG">MOG</option>
                                    <option value="MATOS">MATOS</option>
                                    <option value="TAMAN DAYU">TAMAN DAYU</option>
                                    <option value="BLITAR">BLITAR</option>
                                    <option value="LUMAJANG">LUMAJANG</option>
                                    <option value="ARAYA">ARAYA</option>
                                </select>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div class="form-group form-row">
                                <label for="Alamat"><strong>Alamat</strong></label> 
                                <textarea type="text" class="form-control Alamat" id="Alamat" 
                                    v-model="custs.alamat" name="alamat" placeholder="Alamat" rows="3" required></textarea>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        
                    </div>
                    <button type="button" class="btn btn-ksm btn-block btnKonsul" @click="insertTestDrive" >Submit</button>
			</form>
        </div>
    </div>
    <div style="min-height: 75px !important;"></div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    data(){
        return{
            custs: {
                username: '',  
                phone: '',
                gender: 'Laki-Laki',
                model: '',
                lokasi: '',
                ttl: '',
                alamat: '',
                refby: '99/99/SA',
                email: '',
            },
            curScreen: screen.width,
        }
    },
    setup() {
        
    },
    beforeMount(){
       
    },
    methods:{
        
        async insertTestDrive()
        {
            if(this.custs.username != "" && this.custs.phone != ""
                && this.custs.gender != "" && this.custs.model != "" 
                && this.custs.alamat != "" && this.custs.lokasi != "")
            {
                const retTestDrive = await this.FuncPost(this.urlSite + '/HttpApi/AddTestDriveNew', this.custs);
                
                if(retTestDrive.data.search("Success") >= 0)
                {
                    window.location.href="/MapTestDriveNew";
                }
                else
                {
                    alert("Gagal Booking, Ulangi Lagi");
                }
                
            }
            else
            {
                alert("Lengkapi Form Anda");
            }
        }
        
    }
}
</script>