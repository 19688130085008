<template>
    <div class="container">
        <div id="map" style=" height:300px; margin: 0; padding: 0;">
        </div>
        <span>{{x}}, {{y}}</span><br>
        <canvas id="myCanvas" width="300" height="360" @mousedown="beginDrawing" 
            @mousemove="keepDrawing" @mouseup="stopDrawing" />
        <button class="btn btn-sm btn-info" @click="saveToImg">download</button>
    </div>
</template>
<style>
#myCanvas {
  border: 1px solid grey;
}
</style>
<script>
import $ from 'jquery'

import { Loader } from '@googlemaps/js-api-loader';
import myImage from "../assets/images/testcanvas.jpg";

export default{
    data(){
        return{
            map: null,
            mapCenter: {
                lat: -7.936601925602069, lng: 112.62635350227356
            },
            restourant : {
                lat: -7.937601925602069, lng: 112.62735350227356
            },
            canvas: null,
            x: 0,
            y: 0,
            isDrawing: false
        }
    },
    methods:{
        drawLine(x1, y1, x2, y2) {
            let ctx = this.canvas;
            ctx.beginPath();
            ctx.strokeStyle = 'black';
            ctx.lineWidth = 1;
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.stroke();
            ctx.closePath();
        },
        beginDrawing(e) {
            this.x = e.offsetX;
            this.y = e.offsetY;
            this.isDrawing = true;
        },
        keepDrawing(e) {
            if (this.isDrawing === true) {
                this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
                this.x = e.offsetX;
                this.y = e.offsetY;
            }
        },
        stopDrawing(e) {
            if (this.isDrawing === true) {
                this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
                this.x = 0;
                this.y = 0;
                this.isDrawing = false;
            }
        },
        saveToImg()
        {
            
            const image = new Image();
            image.load()
                .then((image) => {
                    console.log('this:', this.canvas);
                    this.canvas.drawImage(this.canvas, this.x, this.y);
                });
        },
        
        initMap(){
            const loader = new Loader({
                apiKey: "AIzaSyDMP1_zMlTEy15t1s85ja6EPtxcp5IOBRU", 
                version: "weekly",
                libraries: ["places"]
                });

                const mapOptions = {
                    center: this.mapCenter,
                    zoom: 17,
                    streetViewControl: true,
                    mapTypeControl: true,
                    fullscreenControl: true,
                    zoomControl: true,
                };

                loader.load()
                    .then((google) => {
                        this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
                        
                        const markers = new google.maps.Marker({
                            position: this.restourant,
                            map: this.map,
                            icon: require("../assets/images/stop.png")

                        });
                        const markers2 = new google.maps.Marker({
                            position: this.mapCenter,
                            map: this.map,
                            icon: require("../assets/images/stop.png")

                        });
                        

                        let dD = new google.maps.DirectionsRenderer();
                        dD.setOptions({
                            polylineOptions: {
                                strokeColor: '#fc0303'
                            },
                            suppressMarkers: true
                        });
                        dD.setMap(this.map);
                        let trafficLayer = new google.maps.TrafficLayer();
                        trafficLayer.setMap(this.map);

                        let request = {
                            origin: this.mapCenter,
                            destination: this.restourant,
                            travelMode: 'WALKING'
                        };

                        let dS = new google.maps.DirectionsService();
                        
                        dS.route(request, function(result, status)
                        {
                            if(status == 'OK'){
                                console.log(result);
                                dD.setDirections(result);
                                
                            }
                            else{
                                console.log(status);
                            }
                        });
                    })
                    .catch(e => {
                        console.log("error");
                        console.log(e);
                    });
          
        },
    },
    mounted(){
        this.initMap(); 
        
        var c = document.getElementById("myCanvas");
        this.canvas = c.getContext('2d');
    }
}
</script>