<template>
    <div class="col-12 w-100" style="padding-top: 15px;">
        <p class="text-left"><strong>LATES PROMO</strong></p>
    </div>
    <a v-for="promo in promos" :key="promo.Nama" :href="'/DetailPromo/' + promo.Nama" class="removeBlueLink">
        <div class="card card-block shadow bg-white rounded w-100 mx-0 my-2 p-0 text-left" >
            <div class="row w-100 m-0 p-0">
                <div class="col-4" style="margin-top: auto; margin-bottom: auto;" >
                    <img class="img-fluid" 
                        :src="this.urlSite + promo.Link" >	
                </div> 
                <div class="col-8 w-100 m-0 p-0">
                    <p class="mb-0 pb-0 pt-1 mt-1"><small><em>{{ promo.CreateDate.substring(0,10) }}</em></small></p>
                    <p class="my-0 py-0"><small>{{ promo.Nama }}</small></p>
                </div>
            </div>
        </div>
    </a>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";

export default {
  mixins: [nytMixin],
  data() {
    return{
      promos: [],
    }
    
  },
  beforeMount() {
    this.GetPromos();

    
  },
  mounted(){
    
  },
  methods: {
    async GetPromos(){
       const resCarousel = await this.HomeCarousel();
      this.promos = resCarousel.data.promos;
    },
  },
};
</script>