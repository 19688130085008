<template>
    <div style="height: 200px;"></div>
   
    <label>{{mymarker.currentPosition.lat}}</label>
    <br>
    <label>{{mymarker.currentPosition.lng}}</label>
</template>
<script>
export default {
    data() {
    return{
         mymarker:  {
                        currentPosition : { lat : 0, lng : 0},  
                    },
      
    }
    
  },
  beforeMount() {
    this.locatorButtonPressed();
  },
  mounted(){
    this.myposition();

  },
  methods: {
   async locatorButtonPressed() {
        navigator.geolocation.getCurrentPosition(
            position => {
                //this.getStreetAddressFrom(position.coords.latitude, position.coords.longitude)
                console.log("post", position.coords.latitude, position.coords.longitude);
            },
            error => {
                console.log(error.message);
            }
        );

        const resultData = await this.FuncGetSecond("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDMP1_zMlTEy15t1s85ja6EPtxcp5IOBRU");
        console.log("api", resultData);
    },
    myposition : function() {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(
                            this.successPosition,
                            this.failurePosition, 
                            {
                                enableHighAccuracy: true,
                                timeout:1000,
                                maximumAge: 4000
                            }
                        );
        }else{
            document.getElementById("resulttest").innerHTML = "Your browser does not support Geolocation API!!!";
        }
    },
    successPosition: function(position){
            this.mymarker.currentPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
    },
    failurePosition: function(err){
        alert("Error Code: " + err.code + " Error Message: " + err.message);
    },
    
  },
  computed: {
    
}
};
</script>