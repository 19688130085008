import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { dom } from '@fortawesome/fontawesome-svg-core'

import firebase, { messaging } from "firebase/app";
import "firebase/messaging";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import VueSignature from "vue-signature-pad";

import print from 'vue3-print-nb'

import {parse} from 'himalaya'

const pjson = require('../package.json');

/* new */
import { FCM } from "capacitor-fcm";
const fcm = new FCM();
import { Plugins, Capacitor,
        PushNotificationActionPerformed, 
        PushNotificationToken,
        Geolocation } from '@capacitor/core';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { loadingController } from '@ionic/vue';
const { PushNotifications, LocalNotifications, Device } = Plugins;

/* end new 

firebase.initializeApp({
    apiKey: "AIzaSyAEKl7Xf_PePtSV0kuS7iYA_8HevQzBnk4",
    authDomain: "ksmproject-a738c.firebaseapp.com",
    databaseURL: "https://ksmproject-a738c-default-rtdb.firebaseio.com",
    projectId: "ksmproject-a738c",
    storageBucket: "ksmproject-a738c.appspot.com",
    messagingSenderId: "678632161303",
    appId: "1:678632161303:web:1ed93d81492b34b3715c52",
    measurementId: "G-1GRD1PHQJY"
  });

navigator.serviceWorker
  .register("firebase-messaging-sw.js")
  .then((registration) => {
    const messaging = firebase.messaging();
    messaging.useServiceWorker(registration);
  })
  .catch((err) => {
    console.log(err);
  });
*/
const axios = require('axios');

dom.watch()

library.add(fas)
library.add(far)
library.add(fab)

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(print)
  .use(VueToast, {
    position: 'bottom'
  })
  .use(VueSignature)
  .mixin({
    data() {
        return{
            urlSite: 'https://admin.kartikasari.co.id',
            urlApiLoad: 'https://apps.kartikasari.co.id/Api/api2Load',
            token: 'b4f392359aad35335be8eac4cf9d7f5c',
            uniqueCode: 'BN',
            idLocalNotif: 0,
            listNotif: [],
            myLocation: {
              lat: 0,
              lon: 0
            },
            //dataUnitCompare: [],
            //lengthUnitCompare: 0,
            myVersion : "1.29", //versi google play 1.29, jangan lupa ganti versi di API 
        }
    },
    methods: { 
      getApkVersionApi: function(platform)
        {
            try{
                const resData = axios.get("https://admin.kartikasari.co.id/HttpApi/CheckVersionApp/" + platform);
                return resData;
            }catch(error)
            {
                console.log(error);
            }  
        },
        
      isMobile(){
        if( (Capacitor.getPlatform() === 'android' || screen.width <= 760) ||
            ( Capacitor.getPlatform() === 'ios' && screen.width <= 1100 ) )
        {
          return true;
        }
        else
        {
          return false;
        }
      },
      isIos(){
        if( Capacitor.getPlatform() === 'ios' ) 
        {
          return true;
        }
        else
        {
          return false;
        }
      },
      globalTest: function(){
          console.log("show message");
          alert("hehehe");
      },
      getMessage(message: string) {
        return message;
      },
      FuncPostSecond: function(url: any, myFormData: any){
          try{
              const form_data = new FormData();
              const getKey = Object.keys(myFormData);
              const getValue = Object.values(myFormData);
              for(let key=0; key<getKey.length; key++)
              {
                  const newData = this.getMessage(getValue[key]);
                  form_data.append(getKey[key], newData);
              }
              const resPostData = axios({
                  method: 'post',
                  url: url, 
                  data: form_data,
                  headers: { "Content-Type": "multipart/form-data" }
              });
              return resPostData;
              
          }catch(error){
              console.log(error);
          }
      },
      FuncGetToken: function()
      {
        try{
            const resPostData = axios({
                method: 'post',
                url: 'https://admin.kartikasari.co.id/TestJWT/get_post', 
                data: '',
                headers: { }
            });
            return resPostData;
        } catch(error){
            console.log(error);
        }
      },
      FuncGetJwt: function(url = "", myFormData = [], token = "") {
        try{
            let getValue = [];
            let params = '';
            getValue = Object.values(myFormData);
            
            for(let key=0; key<getValue.length; key++)
            {
                params += getValue[key] +'/';
            }
            const resPostData = axios({
                method: 'get',
                url: url, 
                data: params,
                headers: {
                  'Content-type': 'application/json',
                  'Authorization': 'Bearer ' + token 
                }
            });
          return resPostData;
            
        }catch(error){
            console.log(error);
        }
      },
      async testNewPost(url:any, myFormData: any, token = "")
      {
        const loading = await loadingController.create({
            message: 'Please Wait...',
            spinner: 'circles'
        });
        loading.present();

        const retData = await this.FuncPostJwt(url, myFormData, token);

        loading.dismiss();
        return retData;
      },
      FuncPostJwt: function(url: any, myFormData: any, token = ""){
          try{
              const form_data = new FormData();
              const getKey = Object.keys(myFormData);
              const getValue = Object.values(myFormData);
              for(let key=0; key<getKey.length; key++)
              {
                  const newData = this.getMessage(getValue[key]);
                  form_data.append(getKey[key], newData);
              }
              const resPostData = axios({
                  method: 'post',
                  url: url, 
                  data: form_data,
                  headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + token 
                  }
              });
              return resPostData;
              
          }catch(error){
              console.log(error);
          }
      },
      FuncGetSecond: function(url: any){
          try{
              const resPostData = axios({
                  method: 'get',
                  url: url, 
                  headers: { "Content-Type": "multipart/form-data" }
              });
              return resPostData;
              
          }catch(error){
              console.log(error);
          }
      },
      funcLogoutSecond: function()
      {
          try{
              localStorage.clear();
              return true;
          } catch(error){
              console.log(error);
          }
      },
      //belum di pakai... next aja lah ya
      eventGoBack: function(componenToClose = "")
      {
          if(componenToClose != "")
          {
              console.log("isi komponen to close");
              console.log(componenToClose);
          }
          else
          {
              console.log("back aja");
          }
      },
      ClearLocalStorage: function()
      {
          localStorage.Kode = ''
          localStorage.Email = '';
          localStorage.Telp = '';
          localStorage.Alamat = '';
          localStorage.Kota = '';
          localStorage.TglLahirOwner = '';
          localStorage.myUnitCompare = '';
      },
      /* new */
      async removeAllNotif()
      {
        await PushNotifications.removeAllDeliveredNotifications();
      },
      async GetDelivNotif(id: number, title: string, text: string, key: string){
        if(localStorage.IsClose == "false")
        {
          const notificationList = await PushNotifications.getDeliveredNotifications();
          //console.log("list notification", JSON.stringify(notificationList));
          this.idLocalNotif = this.idLocalNotif + 1;
          this.showLocalNotification(this.idLocalNotif, title, text, key);
          await PushNotifications.removeAllDeliveredNotifications();
        }
      },
      async showLocalNotification(id : number, title : string, text : string, key: string){
        LocalNotifications.schedule({
          notifications: [
            {
              title: title,
              body: text,
              id: id,
              schedule: { at: new Date(Date.now() + 1000 * 1) },
              actionTypeId: "",
              smallIcon: "ksmicon.png",
              extra: null
            }
          ]
        });
      },
      async pushInit() {
        try {

            PushNotifications.addListener('registrationError', (error) =>          
            {
              console.log('error on register ${JSON.stringify(error)}');
            });
          
            PushNotifications.addListener('pushNotificationReceived', (notification : PushNotificationSchema) => 
            {
                const getNotif = JSON.parse(JSON.stringify(notification));
                console.log('New Notification', JSON.stringify(notification));
                
                /*
                if(localStorage.listNotif)
                {
                  this.listNotif.push(JSON.parse(localStorage.listNotif));
                }
                
                //console.log("local list before ", JSON.stringify(this.listNotif));
                this.listNotif.push(notification);
                //console.log("list push", JSON.stringify(this.listNotif));
                localStorage.listNotif = JSON.stringify(this.listNotif);
                //console.log("local storage after", localStorage.listNotif);
                */

                console.log(localStorage.SettingNotif);

                const getSettingNotif = JSON.parse(localStorage.SettingNotif);
                getSettingNotif.forEach(element => {
                  if(getNotif.title == element.NamaNotif && element.IsActive == "true")
                  {
                    console.log("masuk true");
                    this.GetDelivNotif(getNotif.id, getNotif.title, getNotif.body, getNotif.data.keyData);
                  }
                  else
                  {
                    console.log("masuk false");
                    this.removeAllNotif();
                  }
                });
                

            });
            PushNotifications.addListener('registration', 
                (token: PushNotificationToken) => {
                    console.log('its my token2', token.value);
                    //alert('Push registration success, token: ' + token.value);
            });
          
            PushNotifications.addListener('pushNotificationActionPerformed', 
                (notification: PushNotificationActionPerformed) => {
                   // alert('Push action performed: ' + JSON.stringify(notification));
                   console.log('push action performed ', notification.notification.data.keyData); //keyData adalah nama object yg di firebase
                   if(notification.notification.data.keyData == "notif")
                   {
                    window.location.href="/Notif";
                   }
            });

            PushNotifications.register();
            const fcmToken = await fcm.getToken();
            const stringToken = JSON.parse(JSON.stringify(fcmToken));
            //console.log("Token Mobile ", stringToken.token);
            localStorage.tokenFirebase = stringToken.token;

          } catch (e) {
            console.log(e);
          }
      },
      /* end new */
      getAddressCustSecond: function(lat = null, lon = null)
      {
          try{
              const getAddress = axios.get(
                                  "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                                  lat +
                                  "," +
                                  lon +
                                  "&key=AIzaSyDMP1_zMlTEy15t1s85ja6EPtxcp5IOBRU");
              return getAddress;
          } catch(error){
              console.log(error);
          }
      },
      async GetPosition()
      {
        const position = await Geolocation.getCurrentPosition();
        this.myLocation.lat = position.coords.latitude;
        this.myLocation.lon = position.coords.longitude;
        
        const getPath = window.location.pathname;
        if( (getPath.search("DetailCar") >= 0 || getPath.search("DetailProduct") >= 0
            || getPath.search("News") >= 0
          ) 
          //&& localStorage.Kode
        )
        {
          const getAddress = await this.getAddressCustSecond(this.myLocation.lat, this.myLocation.lon);
          const form_data = {};   
			    form_data['Ip'] = "";
          form_data['Location'] = getAddress.data.results[0].address_components[0].short_name;
          form_data['Lat'] = this.myLocation.lat;
          form_data['Lon'] = this.myLocation.lon;
          form_data['Content'] = getPath;
          form_data['Gemail'] = localStorage.Email || '';
          form_data['Gid'] = localStorage.Kode || '';
          if(this.isMobile())
          {
            form_data['StatusAkses'] = "APP";
          }
          else
          {
            form_data['StatusAkses'] = "WEB";
          }
          const resultData = await this.FuncPostSecond("https://admin.kartikasari.co.id/HttpApi/InsertVisitor", form_data);
          console.log(resultData);
        }

      },
      async checkToken()
      {
          if(localStorage.Token)
          {
              console.log("token sudah ada");
          }
          else
          {
              const resultData = await this.FuncGetToken();
              if(resultData.data.kode == '200')
              {
                  localStorage.Token = resultData.data.data.token;
                  localStorage.Exp = resultData.data.data.exp;
                  
              }
              else
              {
                  alert("Can't Get Token");
              }
          }
      },
      
      

    },
    beforeMount(){
      //this.checkToken();
      this.GetPosition();


    },
    mounted()
    {
      

    },
    
  })
  .component('font-awesome-icon', FontAwesomeIcon);
  

router.isReady().then(() => {
  app.mount('#app');
});