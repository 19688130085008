<template>
   
    <!-- ***** Main Banner Area Start ***** -->
    <section class="section pt-0 mt-5"
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 110px !important;' : ''">
        <div class="container text-left">
           <!-- <form method="post" action="#" > -->
				<div class="form-group form-row">
					<div class="col">
						<label for="Keperluan"><strong>Keperluan</strong></label>
						<div class="row ml-2">
							<div class="form-check form-check-inline">
								<input class="form-check-input" v-model="custUnit.Keperluan" type="radio" name="Keperluan" id="Keperluan" value="Booking Service" checked readonly>
								<label class="form-check-label" for="Keperluan">Booking Service</label>
							</div>
						</div>
						<em class="text-danger">*wajib di isi</em>
					</div>
                    <!--
					<div class="col">
						<label for="Keperluan"><strong>Estimasi Pembelian Mobil</strong></label>
						<div class="row ml-2">
							<div class="form-check form-check-inline">
								<input class="form-check-input" v-model="custUnit.EstimasiBeli" type="radio" id="EstimasiBeli" name="EstimasiBeli" value="Kurang 3 Bln" checked>
								<label class="form-check-label" for="EstimasiBeli">0-3 Bulan</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" v-model="custUnit.EstimasiBeli" type="radio" id="EstimasiBeli2" name="EstimasiBeli" value="Lebih 3 Bln">
								<label class="form-check-label" for="EstimasiBeli2">3 Bulan Keatas</label>
							</div>
						</div>
						<em class="text-danger">*wajib di isi</em>
					</div>
                    -->
				</div>
				<div class="form-group form-row">
					<div class="col">
						<label for="Nama"><strong>Nama</strong></label> 
						<input type="text" v-model="custUnit.Nama" class="form-control Nama" id="Nama" name="Nama" placeholder="Nama" required>
						<em class="text-danger">*wajib di isi</em>
					</div>
					<div class="col">
						<label for="Phone"><strong>Phone</strong></label> 
						<input type="text" v-model="custUnit.Phone" class="form-control Phone" id="Phone" name="Phone" placeholder="+62 xxx xxx xxx" required>
						<em class="text-danger">*wajib di isi</em>
					</div>
					<div class="col">
						<label for="Email"><strong>Email</strong></label> 
						<input type="text" v-model="custUnit.Email" class="form-control Email" id="Email" name="Email" placeholder="Name@kartika.co.id" required>
						<em class="text-danger">*wajib di isi</em>
					</div>
				</div>
				<div class="form-group form-row">
					<div class="col">
						<label for="Model"><strong>Model</strong></label>
						<select class="form-control" id="Model" name="Model" 
                            @change="GetTypeCar" v-model="custUnit.Model" required>
							<option value="">Select Car Name</option>
                            <option v-for="(unit, index) in jenisUnit" :key="index" 
                                :value="unit.JenisKendaraan">{{unit.JenisKendaraan}}</option>
						</select>
						<em class="text-danger">*wajib di isi</em>
					</div>
					<div class="col">
						<label for="Type"><strong>Type</strong></label>
						<select class="form-control" id="Type" name="Type" v-model="custUnit.Type" required>
							<option value="">Select Car Type</option>
							<option v-for="(type, index) in tipeUnit" :key="index" 
                                :value="type.Keterangan">{{type.Keterangan}}</option>
						</select>
						<em class="text-danger">*wajib di isi</em>

					</div>
				</div>
				<div>
					<label for="Pertanyaan"><strong>Pertanyaan</strong></label>
					<textarea v-model="custUnit.Pertanyaan" class="form-control" id="Pertanyaan" name="Pertanyaan" rows="3" required></textarea>
					<em class="text-danger">*wajib di isi</em>
				</div>
				<div class="form-check form-check-inline mt-2">
					<input class="form-check-input" type="checkbox" id="agreement" @change="CheckAgree" >
					<em class="form-check-label text-danger" for="agreement"> <strong>I have read and agree to the Terms 
						and Conditions and Privacy Policy</strong></em>
				</div>
				<button type="button" class="btn btn-ksm float-right" @click="submitKonsul" 
                    :disabled="btnDisabled == true" >Submit</button>
			<!-- </form> -->
		</div>
    </section>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    data(){
        return{
            custUnit: {
                Keperluan: 'Booking Service',
                EstimasiBeli: '',
                Nama: '',
                Phone: '',
                Email: '',
                Model: '',
                Type: '',
                Pertanyaan: '',
            },
            jenisUnit: [],
            tipeUnit: [],
            btnDisabled: true,
            curScreen: screen.width,
        }
    },
    setup() {
        
    },
    beforeMount() {
        this.GetJenisUnit();
    },
    methods:{
        async GetJenisUnit(){
            var form_data = {};
            var resultData = await this.FuncGet("/GetAllJenisKendaraan", form_data);
            this.jenisUnit = resultData.data;
        },
        async GetTypeCar(event){
            var form_data = {};
            form_data["Jenis"] = event.target.value;
            var resultData = await this.FuncGet("/GetAllUnit", form_data);
            this.tipeUnit = resultData.data;
        },
        async submitKonsul()
        {
            if(this.custUnit.Keperluan != '' && this.custUnit.Nama != ''
                && this.custUnit.Phone != '' && this.custUnit.Email != '' && this.custUnit.Model != '' 
                && this.custUnit.Type != '' && this.custUnit.Pertanyaan != '' )
            {
                const addKonsul = await this.FuncPost(this.urlSite + "/HttpApi/AddKonsul", this.custUnit);
                if(addKonsul.data.search("Success") >= 0)
                {
                    window.location.href='/';
                }
                else
                {
                    alert("Gagal Insert Konsultasi Unit");
                }
            }
            else
            {
                alert("Lengkapi Form Anda!");
            }

        },
        CheckAgree: function(event)
        {
            if(event.target.checked == true)
            {
                this.btnDisabled = false;
            }
            else
            {
                this.btnDisabled = true;
            }
        }
    }
}
</script>