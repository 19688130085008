const axios = require('axios');
const apiUrl = 'https://admin.kartikasari.co.id/HttpApi/testApi2';
const apiHttp = "https://admin.kartikasari.co.id/HttpApi/";

//import { Capacitor} from '@capacitor/core';

export const nytMixin = {
    methods: {
        globalTest: function(){
            try {
                const resData = axios.get(apiUrl);
                return resData;
            }
            catch (error) {
                console.log(error);
            }
            
        },
        HomeCarousel: function(){
            try{
                const resDataCarousel = axios.get(apiHttp + "HomeCarousel");
                //console.log(resDataCarousel);
                return resDataCarousel;
            }catch(error){
                console.log(error);
            }
        },
        HomeCarCatalogue: function(){
            try{
                const resDataCarousel = axios.get(apiHttp + "GetCarCatalogue");
                return resDataCarousel;
            }catch(error){
                console.log(error);
            }
        },
        DataShoping: function(param = ""){
            try{
                if(param)
                {
                    param = param.replace("/", "-");    
                }
                const resDataShoping = axios.get(apiHttp + "GetShoping/" + param);
                return resDataShoping;
            }catch(error){
                console.log(error);
            }
        },
        /*
        isMobile(){
            if(Capacitor.getPlatform() === 'android' || screen.width <= 760)
            {
              return true;
            }
            else
            {
              return false;
            }
          },
          */
        GetSparepart: function(param = null) {
            try{
                const resDataSparepart = axios.get( apiHttp + "GetSpareparts/" + param);
                return resDataSparepart;
            }catch(error){
                console.log(error);
            }
        },
        GetDetailCar: function(param = ""){
            try{
                const resDataSparepart = axios.get( apiHttp + "GetDetailCar/" + param);
                return resDataSparepart;
            }
            catch(error){
                console.log(error);
            }
        },
        FuncPost: function(url = "", myFormData = []){
            try{
                var getKey = [];
                var getValuePost = [];
                var form_data = new FormData();
                getKey = Object.keys(myFormData);
                getValuePost = Object.values(myFormData);
                
                for(var key=0; key<getKey.length; key++)
                {
                    form_data.append(getKey[key], getValuePost[key]);
                }
                const resPostData = axios({
                    method: 'post',
                    url: url, 
                    data: form_data,
                    headers: { 
                        "Content-Type": "multipart/form-data",
                    }
                });
                return resPostData;
                
            }catch(error){
                console.log(error);
            }
        },
        FuncGet: function(url = "", myFormData = []) {
            try{
                var getValue = [];
                var params = '';
                getValue = Object.values(myFormData);
                
                for(var key=0; key<getValue.length; key++)
                {
                    params += getValue[key] +'/';
                }
                const resGetData = axios.get( apiHttp + url + "/" + params);
                return resGetData;
                
            }catch(error){
                console.log(error);
            }
        },
        funcLogout: function()
        {
            try{
                localStorage.clear();
                return true;
            } catch(error){
                console.log(error);
            }
        },
        getAddressCust: function(lat = null, lon = null)
        {
            try{
                const getAddress = axios.get(
                                    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                                    lat +
                                    "," +
                                    lon +
                                    "&key=AIzaSyDMP1_zMlTEy15t1s85ja6EPtxcp5IOBRU");
                return getAddress;
            } catch(error){
                console.log(error);
            }
        },
        getDetailProduct: function(param = ""){
            try{
                const resDataSparepart = axios.get( apiHttp + "DetailProduct/" + param);
                return resDataSparepart;
            }catch(error)
            {
                console.log(error);
            }
        },
        getDetailNews: function(param = "", type = ""){
            try{
                const resDataNews = axios.get( apiHttp + "DetailNews/" + param + '/' + type);
                return resDataNews;
            }catch(error)
            {
                console.log(error);
            }
        },
        insertUnitCompare: function(param = ""){
            if(localStorage.myUnitCompare)
            {
                const objArray = JSON.parse(localStorage.myUnitCompare);
                const myObj = {
                    unit: param
                }
                objArray.push(myObj);
                if(objArray.length > 3)
                {
                    objArray.shift();
                }
                const unitCompare = JSON.stringify(objArray);
                localStorage.myUnitCompare = unitCompare;
                
            }
            else
            {
                const objArray = [];
                const myObj = {
                    unit: param
                }
                objArray.push(myObj);
                const unitCompare = JSON.stringify(objArray);
                localStorage.myUnitCompare = unitCompare;
                
            }
             
        },

        getApkVersion: function()
        {
            try{
                const resData = axios.get("https://admin.kartikasari.co.id/Kartikasari/TestPlayStore");
                return resData;
            }catch(error)
            {
                console.log(error);
            }
        },
        
    }
}