<template>
    <div v-if="this.isMobile() == true" class="row w-100 mt-0 mx-0 p-0">
        <div class="col-4 w-100 p-0 mx-0 mt-1  text-nowrap text-center" 
            v-for="index in 3" :key="index" >
            <a v-if="resultDist[index-1]" href="/CATALOGUE">
                <img  class="img-responsive w-100" 
                    :src="this.urlSite + resultDist[index-1].linkImage">
            </a>
            <a v-else href="/CATALOGUE" ><i class="fa-solid fa-circle-plus"></i></a>
            <!--<button v-else class="btn btn-sm btn-ksm">+</button>  -->
        </div>
        <div class="col-12 w-100 m-0 p-0">
            <div class="row ml-0 mr-0 d-flex flex-row flex-nowrap overflow-auto mt-2" v-for="(car, index) in resultDist" :key="index">
                <div v-for="(btn, index) in resCars.filter(x => x.Nama == car.name)" 
                    :key="index"  class="col-7 w-100 mx-1 my-0 p-0">
                    <button :class="car.type == btn.Type ? 'btn-ksm' : 'btn-secondary btn-rounded'"
                        @click="filterCar(btn.IdDetailCatalogue, car.id, btn.Type, $event, btn.Type)"
                        class="btn btn-sm btn-block"><small>{{btn.Type}}</small></button>
                </div>
            </div>
        </div>
        <div class="col-12 text-nowrap overflow-auto"> 
            <table class="table">
                <thead>
                    <tr>
                        <th>Type Unit</th>
                        <th>Price List</th>
                        <th>Transmission</th>
                        <th>Engine</th>
                        <th>Displacement</th>
                        <th>Rpm</th>
                        <th>Torque</th>
                        <th>FuelSystem</th>
                        <th>FuelCapacity</th>
                        <th>Fuel</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Wheelbase</th>
                        <th>TreadFront</th>
                        <th>TreadRear</th>
                        <th>FrontSuspension</th>
                        <th>RearSuspension</th>
                        <th>SteeringGear</th>
                        <th>TireSize</th>
                        <th>BoreStroke</th>
                        <th>MaxOutput</th>
                        <th>FrontBrake</th>
                        <th>RearBrake</th>
                    </tr>
                </thead>
                <tbody v-for="(car, index) in resultDist" :key="index">
                    <tr  v-for="(btn, index) in resCars.filter(x => x.Type == car.type)" :key="index">
                        <td>{{btn.Type}}</td>
                        <td>{{parseInt(btn.PriceList).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</td>
                        <td>{{btn.Transmission}}</td>
                        <td>{{btn.Engine}}</td>
                        <td>{{btn.Displacement}}</td>
                        <td>{{btn.Rpm}}</td>
                        <td>{{btn.Torque}}</td>
                        <td>{{btn.FuelSystem}}</td>
                        <td>{{btn.FuelCapacity}}</td>
                        <td>{{btn.Fuel}}</td>
                        <td>{{btn.Length}}</td>
                        <td>{{btn.Width}}</td>
                        <td>{{btn.Height}}</td>
                        <td>{{btn.Wheelbase}}</td>
                        <td>{{btn.TreadFront}}</td>
                        <td>{{btn.TreadRear}}</td>
                        <td>{{btn.FrontSuspension}}</td>
                        <td>{{btn.RearSuspension}}</td>
                        <td>{{btn.SteeringGear}}</td>
                        <td>{{btn.TireSize}}</td>
                        <td>{{btn.BoreStroke}}</td>
                        <td>{{btn.MaxOutput}}</td>
                        <td>{{btn.FrontBrake}}</td>
                        <td>{{btn.RearBrake}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 2-100 m-0 p-0">
            <button class="btn btn-block btn-ksm btn-sm" @click="AddToChartMobile">
                <i class="fa-solid fa-cart-plus"></i>
                Add To Chart
            </button>            
            <button class="btn btn-block btn-secondary btn-sm btn-rounded btn-whatsapp">
                <i class="fa-brands fa-whatsapp"></i>
                Whatsapp
            </button>
        </div>
    </div>
    <div v-else class="row w-100">
        
        <div v-for="(car, index) in resultDist" :key="index" class="col-4 w-100">
            <div class="card w-100">
                <img class="card-img-top" 
                    :src="this.urlSite + car.linkImage">
                <div class="card-body">
                    <div class="row d-flex flex-nowrap overflow-auto my-1" 
                        style="">
                        <div v-for="(btn, index) in resCars.filter(x => x.Nama == car.name)" 
                            :key="index"  class="col-7 w-100 mx-1 my-0 p-0">
                            <button :class="car.type == btn.Type ? 'btn-ksm' : 'btn-secondary btn-rounded'"
                                @click="filterCar(btn.IdDetailCatalogue, car.id, btn.Type, $event, btn.Type)"
                                class="btn btn-sm btn-block">{{btn.Type}}</button>
                        </div>
                    </div>
                    <div class="row">
                        <ul class="list-unstyled w-100 mx-1 my-0 p-0">
                            <span class="" v-for="(btn, index) in resCars.filter(x => x.Nama == car.name)" :key="index">
                                <span v-if="car.type == btn.Type">
                                    <li >Price List <span class="float-right">{{parseInt(btn.PriceList).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</span></li>
                                    <li >Type Unit <span class="float-right">{{btn.Type}}</span></li>
                                    <li >Transmission <span class="float-right">{{btn.Transmission}}</span></li>
                                    <li >Engine <span class="float-right">{{btn.Engine}}</span></li>
                                    <li >Displacement <span class="float-right">{{btn.Displacement}}</span></li>
                                    <li >Rpm <span class="float-right">{{btn.Rpm}}</span></li>
                                    <li >Torque <span class="float-right">{{btn.Torque}}</span></li>
                                    <li >FuelSystem <span class="float-right">{{btn.FuelSystem}}</span></li>
                                    <li >FuelCapacity <span class="float-right">{{btn.FuelCapacity}}</span></li>
                                    <li >Fuel <span class="float-right">{{btn.Fuel}}</span></li>
                                    <li >Length <span class="float-right">{{btn.Length}}</span></li>
                                    <li >Width <span class="float-right">{{btn.Width}}</span></li>
                                    <li >Height <span class="float-right">{{btn.Height}}</span></li>
                                    <li >Wheelbase <span class="float-right">{{btn.Wheelbase}}</span></li>
                                    <li >TreadFront <span class="float-right">{{btn.TreadFront}}</span></li>
                                    <li >TreadRear <span class="float-right">{{btn.TreadRear}}</span></li>
                                    <li >FrontSuspension <span class="float-right">{{btn.FrontSuspension}}</span></li>
                                    <li >RearSuspension <span class="float-right">{{btn.RearSuspension}}</span></li>
                                    <li >SteeringGear <span class="float-right">{{btn.SteeringGear}}</span></li>
                                    <li >TireSize <span class="float-right">{{btn.TireSize}}</span></li>
                                    <li >BoreStroke <span class="float-right">{{btn.BoreStroke}}</span></li>
                                    <li >MaxOutput <span class="float-right">{{btn.MaxOutput}}</span></li>
                                    <li >FrontBrake<span class="float-right">{{btn.FrontBrake}}</span></li>
                                    <li >RearBrake<span class="float-right">{{btn.RearBrake}}</span></li>
                                </span>
                            </span>
                        </ul>
                        <br>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-6">
                            <button class="btn btn-block btn-ksm btn-sm" 
                                @click="addToChart($event, car.type, car.idDetail)">
                                <i class="fa-solid fa-cart-plus"></i>
                                Add To Chart
                            </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-block btn-secondary btn-sm btn-rounded btn-whatsapp">
                                <i class="fa-brands fa-whatsapp"></i>
                                Whatsapp
                            </button>
                        </div>    
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade right" id="modalChooseCar" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-backdrop="true">
      <div class="modal-dialog modal-dialog-centered modal-side modal-bottom-right modal-notify modal-danger" role="document">
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header bg-danger">
            <p class="heading text-white">Choose Your Car</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <!--Body-->
          <div class="modal-body">
            <div class="row">
              <div class="col-12" v-for="(car, index) in resultDist" :key="index"> 
                <button class="btn btn-sm btn-info my-1 btn-rounded btn-block" 
                    @click="addToChart($event, car.type, car.idDetail)">{{car.type}}</button>
              </div>
            </div>
          </div>
          <!--Footer-->
          <div class="modal-footer flex-center">
            <a type="button" class="btn btn-outline-danger waves-effect" data-dismiss="modal">Cancel</a>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>
</template>

<script>
import { nytMixin } from "../assets/js/myMixin.js";
import $ from 'jquery'
export default {
    mixins: [nytMixin],
    data(){
        return{
            cars: [],
            resCars: [],
            curActive: '',
            dataClickCar: [],
            carMobile: [],
            resultDist: [],
        }
    },
    setup() {
        
    },
    beforeMount() {
        
        this.getCarDetail();
    },
    updated(){
        if(localStorage.myUnitCompare)
        {
            this.cars = JSON.parse(localStorage.myUnitCompare);
        }
    },
    methods: {
        
        async getCarDetail(){
          var myCompare = {};
          myCompare["units"] = localStorage.myUnitCompare;
          var retData = await this.FuncPost(this.urlSite + '/HttpApi/UnitCompare', myCompare);
          this.resCars = retData.data;
          //console.log(retData.data);

            const map = new Map();
            for (const item of this.resCars) {
                if(!map.has(item.IdCatalogue)){
                    map.set(item.IdCatalogue, true);    // set any value to Map
                    this.resultDist.push({
                        id: item.IdCatalogue,
                        idDetail: item.IdDetailCatalogue,
                        name: item.Nama,
                        type: item.Type,
                        linkImage: item.LinkImage,
                    });
                }
            }
            
        },
        async filterCar(idDetail, idCatalogue, carName, event, type)
        {
            //console.log("filter is change");
            Object.keys(this.resultDist).forEach(key => {
                if(this.resultDist[key].id == idCatalogue)
                {
                    this.resultDist[key].id = idCatalogue;
                    this.resultDist[key].idDetail = idDetail;
                    this.resultDist[key].type = type;
                }
            });
        },
        async addToChart(event, type, idDetail)
        {
            if(localStorage.Kode)
            {
                var form_data = {};   
                form_data['idDetailCarCatalogue'] = idDetail;
                form_data['carName'] = type;
                form_data['carColor'] = '';
                form_data['code'] = localStorage.Kode;
                form_data['carPrice'] = '';
                
                var getPrice = this.resCars.filter(x => x.Type == type)
                if(getPrice)
                {
                    form_data['carPrice'] = getPrice[0].PriceList;
                }
                const resData = await this.FuncPost(this.urlSite + '/HttpApi/AddToChartCar', form_data);
                console.log(resData);
            }
            else
            {
                window.location.href="/LoginUser";
            }
            $("#modalChooseCar").modal("hide");
        },
        AddToChartMobile: function()
        {
            if(localStorage.Kode)
            {
                $("#modalChooseCar").modal("show");
            }
            else
            {
                window.location.href="/LoginUser";
            }
        }
         
    }
}
</script>