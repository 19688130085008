<template>
    <div v-if="showFormService" class="w-100 m-0 p-0 text-left" >
        <form class="w-100 m-3 p-0"  action="#"> 
            <div class="form-group">
                <label>NoPol</label>
                <input v-model="detailUnit.NoPol" class="form-control" type="text" name="Nopol" placeholder="NoPol" :readonly="isZero" />
            </div>
            <div v-if="isZero == false" class="form-group">
                <label for="jenisKendaraan">Jenis Kendaraan </label>
                <select v-model="jenisKendaraan" id="jenisKendaraan" name="jenisKendaraan" class="form-control" >
                    <option v-for="(unit, index) in units" :key="index" :value="unit.Kode" >{{unit.Keterangan }}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Phone</label>
                <input v-model="phoneCust" class="form-control" type="text" name="Phone" placeholder="Phone" :readonly="isZero" />
            </div>
            <div class="form-group">
                <label>Address</label>
                <input v-if="newHomeLocation == '' || newHomeLocation == undefined" v-model="alamatCust" class="form-control" type="text" name="Address" placeholder="Address" :readonly="isZero" />
                <input v-else v-model="newHomeLocation" class="form-control" type="text" name="Address" placeholder="Address" :readonly="isZero" />
            </div>
            <div v-if="isZero == true" class="form-group">
                <label>NoRangka</label>
                <input v-model="detailUnit.NoRangka" class="form-control" type="text" name="NoRangka" placeholder="NoRangka" :readonly="isZero" />
            </div>
            <div class="form-group">
                <label>Tahun Pembuatan </label>
                <input v-model="detailUnit.ThnProduksi" class="form-control" 
                    type="number" name="Thn"  :placeholder="currentYear" 
                    :readonly="isZero" @focusout="changeYear"/>
                
            </div>
            <div v-if="newHomeLocation" class="form-group">
                <label for="TypeSBE">Select Type SBE</label>
                <select class="form-control" name="TypeSBE" id="TypeSBE">
                    <option value="1000">1.000</option>
                    <option value="10000">10.000</option>
                    <option value="20000">20.000</option>
                    <option value="30000">30.000</option>
                    <option value="40000">40.000</option>
                    <option value="50000">50.000</option>
                    <option value="60000">60.000</option>
                    <option value="70000">70.000</option>
                    <option value="80000">80.000</option>
                    <option value="90000">90.000</option>
                    <option value="100000">100.000</option>
                    <option value="110000">110.000</option>
                    <option value="120000">120.000</option>
                    <option value="130000">130.000</option>
                    <option value="140000">140.000</option>
                    <option value="Keluhan Lainya">Keluhan Lainya</option>
                </select>
            </div>
            <div class="form-group">
                <div class="row"> 
                    <div class="col-6"> 
                        <label>Tanggal</label>
                        <input v-model="curDate" class="form-control" type="date" 
                            name="Tanggal" placeholder="Tanggal" :min="minDate" />
                    </div>
                    <div class="col-6"> 
                        <label>Jam</label>
                        <input v-model="curTime" class="form-control" type="time" 
                            name="Jam" placeholder="Jam" min="08:00" max="16:30" />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Tipe Service</label>
                <select class="form-control" name="typeService" v-model="typeService" > 
                    <option value="00002">General Repair</option>
                    <option value="00006">Service Berkala</option>
                </select>
            </div>
            <div class="form-group">
                <label>Konfirmasi Penggantian Sparepart</label>
                <div class="row m-2"> 
                    <div class="col-6 form-check">
                        <input class="form-check-input" name="ConfirmReplacement" v-model="ConfirmReplacement" 
                            type="radio" value="konfirmasi" id="flexCheckChecked" checked>
                        <label class="form-check-label" for="flexCheckChecked">
                            Konfirmasi
                        </label>
                    </div>
                    <div class="col-6 form-check">
                        <input class="form-check-input" name="ConfirmReplacement" v-model="ConfirmReplacement" 
                            type="radio" value="langsung" id="flexCheckChecked2" >
                        <label class="form-check-label" for="flexCheckChecked2">
                            Langsung
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group"> 
                <label>Keluhan</label>
                <input class="form-control" v-model="Keluhan"  name="keluhan" placeholder="Keluhan" style="height: 75px;" />
            </div>
            <div class="form-group"> 
                <label>Voucher</label>
                <select v-if="vouchers.length > 0" class="form-control" 
                    name="voucher" v-model="dataVoucher" @change="selectVoucher($event)"> 
                    <option v-for="v in vouchers" :key="v.Kode" :value="v.Kode" >{{v.Keterangan}}</option>
                </select>
                <input v-else class="form-control" type="text" name="voucher" placeholder="Voucher Kosong" readonly>
            </div>
            <a class="btn btn-sm btn-ksm btn-block" @click="InsertService">Submit</a>
        </form>
        
        <myModWarn @name="ReturnModal" ref="myModalWarning" />
    </div>
</template>

<script>
import { nytMixin } from "../assets/js/myMixin.js";
import myModWarn from "./ModalWarning.vue";
//import { IonDatetime } from '@ionic/vue';
export default {
    props: ["homeLocation", "metodeBooking"],
    components: { myModWarn },
    mixins: [nytMixin],
    data() {
        return{
            newHomeLocation: this.homeLocation,
            showFormService: false,
            detailUnit: [],
            phoneCust: localStorage.Telp,
            alamatCust: localStorage.Alamat,
            curDate: new Date().toISOString().substr(0, 10),
            minDate: new Date(),
            curTime: new Date().toLocaleTimeString("idn", { hour: "2-digit", minute: "2-digit", hour12: false,}),
            unitCust: '',
            vouchers: [],
            retModal: false,
            typeService: '00002',
            ConfirmReplacement : 'konfirmasi',
            Keluhan: '',
            dataVoucher: '',
            isZero: true,
            units: [],
            jenisKendaraan: '',
            currentYear: new Date().getFullYear(),
        }
    },
    beforeMount() {
        var getTomorrow = new Date();
        getTomorrow.setDate(getTomorrow.getDate() + 1);
        this.minDate = getTomorrow.toISOString().substr(0, 10);
        this.curDate = this.minDate;

        if(localStorage.Telp) { this.phoneCust = ''; }
        if(localStorage.Alamat) { this.alamatCust = ''; }
        
        this.getUnit();
    },
    methods: {
        selectVoucher(event)
        { 
            const thisEvent = event.target.value;
            this.dataVoucher = thisEvent;
        },
        changeYear()
        {
            if(this.detailUnit.ThnProduksi < 1900 && this.detailUnit.ThnProduksi > this.currentYear)
            {
                this.detailUnit.ThnProduksi = 0;
                alert("Tahun Tidak Sesuai ");
            }
        },
        async getUnit()
        {
            var form_data = {};
            const response = await this.FuncGet('GetAllUnit', form_data);
            this.units = response.data;
            if(this.units.length > 0)
            {
                this.jenisKendaraan = this.units[0].Kode;
            }
        },
      async setShowFormService(unit, param){        
          var form_data = {};   
          form_data['NoRangka'] = param;
          var resUnit = await this.FuncGet('DetailUnitByNoKa', form_data);
          this.detailUnit = resUnit.data;
          this.unitCust = unit;

          this.getVoucherWorkshop();

          this.showFormService = true;
      },
      setShowFormServiceZero()
      {
        this.showFormService = true;
        this.isZero = false;
      },
      setHideFormService(){
          this.showFormService = false;
      },
      async getVoucherWorkshop(){
            var form_data = {};
            form_data['token'] = this.token;
            form_data['kode'] = 'DATAVOUCHERWORKSHOP'; 
            form_data['params[0]'] = this.unitCust; 
            const resultData = await this.FuncPostSecond(this.urlApiLoad, form_data);
            this.vouchers = resultData.data.result;
            if(this.vouchers.length > 0)
            {
                this.dataVoucher = this.vouchers[0].Kode;
            }
      },
      InsertService()
      {
        if(this.curTime >= '08:00' && this.curTime <= '16:30')
        {
            this.$refs.myModalWarning.showModal();
        }
        else
        {
            alert("Jam Kerja 08:30 - 16:30");
        }
        
      },
      async ReturnModal(retData)
      {
        //console.log("form service");
        //console.log(retData);
        if(retData == true)
        {
            var form_data = {};   
			form_data['Nopol'] = this.detailUnit.NoPol;
			form_data['Phone'] = this.phoneCust;
			form_data['Tahun'] = this.detailUnit.ThnProduksi;
			form_data['TglBooking'] = this.curDate;
			form_data['JamBooking'] = this.curTime;
			form_data['TypeService'] = this.typeService;
			form_data['PenggantianSparepart'] = this.ConfirmReplacement;
			form_data['Keluhan'] = this.Keluhan;
			form_data['Voucher'] = this.dataVoucher;
            form_data['Kode'] = localStorage.Kode;
            if(this.isZero == false)
            {
                form_data['Unit'] = this.jenisKendaraan;
                form_data['NoRangka'] = '';
            }
            else
            {
                form_data['Unit'] = this.detailUnit.Unit;
                form_data['NoRangka'] = this.detailUnit.NoRangka;
            }
            form_data['UniqueCode'] = this.uniqueCode;
            form_data['NamaCust'] = localStorage.Nama;
            form_data['MetodeBooking'] = this.metodeBooking;

            if(this.newHomeLocation == '' || this.newHomeLocation == undefined)
            {
                form_data['Address'] = this.alamatCust;

                //console.log("form data", form_data);

            }

            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/InsertBookingService", form_data);
            
            if(resultData.data.search("Success") >= 0)
            {
                if(confirm("Sukses Booking Service"))
                {
                    window.location.href="/"
                }
                else
                {
                    alert(resultData.data);
                }
            }
            else
            {
                alert(resultData.data);
            }
        }
      }
    
    },
    created(){
        //console.log("metode booking", this.metodeBooking);
    }
};
</script>