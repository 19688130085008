<template>
    <div class="row" >
        <div class="col-12" :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 70px;' : ''"> 
            <section id="team" class="team">
                    <div class="container">
                <div class="section-title">
                    <h2>Our Team</h2>
                    <h3><b>Sales Division</b></h3>
                </div>
                <div class="row">
                <div v-for="sale in teams.filter(x => x.TitleJabatan == 'Counter Sales' || x.TitleJabatan == 'Customer Service')" :key="sale.NamaLengkap" 
                    class="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up">
                    <div class="member">
                    <div class="pic">
                        <img :src="sale.GambarPath == '' ? 'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/' 
                        + sale.NamaLengkap + '.jpg&size=500' : 'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/' 
                        + sale.GambarPath + '&size=500'" class="img-fluid" alt="" style="max-height: 250px;">
                    </div>
                    <div class="member-info">
                        <h4>{{sale.NamaLengkap}}</h4>
                        <span>{{sale.TitleJabatan}}</span>
                        <div class="social">
                            <a :href="'https://wa.me/' + sale.Telp" target="_blank" class="m-1">
                            <i class="fa-brands fa-whatsapp"></i>
                            </a>
                            <a href="" class="m-1"><i class="fa-brands fa-facebook"></i></a>
                            <a href="" class="m-1"><i class="fa-brands fa-instagram"></i></a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="section-title">
                <h3><b>Service Division</b></h3>
                </div>
                <div class="row">
                <div v-for="sale in teams.filter(x => x.TitleJabatan == 'Service')" :key="sale.NamaLengkap" 
                    class="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up">
                    <div class="member">
                    <div class="pic" style="min-height: 260px;">
                        <img :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/' 
                        + sale.NamaLengkap + '.jpg&size=500'" class="img-fluid" alt="" style="max-height: 250px;">
                    </div>
                    <div class="member-info">
                        <h4>{{sale.NamaLengkap}}</h4>
                        <span>{{sale.TitleJabatan}}</span>
                        <div class="social">
                            <a :href="'https://wa.me/' + sale.Telp" target="_blank" class="m-1">
                                <i class="fa-brands fa-whatsapp"></i>
                            </a>
                            <a href="" class="m-1"><i class="fa-brands fa-facebook"></i></a>
                            <a href="" class="m-1"><i class="fa-brands fa-instagram"></i></a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    setup() {
        
    },
    data() {
        return {
            teams: [],
            curScreen: screen.width,
        }
    },
    beforeMount() {
        this.GetTeams();
    },
    methods: {
        async GetTeams(){
            const resCatalogue = await this.HomeCarCatalogue();
            this.teams = resCatalogue.data.sales;
        },
    }
}
</script>