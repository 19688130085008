<template>
    <div v-if="this.isMobile()" class="row " style="margin-top: 15px;"></div>
    <div v-else class="col-12 w-100 mt-5" 
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 125px !important;' : ''" > 
        <nav aria-label="breadcrumb" >
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a class="removeBlueLink" href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Kredit Simulasi</li>
            </ol>
        </nav>
    </div>
    
    <div class="row w-100 m-0 p-0">
        
        <div v-if="!this.isMobile()" class="col-6">
            <img :src="imgNotFound"
                class="img-responsive w-100" 
                alt="Responsive imageCar" id="imageCar"> 
        </div>
        <div :class="this.isMobile() ? 'col-12 w-100 text-left' : 'col-6 w-100'">
            <form id="apply-form" class="text-left">
                <div class="form-group">
                    <div class="row">
                        <div class="col-6">
                            <label for="namaPelanggan">Nama </label>
                            <input class="form-control" id="namaPelanggan" type="text" placeholder="John" v-model="namaPelanggan">
                        </div>
                        <div class="col-6">
                            <label for="phone">Phone</label>
                            <input class="form-control" id="phone" type="text" placeholder="+62" v-model="phone">
                        </div> 
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-6">
                            <label for="Jenis">Jenis Kendaraan</label>
                            <select id="Jenis" class="form-control" v-model="unit" @change="GetTypeCar" >
                                <option v-for="(jenis, index) in jenisUnit" :key="index" :value="jenis.JenisKendaraan">
                                    {{jenis.JenisKendaraan}}
                                </option>
                            </select>
                        </div>
                        <div class="col-6">
                            <label for="Tipe">Tipe Kendaraan</label>
                            <select id="Tipe" class="form-control" v-model="typeCar" @change="GetPrice">
                                <option v-for="(tipe, index) in tipeUnit" :key="index" :value="tipe.Keterangan" >
                                    {{tipe.Keterangan}}
                                </option>   
                                
                            </select>
                        </div>
                    </div>
                </div>    
                <div class="form-group">
                    <div class="row">
                        <div class="col-6">
                            <label for="price">Harga</label>
                            <input class="form-control text-right" type="text" readonly v-model="resHarga"> 
                        </div>
                        <div class="col-6">
                            <label for="Tenor">Tenor</label>
                            <select id="Tenor" v-model="tenor" class="form-control">
                                <option value="12">12 Bulan</option>
                                <option value="24">24 Bulan</option>
                                <option value="36">36 Bulan</option>
                                <option value="48">48 Bulan</option>
                                <option value="60">60 Bulan</option>
                            </select>
                        </div>
                    </div>
                </div>   
                <div class="form-group">
                    
                    <div class="row ">
                        <div class="col-6 w-100 ">
                            <label for="Dp">Dp</label>
                            <select id="Dp" class="form-control" v-model="percentDp" @change="changeDp" >
                                <option value="10">10%</option>
                                <option value="20">20%</option>
                                <option value="30">30%</option>
                                <option value="custom">Custom</option>
                            </select>
                        </div>
                        <div class="col-6 w-100 ">
                            <label for="SisaBayar">{{SisaOrDp}}</label>
                            <input class="form-control text-right" id="SisaBayar" placeholder="Nominal" type="text" :readonly="dp != '0'" v-model="valDp">
                        </div>
                    </div>
                </div>
                <label><em class="text-danger">* Mohon Menunggu Inforamasi Selanjutnya Setelah Submit</em></label>
                <a href="javascript:void(0)" class="btn btn-danger btn-block" @click="kalkulasi" >Submit Kredit Simulasi</a>
            </form>
        </div>
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
import $ from 'jquery';
export default {
    mixins: [nytMixin],
    data(){
        return{
            SisaOrDp: 'Sisa Bayar',
            jenisUnit: [],
            tipeUnit: [],
            resHarga: 0,
            dp: '',
            valDp: '',
            imgNotFound: this.urlSite + '/assets/MasterWebKSM/img/notFoundImage.png',
            unit: '-Kendaraan-',
            typeCar: '-Jenis Kendaraan-',
            percentDp: '',
            tenor: '',
            namaPelanggan: '',
            phone: '',
            resKode: '',
            curScreen: screen.width,
        }
    },
    setup() {
        
    },
    beforeMount(){
        this.GetJenisUnit();
    },
    methods:{
        async GetJenisUnit(){
            var form_data = {};
            var resultData = await this.FuncGet("/GetAllJenisKendaraan", form_data);
            this.jenisUnit = resultData.data;
        },
        async GetTypeCar(event){
            let indonesianRupiah = Intl.NumberFormat('id-ID');
            var form_data = {};
            form_data["Jenis"] = event.target.value;
            var resultData = await this.FuncGet("/GetAllUnit", form_data);
            this.tipeUnit = resultData.data;
            this.resHarga = indonesianRupiah.format(parseInt(this.tipeUnit[0].PriceList));
            //console.log(this.tipeUnit);
            if(resultData.data.length > 0)
            {
                this.imgNotFound = this.urlSite + "/picture.php?source=api-integration/images/promoAltius/"+resultData.data[0].Gambar1+"&size=850";
            }
            //console.log(this.imgNotFound);
        },
        GetPrice:function(event)
        {
            let indonesianRupiah = Intl.NumberFormat('id-ID');
            var getData = this.tipeUnit.filter(x => x.Keterangan == event.target.value);
            this.resHarga = indonesianRupiah.format(parseInt(getData[0].PriceList));
            this.resKode = getData[0].Kode;
            
        },
        changeDp: function(event)
        {
            this.dp = event.target.value;
            if(this.dp == 'custom') 
            { 
                this.dp = '0';
                this.SisaOrDp = 'Nominal DP'; 
                this.valDp = '0';
            }
            else
            {
                var dpInt = (parseInt(this.dp) * parseInt(this.resHarga.replace(/\./gi,""))) / 100 ;
                var resSisa = parseInt(this.resHarga.replace(/\./gi,"")) - dpInt;
                if(isNaN(resSisa))
                {
                    resSisa = 0;
                }
                let indonesianRupiah = Intl.NumberFormat('id-ID');
                this.valDp = indonesianRupiah.format(resSisa);
            }
        },
        async kalkulasi()
        {
            var isAnyEmpty = false;
            $('#apply-form *').filter(':input').each(function(key, value){
                //console.log("oke", key, this.value);
                if(this.value == "" )
                {
                    isAnyEmpty = true;
                }
            });
            if(isAnyEmpty == false)
            {
                    var form_data = {}; 
                var kodePelanggan = '';  
                var namaPelanggan = '';
                if(localStorage.Kode && localStorage.Email) {
                    kodePelanggan = localStorage.Kode;
                    namaPelanggan = localStorage.Email;
                }
                form_data['KodePelanggan'] = kodePelanggan;
                form_data['nama'] = this.namaPelanggan;
                form_data['phone'] = this.phone;
                form_data['modelMobil'] = this.unit;
                form_data['tipeMobil'] = this.typeCar;
                form_data['otr'] = this.resHarga;
                form_data['tenor'] = this.tenor;
                form_data['dp'] = this.percentDp;
                form_data['resultDp'] = this.valDp.replace(/\./gi,"");
                form_data['Kode'] = this.resKode;
                var retData = await this.FuncPost(this.urlSite + '/HttpApi/AddKreditSimulation', form_data);
                if(retData.search("Success") >= 0 )
                {
                    window.location.href="/";
                }
                else
                {
                    alert("Gagal Submit Kredit Simulasi");
                }
            }
            else
            {
                alert("Lengkapi Form Anda");
            }
        }
    }
}
</script>