<template>
    <div style="min-height: 10px;"></div>
    <div  class="row w-100 mb-0 my-2 p-0 mt-5">
        <div class="col-12">
            <table id="tableCareer" class="table w-100">
                <thead>
                <tr>
                    <th>Image</th>
                </tr>
                </thead>
                <tbody  class="row ml-0 mr-0 pl-0 pr-0">
                    <tr v-for="(career, index) in careers" :key="index" 
                        class="odd col-sm-6 col-md-4 col-lg-3 col-xl-3 ml-0 mr-0 pl-0 pr-0">
                        <td>
                            <div class="card shadow ml-0 mr-0 h-100 w-100" @click="showImage(career.Image)">
                                <img class="card-img-top" style="max-heigh: 250px;" 
                                    :src="this.urlSite+'/assets/img/career/'+career.Image" :alt="career.Nama">
                                
                            </div>
                        </td>
                    </tr>
                  </tbody>
            </table>
        </div>
    </div>
    <div v-if="careers.length <= 0" class="row w-100 m-0 p-0">
        <div class="col-12 " style="height: 300px;">
            <p class="text-center"><em>
                Data Kosong    
            </em></p>
        </div>
    </div>

<!-- Modal image show -->
<div class="modal fade" id="modalShowImg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
        <img :src="this.urlSite+'/assets/img/career/' + showMyImage" class="w-100 roundedAll m-0 p-0"/> 
      
  </div>
</div>

</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
import $ from 'jquery'

export default {
    mixins: [nytMixin],
    data(){
        return{
            careers: [],
            showMyImage: '',
        }
    },
    setup() {
    },
    beforeMount(){
        this.getCareers();

    },
    methods:{
        showImage(image)
        {
            this.showMyImage = image;
            $("#modalShowImg").modal("show");
        },
        async getCareers()
        {
            var form_data = {};
            var resultData = await this.FuncGet("/GetAllCareer", form_data);
            this.careers = resultData.data;
        }
    },
    updated()
    {
        $("#tableCareer thead").hide();
        setTimeout(function() { $("#tableCareer").DataTable({
            "bPaginate": false,
            "bInfo": false,
            "lengthChange": false,
            "autoWidth": false,
            "retrieve": true,
        }); }, 2000);
    }
}
</script>