<template>
    
    <div class="row w-100 m-0 p-0 " >
       
        <div class="col-12 w-100 p-0 m-0 " >
            <GoogleMap api-key="AIzaSyDMP1_zMlTEy15t1s85ja6EPtxcp5IOBRU" 
                style="width: 100%; height: 100vh; " :center="myLocation" :zoom="17" >
                <CustomMarker :options="{ position: center, anchorPoint: 'CENTER' }">
                    <div style="text-align: center">
                        <img src="@/assets/images/address.png" width="20" 
                           style="margin-top: 8px" />
                    </div>
                </CustomMarker>
                <CustomMarker :options="{ position: myLocation, anchorPoint: 'CENTER' }">
                    <div style="text-align: center">
                        <img src="@/assets/images/racesuit.png" width="20" 
                           style="margin-top: 8px" />
                    </div>
                </CustomMarker>
                <Polyline :options="flightPath" />
            </GoogleMap>
        </div>
        
    </div>

</template>


<script>
import { defineComponent } from "vue";
import { GoogleMap, Marker, Polyline, CustomMarker } from "vue3-google-map";
import { nytMixin } from "../assets/js/myMixin.js";
import { Plugins, Capacitor,
        Geolocation } from '@capacitor/core';

export default defineComponent({
  mixins: [nytMixin],
  components: { GoogleMap, Marker, Geolocation, Polyline, CustomMarker },
  data(){
      return {
        myLocation: { lat: -7.636036, lng: 112.752462 },
      }
  },
  setup() {
    const center = { lat: -7.936088, lng: 112.650936 };
    const flightPlanCoordinates = [
        { lat: -7.936258, lng: 112.651103 },
        { lat: -7.936302, lng: 112.651243 },
        { lat: -7.936508, lng: 112.651640 },
        { lat: -7.937795, lng: 112.654449 },

        { lat: -7.937803, lng: 112.654513 },//lingkaran awal
        { lat: -7.937770, lng: 112.654553 },
        { lat: -7.937749, lng: 112.654598 },
        { lat: -7.937751, lng: 112.654604 }, 
        { lat: -7.937739, lng: 112.654665 },
        { lat: -7.937751, lng: 112.654716 },
        { lat: -7.937773, lng: 112.654763 },
        { lat: -7.937807, lng: 112.654798 },
        { lat: -7.937858, lng: 112.654822 },
        { lat: -7.937912, lng: 112.654834 },
        { lat: -7.937956, lng: 112.654834 },//lingkaran awal akhir

        { lat: -7.937958, lng: 112.654847 },
        { lat: -7.940193, lng: 112.660083 },
        { lat: -7.940556, lng: 112.663949 },
        { lat: -7.940681, lng: 112.663954 },
        { lat: -7.940345, lng: 112.660050 },
        { lat: -7.939114, lng: 112.657258 },
        { lat: -7.938042, lng: 112.654804 },

        { lat: -7.938072, lng: 112.654753 }, //lingkaran kedua
        { lat: -7.938097, lng: 112.654688 },
        { lat: -7.938100, lng: 112.654617 },
        { lat: -7.938058, lng: 112.654539 },
        { lat: -7.938004, lng: 112.654494 },
        { lat: -7.937945, lng: 112.654476 },
        { lat: -7.937892, lng: 112.654472 },
        { lat: -7.937891, lng: 112.654451 }, //lingkaran kedua end

        { lat: -7.936365, lng: 112.651044 },

    ];
    const flightPath = {
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    };
    return { center, flightPath };
  },
  methods: {
      async getMyLocation()
      {
        const position = await Geolocation.getCurrentPosition();
        this.myLocation = { lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude) };

        console.log("mylocation", this.myLocation);
      }

        
  },
  updated(){
  },
  mounted(){
  },
  beforeMount(){
    //this.getMyLocation();
  },
  created()
  {
    setInterval(this.getMyLocation, 2000);
  }
});
</script>
