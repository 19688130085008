<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 mt-1">
                <img v-if="dataPromo.Link != undefined" :src="this.urlSite +dataPromo.Link" class="w-100"/>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-between" style="padding-top: 15px;">
                    <article class="white-panel w-100 text-left"> 
                        <h4>{{ dataPromo.Judul }}</h4>
                        <p v-html="dataPromo.IsiPromo"></p>
                    </article>
                </div>
            </div>  
        </div>
    </div>
    <div style="min-height: 300px;"></div>
</template>
<script>
import $ from 'jquery'
import { nytMixin } from "../assets/js/myMixin.js";
const axios = require('axios');

export default {
  mixins: [nytMixin],
  data(){
    return{
        dataParam: this.$route.params.param,
        dataPromo: [],
    }
  },
  methods:
  {
    async getPromo()
    {
        var form_data = {};
        form_data["NamaPromo"] = this.dataParam.replace("%20", " ");
        const retData = await this.FuncPost(this.urlSite + '/HttpApi/DetailPromo', form_data);
        this.dataPromo = retData.data;
    }
  },
  beforeMount()
  {
    this.getPromo();
  }
}
</script>