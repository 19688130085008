<template>
    <div>
        test
    </div>
</template>
<script>
import $ from 'jquery'
import { nytMixin } from "../assets/js/myMixin.js";

export default {
    mixins: [nytMixin],
    data(){
        return{
            
        }
    },
    beforeMount(){
        
    },
    methods:{
        async getData()
        {
            var form_data = {};   
            form_data['emailCust'] = this.emailForgotPassword;
            const getData = await this.FuncPostJwt(this.urlSite + "/HttpApi/changePassword", form_data);
        }
    },
    mounted(){
        
    }
}
</script>