<template>
     <div class="modal fade right" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-backdrop="true">
      <div class="modal-dialog modal-dialog-centered modal-side modal-bottom-right modal-notify modal-danger" role="document">
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header bg-light my-0 py-0">
            <p class="heading text-ksm my-0 py-0">Detail Order  {{kodeSo}}</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="text-danger">&times;</span>
            </button>
          </div>
          <!--Body-->
          <div class="modal-body">
            <div class="row">
              <div v-if="retOrderSparepart.length > 0" class="col-12 w-100 m-0 p-0" >
                    <div v-for="(spare, index) in retOrderSparepart" :key="index" class="row mx-1 my-3 shadow p-1 mb-3 bg-white rounded" :id="'list' + index" >
                        <div class="col-2 m-0">
                            <img class="mt-1" style="max-height: 65px; max-width: 65px; border-radius: 15px;" 
                                :src="this.urlSite + spare.Image">
                        </div>
                        <div class="col-6 pl-4"> 
                            <p class="m-0 text-left"> <small>{{spare.Nama}}</small> </p>
                            <p class="m-0 text-left" ><small>
                                Rp.{{parseInt(spare.HargaAwal).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}} 
                                x {{spare.Quantity}}
                                </small>
                            </p>
                        </div>   
                        <div class="col-4 m-0">
                                <p>Rp.{{parseInt(spare.HargaAwal * spare.Quantity).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}} </p>
                        </div>                             
                    </div> 
                    <div class="mx-1 my-0 px-1 py-0">
                        <p class="m-0 p-0 text-left"><small>Total Pembayaran <span class="float-right">Rp. {{parseInt(totalBayar).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</span></small></p>
                        <p class="m-0 p-0 text-left"><small>Tanggal Pesan<span class="float-right"> {{retOrderSparepart[0].DateProses}}</span></small></p>
                        <p class="m-0 p-0 text-left"><small>Phone<span class="float-right"> {{retOrderSparepart[0].PhonePenerima}}</span></small></p>
                        <p class="m-0 p-0 text-left"><small>Alamat<span class="float-right"> {{retOrderSparepart[0].AlamatPenerima}}</span></small></p>  
                    </div>
                </div>
                <div v-if="retOrderCar.length > 0" class="col-12 w-100">
                    <div v-for="(car, index) in retOrderCar" :key="index" class="row mx-1 my-3 shadow p-1 mb-3 bg-white rounded" :id="'list' + index" >
                        <div class="col-3 m-0">
                            <img class="mt-1" style="max-height: 65px; max-width: 65px;" 
                                :src="this.urlSite +'/picture.php?source=api-integration/images/promoAltius/'+car.gambar+'&size=500'">
                        </div>
                        <div class="col-9"> 
                            <p class="m-0 text-left"> <small>{{car.CarName}}</small> </p>
                            <p class="m-0 text-right" ><small>
                                Rp.{{parseInt(car.CarPrice).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}} 
                                </small>
                            </p>
                        </div>   
                                                    
                    </div> 
                    <div class="mx-1 my-0 px-1 py-0">
                        <p class="m-0 p-0 text-left"><small>Total Pembayaran <span class="float-right">Rp. {{parseInt(totalBayar).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</span></small></p>
                        <p class="m-0 p-0 text-left"><small>Tanggal Pesan<span class="float-right"> {{retOrderCar[0].DateProses}}</span></small></p>
                        <p class="m-0 p-0 text-left"><small>Phone<span class="float-right"> {{retOrderCar[0].PhonePenerima}}</span></small></p>
                        <p class="m-0 p-0 text-left"><small>Alamat<span class="float-right"> {{retOrderCar[0].AlamatPenerima}}</span></small></p>  
                    </div>
                </div>
            </div>
          </div>

        </div>
        <!--/.Content-->
      </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    props: ["kodeSO"],
    setup() {
        
    },
    data(){
        return{
            kodeSo: '',
            retOrderSparepart: [],
            retOrderCar: [],
            totalBayar : 0,
        }
    },
    beforeMount(){
        
    },
    mounted() {

    },
    methods:{
        showModal() {
            $("#exampleModal").modal("show");
        },
        hideModal() {
            $("#exampleModal").modal("hide");
        },   
        async setKodeSO(param)
        {
            this.retOrderSparepart = [];
            this.retOrderCar = [];
            var form_data = {};
            this.kodeSo = param;
            if(this.kodeSo.search("POINV") >= 0)
            {
                form_data["table"] = "MasterWishList";
                form_data['invoice'] = param;
                const resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetOrder", form_data);
                this.retOrderSparepart = resultData.data;
                var retTotalBayar = 0;
                this.retOrderSparepart.forEach(function(ret) {
                    retTotalBayar += parseInt(ret.Quantity) * parseInt(ret.HargaAwal);
                });
                this.totalBayar = retTotalBayar;
            }
            if(this.kodeSo.search("POCAR") >= 0)
            {
                form_data["table"] = "MasterWishListCar";
                form_data['invoice'] = param;
                const resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetOrder", form_data);
                this.retOrderCar = resultData.data;
                var retTotalBayar = 0;
                this.retOrderCar.forEach(function(ret) {
                    retTotalBayar += parseInt(ret.CarPrice);
                });
                this.totalBayar = retTotalBayar;
            }
        }
    }
}
</script>