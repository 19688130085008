<template>
  <div v-if="isMobile() == false" class=" ml-1 mr-1 mt-1"
    :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 110px !important;' : ''">
    <header >
      <div v-if="!carousels" id="carouselExampleIndicators" class="carousel slide w-100" data-ride="carousel">
        <ol class="carousel-indicators">
          <li v-for="(crs, index) in carousels" :key="crs.IdPromo" 
            :data-target="'#carouselExampleIndicators '" 
            :data-slide-to="crs.IdPromo" :class="index == 0 ? 'active':'' ">
          </li>
        </ol>
        <div class="carousel-inner roundedCarousel" role="listbox">
          <div v-for="(crs, index) in carousels" 
            :key="crs.IdPromo" :class="index == 0 ? 'carousel-item active' : 'carousel-item' ">
            <a :href="'DetailCar/' + crs.Nama.replace(' ', '-')">
              <img class="d-block w-100 h-100 " 
                :src="this.urlSite + crs.Link" alt="Image Data">
            </a>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
        <div class="row w-100 btnViewAllCarousel m-0 p-0">
          <div class="col-12 d-flex justify-content-center">
            <button class="btn btn-sm backBlue">View All</button>
          </div>
        </div>
      </div>
    </header>

    <!-- recomended -->
    <div class="card mt-5 roundedAll  bg-light w-100 p-0 m-0">
		  <div class="card-body text-left">
        <h5 class="card-title d-inline "><strong>Recomended For You</strong></h5> 
        <a href="/Recomended" 
          class="linkBorder d-inline backBlue pt-1 pb-1 pl-2 pr-2 float-right"> View All</a>
        <div class="container-fluid m-0 p-0 text-center ">
          <div class="row mx-auto my-auto w-100">
            <div id="recipeCarousel" class="carousel slide w-100 " data-ride="carousel">
              <div class="carousel-inner w-100" role="listbox" style="height: 300px;">
                <div v-for="(rec, index) in recomendeds" :key="index" 
                  :class="index == 0 ? 'carousel-item active': 'carousel-item'">
                  <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">	
                    <a :href="'/DetailCar/' + rec.Jenis" 
                      class="removeBlueLink w-100 ">
                      <div class="card card-block mx-2 coverRecom shadow p-1 mb-3 bg-white rounded h-100 w-100" 
                        style="max-height: 230px; min-height: 230px;">
                        <div class="row ">
                          
                          <img class="card-img-top col-12" 
                            :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+rec.Image+'&size=500'" 
                            alt="Card image cap" >
                          
                          <div class="col-12 mt-1 "> 
                            <div class="card-body text-center d-flex flex-column" >	
                              <h6 class="card-title pt-5 mt-1 "><strong>{{rec.Keterangan}}</strong></h6>
                              <p class="card-text mt-auto ">START FROM<br> 
                                <strong>{{rec.Price}}</strong> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev w-auto" href="#recipeCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon bg-dark border border-dark rounded-circle" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next w-auto" href="#recipeCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon bg-dark border border-dark rounded-circle" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div> 
    </div>

    <!-- penjualan sparpart -->
    <div class="card mt-3 roundedAll bg-light w-100 mx-0 pb-5">
      <div class="card-body w-100 m-0 p-0 text-left">	
        <h5 class="card-title d-inline m-3"><strong>Shop</strong></h5> 
        <div class="row dataJual w-100 m-0 p-0" id="SPAREPART">
          <div class="col-lg-12 col-xl-12 col-sm-12" style="min-height: 400px;">
            <div class="row card w-100 mx-0">
              <table id="tblspareparts" class="table w-100">
                <thead>
                  <tr>
                    <th>IdSparepart</th>
                  </tr>
                </thead>
                <tbody class="row ml-0 mr-0 pl-0 pr-0">
                  <tr v-for="spare in spareparts" :key="spare.IdSparepart" 
                        class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 ml-0 mr-0 pl-0 pr-0">
                    <td > 
                      <div class="card shadow ml-0 mr-0 w-100" >
                        <img class="card-img-top" v-bind:src="this.urlSite + spare.Image" alt="Card image cap">
                        <div class="card-body text-center d-flex flex-column pt-0 mt-0">
                          <p class="card-title"><strong>{{ spare.Keterangan }}</strong></p>
                          <p class="card-text mt-auto">	START FROM <br> <strong>Rp. {{ spare.Harga }} ',- </strong>	</p>
                          <a v-bind:href="'/DetailProduct/'+spare.IdSparepart"  
                            class="linkBorder backBlue btn btn-block btn-sm pt-1 pb-1">View Detail</a>
                        </div>
                        <a @click="eventLike('sparepart', spare.IdSparepart)" >
                            <i :id="spare.IdSparepart" 
                                :class="spare.Status == null || spare.Status == '0' ? 
                                  'far fa-heart fa-2x btnLoveProduct':
                                  'fas fa-heart fa-2x btnLoveProduct'">
                            </i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div v-else class=" ml-1 mr-1 mt-2">
      <div class="col-12 m-0 p-0 w-100">
        <div class="roundedAll bg-light w-100">
          <div class="row m-0 p-0 w-100">
            <div class="col-12 w-100 m-0 p-0" >
              <div class="row card w-100 m-0 p-0 ">
                <table id="tblspareparts" class="table w-100">
                  <thead>
                    <tr>
                      <th>IdSparepart</th>
                    </tr>
                  </thead>
                  <tbody class="row ml-0 mr-0 pl-0 pr-0">
                    <tr v-for="spare in spareparts" :key="spare.IdSparepart" 
                          class="col-6 ml-0 mr-0 pl-0 pr-0">
                      <td > 
                        <div class="card shadow ml-0 mr-0 w-100" >
                          <img class="card-img-top" v-bind:src="this.urlSite + spare.Image" alt="Card image cap">
                          <div class="card-body text-center d-flex flex-column pt-0 mt-0">
                            <p class="card-title"><strong>{{ spare.Keterangan }}</strong></p>
                            <p class="card-text mt-auto">	START FROM <br> <strong>Rp. {{ spare.Harga.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }},- </strong>	</p>
                            <a v-bind:href="'/DetailProduct/'+spare.IdSparepart"  
                              class="linkBorder pt-1 pb-1 btnViewAll mt-auto">View Detail</a>
                          </div>
                          <a @click="eventLike('sparepart', spare.IdSparepart)" >
                              <i :id="spare.IdSparepart" 
                                  :class="spare.Status == null || spare.Status == '0' ? 
                                    'far fa-heart fa-2x btnLoveProduct':
                                    'fas fa-heart fa-2x btnLoveProduct'">
                              </i>
                          </a>
                        
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="min-height: 75px;"></div>
  </div>
</template>

<script>
import $ from 'jquery'
import { nytMixin } from "../assets/js/myMixin.js";

export default {
  
  mixins: [nytMixin],
  data() {
    return{
      carousels: [],
      numChecked:[],
      recomendeds: [],
      spareparts: [],
      curScreen: screen.width,
    }
  },
  beforeMount() {
    this.GetShoping();
  },
  mounted(){ 
    
		
  },
  updated() {
    $('#recipeCarousel').carousel({
      interval: 10000
    });
    
    $("#tblspareparts thead").hide();
    setTimeout(function() 
    { 
      $("#tblspareparts").DataTable( 
      {
        "lengthChange": false,
        "autoWidth": false,
        "retrieve": true,
      }); 
    }, 2000);
    
    $('.carousel .carousel-item').each(function(){
				var minPerSlide = 6;
				var next = $(this).next();
				if (!next.length) {
				next = $(this).siblings(':first');
			}
			next.children(':first-child').clone().appendTo($(this));
				for (var i=0;i<minPerSlide;i++) {
					next=next.next();
					if (!next.length) {
						next = $(this).siblings(':first');
			}
			next.children(':first-child').clone().appendTo($(this));
			}
		});
  },
  methods: {
    async GetShoping(){
      var kodeCust = '';
      if(localStorage.length > 0)
      {
        kodeCust = localStorage.Kode;
      }
      const resShoping = await this.DataShoping(kodeCust);
      //console.log(resShoping.data.dataProducts);
      this.carousels = resShoping.data.carousels;
      this.recomendeds = resShoping.data.recomendeds.filter(x => x.StatusRecomended == 'checked');
      this.spareparts = resShoping.data.dataProducts;

    },
    eventLike: function(jenis, idSpare)
    {
      console.log(jenis, idSpare);
      if(localStorage.Kode)
      {
        var dataLike = this.spareparts.filter(x => x.IdSparepart == idSpare);
        var objIndex = this.spareparts.findIndex((obj => obj.IdSparepart == idSpare));
        if(dataLike[0].Status == null || dataLike[0].Status == '0')
        {
          $("#"+idSpare).addClass( "fas" ); 
          this.spareparts[objIndex].Status = '1';
        }
        else
        {
          $("#"+idSpare).addClass( "far" );
          this.spareparts[objIndex].Status = '0';
        }
        var form_data = {};   
        form_data['Jenis'] = jenis;
        form_data['IdJenis'] = idSpare; 
        form_data['IdCust'] = localStorage.Kode; 
        form_data['StatusPhone'] = '1'; 
        form_data['Status'] = this.spareparts[objIndex].Status; 
        const resultData = this.FuncPost(this.urlSite + "/HttpApi/InsertUpdateLike", form_data);
        //console.log(resultData);
      }
      else
      {
        window.location.href='/LoginUser';
      }
    },
    
  }
  
};


</script>
