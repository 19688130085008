<template>
    <div class="row" style="margin-top: 75px;"></div>
    <div class="row w-100 m-0 p-0 ">
        <div class="col-12 d-flex justify-content-center"> 
            <img class="w-100" src="@/assets/images/ic_logo_ks.png">
        </div>
        <div class="col-12 mt-5 d-flex justify-content-center"> 
            <p><strong>Kartikasari App</strong></p>
        </div>
        <div class="col-12 m-0 p-0 d-flex justify-content-center">
            <p><em>Version xx.xx</em></p>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <p><em>@Copyright 2022</em></p>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <p class="text-center">For further information, please visit <a href="#" class="link-primary">www.kartikasari.co.id</a> or contact our customer care (0341) 479000 </p>
        </div>
    </div>
</template>