<template>
<div class="modal fade right" id="modalAlamat" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-backdrop="true">
      <div class="modal-dialog modal-dialog-centered modal-side modal-bottom-right modal-notify modal-danger" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Update Password</strong></h5>
        <button type="button"  class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
          <form id="apply-form"
            oninput='RePassword.setCustomValidity(RePassword.value != Password.value ? "Password Tidak Sama." : "")'>
              <div class="form-group">
                  <label>Password</label>
                  <input class="form-control form-control-sm" type="password" placeholder="Password"
                    v-model="newPassword" name="Password">
              </div>
              <div class="form-group">
                  <label>Re-Password</label>
                  <input class="form-control form-control-sm" type="password" placeholder="Re-Password"
                    name="RePassword">
              </div>
              <a href="javascript:void(0)" class="btn btn-sm btn-block btn-danger" @click="UpdatePass()">UPDATE PASSWORD</a>
          </form>
      </div>
	  <div class="modal-footer">
	  </div>
    </div>
  </div>
</div>
</template>
<script>
import $ from 'jquery'
import { nytMixin } from "../assets/js/myMixin.js";

export default {
    mixins: [nytMixin],
    data(){
        return{
            newPassword : '',
        }
    },
    setup() {
        
    },
    methods:{
        showModal() {
            $("#modalAlamat").modal("show");
        },
        async UpdatePass(){
          var isAnyEmpty = false;
          $('#apply-form *').filter(':input').each(function(key, value){
              //console.log("oke", key, this.value);
              if(this.value == "" )
              {
                isAnyEmpty = true;
              }
          });
          if(isAnyEmpty == false)
          {
            var form_data = {};   
            form_data['codeCust'] = localStorage.Kode; 
            form_data['newPassword'] = this.newPassword;
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/UpdatePass", form_data);
            if(resultData.data.search("Success") >= 0)
            {
                $("#modalAlamat").modal("hide");
                window.location.href="/SETTING";
            }
            else
            {
                alert("Gagal Update Password");
            }
          }
          else
          {
            alert("Isi Password Anda");
          }
        
        }
    }
}
</script>