<template>
    <div v-if="resLocation == '' || resLocation == undefined" style="padding-bottom: 15px;">
    </div>
    
    <div v-if="!isFormServiceShow" class="row  w-100 m-0 p-0 text-left">
        <div class="col-12 mb-2">
            <p class="d-inline">Kendaraan Anda</p>
            <button @click="showModal" class="d-inline float-right btn btn-sm btn-outline-dark my-0 py-0">
                <i class="fa-solid fa-square-plus"></i> Add Car
            </button>
            <button class="d-inline float-right btn btn-sm btn-outline-dark my-0 py-0 mx-1"
                @click="openFormServiceZero">
                <i class="fa-solid fa-square-plus"></i> Add Booking
            </button>
        </div>
        <div v-if="!isLoading" class="w-100">
            <facebook-loader :width="375" :speed="1" :animate="true" ></facebook-loader>
            <facebook-loader  :speed="1" :animate="true" ></facebook-loader>
            <facebook-loader  :speed="1" :animate="true" ></facebook-loader>
            <facebook-loader  :speed="1" :animate="true" ></facebook-loader>
            <facebook-loader  :speed="1" :animate="true" ></facebook-loader>
        </div>
        <div v-else v-for="x in units" :key="x.NoRangka" @click="openFormService(x.Name, x.NoRangka)" class="card w-100 my-1 mx-0 p-0"> 
            <div class="card-body my-1 py-1"> 
                <p class="my-0 py-0"><strong>{{x.NamaKendaraan}}</strong></p>
                <p class="d-inline my-0 py-0">{{x.NoPol}}</p>
                <p class="d-inline my-0 py-0 float-right">{{x.NoRangka}}</p>
            </div>
        </div>
    </div>

    <formService class="row w-100 m-0 p-0" ref="myFormService" 
        :homeLocation="resLocation" :metodeBooking="resMetodeBooking" />
    <modal ref="mymodal" />
    <div style="min-height: 100px;"> 
    </div>
</template>

<script>
import { nytMixin } from "../assets/js/myMixin.js";
import modal from "./ModalAddUnit.vue";
import formService from "./FormService.vue";
import { FacebookLoader } from 'vue-content-loader';


export default {
  props: ["resLocation", "metodeBooking"],
  components: { modal, formService, FacebookLoader },
  mixins: [nytMixin],
  data(){
      return {
          units: [],
          isFormServiceShow : false,
          resMetodeBooking : '',
          isLoading : false,
          screenWidht: screen.width,
      }
  },
  setup() {
  },
  beforeMount(){
        this.getUnitCust();
        if(this.metodeBooking == "" || this.metodeBooking == undefined)
        {
            this.resMetodeBooking = "APP-WORK";
        }
        else
        {
            this.resMetodeBooking = this.metodeBooking;
        }
  },
  methods: {
    async getUnitCust(){
        /*
        var form_data = {};
        form_data['token'] = this.token;
        form_data['kode'] = 'GETCUSTUNIT'; 
        form_data['params[0]'] = '';
        form_data['params[1]'] = localStorage.Kode; 
        const resultData = await this.FuncPostSecond(this.urlApiLoad, form_data);
        console.log(resultData);
        this.units = resultData.data.result;
        */
        if(localStorage.Kode)
        {
            var form_data = {};   
            form_data['codeCust'] = localStorage.Kode;
            const resultData = await this.FuncPost(this.urlSite + '/HttpApi/GetUnitCustomer', form_data);
            this.units = resultData.data.filter( x => x.ReleasedByOwner == '0' );
        }

        this.isLoading = true;
    },
    showModal() {
        this.$refs.mymodal.showModal();
    },
    closeModal() {
        this.$refs.mymodal.hideModal();
    },
    openFormService: function(unit, param){
        this.$refs.myFormService.setShowFormService(unit, param);
        this.isFormServiceShow = true;

        localStorage.isFormServiceOpen = "true";
    },
    openFormServiceZero()
    {
        this.$refs.myFormService.setShowFormServiceZero();
        this.isFormServiceShow = true;

        localStorage.isFormServiceOpen = "true";
    }
     
  },
  created(){
    
  },
  updated()
  {
    
  }
};
</script>
