<template>
  <div class="modal fade right" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-backdrop="true">
      <div class="modal-dialog modal-dialog-centered modal-side modal-bottom-right modal-notify modal-danger" role="document">
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header bg-danger">
            <p class="heading text-white">Tambah Unit Customer</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <!--Body-->
          <div class="modal-body">
            <div class="row">
              <div class="col-12 text-left"> 
                <form id="apply-form">
                  <div class="form-group">
                    <label for="noRangka">Nomor Rangka </label>
                    <input v-model="noRangka" id="noRangka" name="noRangka" class="form-control" placeholder="No Rangka" >
                  </div>
                  <div class="form-group">
                    <label for="nopol">NoPol </label>
                    <input v-model="nopol" id="nopol" name="nopol" class="form-control" placeholder="Nopol" >
                  </div>
                  <div class="form-group">
                    <label for="jenisKendaraan">Jenis Kendaraan </label>
                    <select v-model="jenisKendaraan" id="jenisKendaraan" name="jenisKendaraan" class="form-control" >
                      <option v-for="unit in units" :key="unit.Kode">{{unit.Keterangan}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="thnKendaraan">Tahun Kendaraan </label>
                    <input v-model="thnKendaraan" id="thnKendaraan" name="thnKendaraan" type="number" placeholder="Tahun Kendaraan" class="form-control" >
                  </div>
                  <div class="form-group">
                    <label for="masaAktif">Masa Aktif STNK </label>
                    <input v-model="masaAktif" id="masaAktif" name="masaAktif" type="date" placeholder="Masa Aktif STNK" class="form-control">
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="modal-footer flex-center">
            <a @click="addUnitCust" href="javascript:void(0)" class="btn btn-danger">
              Submit
            </a>
            <a type="button" class="btn btn-outline-danger waves-effect" data-dismiss="modal">Cancel</a>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>
</template>

<script>
import $ from 'jquery';
import { nytMixin } from "../assets/js/myMixin.js";

export default {
    mixins: [nytMixin],
    data() {
        return{
            units: [],
            noRangka : '',
            nopol: '',
            jenisKendaraan: '',
            thnKendaraan: '',
            masaAktif: '',
        }
    },
    beforeMount() {
        this.getUnit();
    },
  methods: {
    showModal() {
      $("#exampleModal").modal("show");
    },
    hideModal() {
        $("#exampleModal").modal("hide");
    },
    async getUnit()
    {
      var form_data = {};
      const response = await this.FuncGet('GetAllUnit', form_data);
      this.units = response.data;
      if(this.units.length > 0)
      {
        this.jenisKendaraan = this.units[0].Keterangan;
      }
    },
    async addUnitCust()
    {
      var isAnyEmpty = false;
      $('#apply-form *').filter(':input').each(function(key, value){
          //console.log("oke", key, this.value);
          if(this.value == "" )
          {
            isAnyEmpty = true;
          }
      });
      if(isAnyEmpty == false)
      {
        var form_data = {};   
        form_data['token'] = this.token;
        form_data['kode'] = 'INSERTVEHICLECUST'; 
        form_data['params[0]'] = this.noRangka; 
        form_data['params[1]'] = localStorage.Email; 
        form_data['params[2]'] = this.nopol; 
        form_data['params[3]'] = this.jenisKendaraan; 
        form_data['params[4]'] = this.thnKendaraan; 
        form_data['params[5]'] = this.masaAktif; 
        form_data['params[6]'] = 0; 
        const resultData = await this.FuncPostSecond(this.urlApiLoad, form_data);
        if(resultData.data.pesan == "Success")
        {
          //window.location.href="/";
          window.location.reload();
        }
        else
        {
          alert("Gagal Menambahkan Unit");
        }
      }
      else
      {
        alert("Lengkapi Form Anda");
      }
    }
  },
};
</script>