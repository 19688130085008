<template>
<!-- modal list Alamat -->
<div class="modal fade right" id="modalAlamat" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-backdrop="true">
      <div class="modal-dialog modal-dialog-centered modal-side modal-bottom-right modal-notify modal-danger" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Pilih Alamat</strong></h5>
        <button type="button" @click="hideModal" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
		<ul v-if="addrCusts.length > 0 " class="list-group" id="listAddrCust">		
            <li v-for="(addr, index) in addrCusts" :key="index" class="list-group-item "
                @click="chooseAddr(addr.PhonePenerima, addr.AlamatPenerima)" >
                Phone Penerima:<em class="dataPhone">{{addr.PhonePenerima}}</em>
                <br>Alamat Penerima:
                <em class="dataAddr">{{addr.AlamatPenerima}}</em>
            </li>
		</ul>
        <p v-else class="d-flex justify-content-center"><em>Tidak Ada Histori Alamat</em></p>
      </div>
	  <div class="modal-footer">
	  </div>
    </div>
  </div>
</div>
</template>
<script>
import $ from 'jquery';
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    setup() {
        
    },
    data() {
        return {
            addrCusts: [],
        }
    },
    beforeMount(){
      this.getAllContent();
    },
    methods: {
        showModal() {
          $("#modalAlamat").modal("show");
        },
        hideModal() {
            $("#modalAlamat").modal("hide");
        },
        async getAllContent() {
            var form_data = {};   
            form_data['idCust'] = localStorage.Kode;
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/OrderStatus", form_data);
            this.addrCusts = resultData.data.addrCust;
        },
        chooseAddr(phonePenerima, alamatPenerima) {
            //console.log("pilih tanggal", phonePenerima, alamatPenerima);
            this.$emit('address', {phone: phonePenerima, alamat: alamatPenerima});
        }
    }
}
</script>