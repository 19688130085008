<template>
    <div v-if="isMobile()" class="col-12 w-100" style="padding-top: 15px;">
        <p class="text-left"><strong>ALL RECOMENDED</strong></p>
    </div>
     <div v-else class="col-12 w-100" style="padding-top: 25px;">
        <h5 class="text-left"><strong>ALL RECOMENDED</strong></h5>
    </div>
    <div class="col-12 w-100 m-0 p-0" >
        <div class="row w-100 m-0 p-0">
            <div v-for="rec in recomendeds" :key="rec.Kode" 
                class="w-100 m-0 p-1 col-6 col-xl-2 col-lg-2 col-md-3" >
                <div class="card shadow w-100 m-0 p-0" style="min-height:270px;">
                    <img class="card-img-top" v-bind:src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+rec.Image+'&size=500'" 
                        alt="Card image cap">
                    <div class="card-body text-center d-flex flex-column pt-0 mt-0">
                        <p class="card-title"><strong>{{rec.Keterangan}}</strong></p>
                        <p class="card-text w-100 mt-auto">START FROM <br> <strong>{{rec.Price}} </strong>	</p>
                        <a v-bind:href="'/DetailCar/' + rec.Jenis" 
                            class="linkBorder btnViewAll p-1 mt-auto">View Detail</a>
                    </div>
                </div>
            </div>           
        </div>
    </div>
    <div class="col-12" style="min-height: 100px;">
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";

export default {
    mixins: [nytMixin],
    data() {
        return{
            recomendeds: [],
        }
    },
    setup() {
        
    },
    beforeMount() {
        this.GetCarCatalogue();
    },
    methods: {
        async GetCarCatalogue(){
            const resCatalogue = await this.HomeCarCatalogue();
            this.recomendeds = resCatalogue.data.recomendeds;
        },
    }
}
</script>