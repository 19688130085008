<template>
<div v-if="this.isMobile()" class="col-12 w-100 m-0 p-0">
	<div class="row w-100 m-0 p-0">
		<div class="imageDetail w-100 col-12 px-0 pb-2 m-0 d-flex justify-content-center">
			<img v-if="product.Image != undefined" class="w-75" :src="this.urlSite +product.Image" />
		</div>
		<div class="col-12 mt-n3 px-0  ">
			<div class="card roundedTop w-100 m-0 p-0"> 
				<div class="card-body">
                    <p class="card-title text-left"><strong  id="partName">{{product.Keterangan}}</strong></p>
                    <p class="pull-right text-left" id="harga"><strong>Rp.{{parseInt(product.Harga).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</strong></p>
                    <div class="overflow-auto text-left" style="min-height: 100px;">
                        <p class="card-text mt-5">{{product.Detail}}</p>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left" @click="hideNote">
                            Add Note
                            <i  class="fas fa-pencil-alt text-warning float-right pb-2">	</i>
                        </div>
                        <div v-if="dataNote == true" class="col-12 my-1 py-1">
                            <ion-textarea v-model="notePembeli" rows="3" 
                                placeholder="Contoh: Warna Merah" class="border">
                            </ion-textarea>
                        </div>
                        <div class="col-8 text-left">
                            <p>Quantity</p>
                        </div>
                        <div class="col-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <input type="button" class="btn btn-dark btn-sm" 
                                        style="border-radius: 15px 0px 0px 15px" 
                                        id="minus-btn" value="-"
                                        @click="countQuantity(false)">
                                </div>
                                <input type="number" id="qty_input" name="qty_input" style="text-align: center;" 
                                    v-model="dataQuantity" class="form-control form-control-sm" min="1">
                                <div class="input-group-prepend"> 
                                    <input type="button" class="btn btn-dark btn-sm" 
                                        style="border-radius: 0px 15px 15px 0px" 
                                        id="plus-btn" value="+"
                                        @click="countQuantity(true)">
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="row"> 
                        <div class="col-12">
                            <button class="btn btn-ksm btn-block btn-sm btnRounded pull-right btnAddChart"
                                @click="addKeranjang" >
                                <i class="fas fa-cart-plus"></i> Add To My Chart
                            </button>
                        </div>
                    </div>	
				</div>
			</div>
		</div>
	</div>
    <div style="min-height: 75px;"></div>
</div>

<div v-else>
    <div class="row mt-5 w-100 m-0 p-0">
        <div class="col-md-4 mt-2">
		    <div class="card shadow w-100 m-0 p-0 roundedAll">
                <img v-if="product.Image != undefined" :src="this.urlSite + product.Image" 
                class="rounded card-img-top w-100" 
				alt="Sparepart" height="300" > 
            </div>
	    </div>
        <div class="col-md-5 mt-2 text-left" >
            <div class="card shadow bg-white roundedAll w-100 m-0 p-0" style="min-height: 300px;">
                <div class="card-body"> 
                    <h6 class="card-title "> <strong> {{product.Keterangan}} </strong></h6>
                    <p class="card-text" > Terjual <strong v-if="product.jmlTerjual">{{ product.jmlTerjual }}</strong> <strong v-else>0</strong></p>
                    <h6 class="card-title"><strong> Rp.{{parseInt(product.Harga).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}}</strong></h6>
                    <p class="card-text"> {{product.Detail}} </p>
                </div>
            </div>
        </div>
        <div class="col-md-3 mt-2 text-left" >
            <div class="card shadow bg-white w-100 m-0 p-0 roundedAll" style="min-height: 300px;">
                <div class="card-body">
                    <form method="post" action="#"> 
                         <h6 class="card-title "> <strong>Keterangan </strong></h6>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <input type="button" class="btn btn-dark btn-sm " id="minus-btn" 
                                    value="-" @click="countQuantity(false)">
                                </div>
                                <input style="text-align: center;" type="number" 
                                    id="qty_input" name="qty_input" class="form-control form-control-sm"  
                                    min="1" v-model="dataQuantity">
                                <div class="input-group-prepend">
                                    <input type="button" class="btn btn-dark btn-sm " id="plus-btn" 
                                    value="+" @click="countQuantity(true)">
                                </div>
                            </div>
                        <h6 class="card-title">
                            <a href="javascript:void(0)" class="btnTambahCatatan removeBlueLink" @click="hideNote">
                                <i class="fas fa-pencil-alt float-right" ></i> <strong>Tambahkan Catatan</strong>
                            </a>
                        </h6>
                        <div v-if="dataNote == true" class="w-100 m-0 p-0 catatan" >
                            <textarea name="catatan" class="form-control inputCatatan" 
                                placeholder="Contoh: Warna atau Ukuran" v-model="notePembeli"></textarea>
                            
                        </div>
                        <h6 class="mt-3 ">Subtotal Rp. <strong class="float-right" id="subtotal">{{ parseInt(parseInt(product.Harga) * dataQuantity).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }}</strong></h6>
                        <a class="btn btn-block mt-3 backBlue" @click="addKeranjang">+ Keranjang</a>
                        <!--<button class="btn btn-block mt-2 backRed">Langsung Beli</button>-->
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
    
</div>

</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
import { IonTextarea } from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent ({
    components: { IonTextarea },
    mixins: [nytMixin],
    setup() {
        
    },
    data(){
        return{
            dataParam: this.$route.params.param,
            product: [],
            dataNote : false,
            dataQuantity: 1,
            notePembeli: '',
        }
    },
    beforeMount() {
        this.detailProduct();
        
        //this.GetPosition();
    },
    methods: {
        async detailProduct() {
            const dataProduct = await this.getDetailProduct(this.dataParam);
            this.product = dataProduct.data;
        },
        hideNote: function(){
            if(this.dataNote) { this.dataNote = false;  }
            else { this.dataNote = true; }
        },
        countQuantity: function(param){
            if(param) { this.dataQuantity++; }
            else { if( this.dataQuantity > 1) {this.dataQuantity--;} }
        },
        async addKeranjang()
        {
            if(localStorage.Kode)
            {
                var form_data = {};
                form_data["IdSparepart"] = this.dataParam,
                form_data["HargaSparepart"] = this.product.Harga;
                form_data["qty_input"] = this.dataQuantity;
                form_data["catatan"] = this.notePembeli;
                form_data["CodeCust"] = localStorage.Kode;
                
                var retData = await this.FuncPost(this.urlSite + '/HttpApi/AddToChart', form_data);

                if(retData.data.search("Success") >= 0)
                {
                    window.location.href="/ORDERSTATUS";
                }
                else
                {
                    alert("Gagal menambahkan ke chart, Coba Lagi");
                }
            }
            else
            {
                window.location.href="/LoginUser";
            }
        }
    }
});
</script>