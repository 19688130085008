<template>
    <div class="container-fluid match-parent login-block" >
        <div class="row h-100 justify-content-center align-items-center ">
            <div class="col-12 d-flex justify-content-center">
                <div class="card col-md-4 login-sec">
                    <form class="login-form " method="post" action="">
                        <h2 class="text-center">KARTIKASARI</h2>
                        <div class="form-group">
                            <label for="userID" class="text-uppercase float-left">User ID/ Phone Number</label>
                            <input type="text" class="form-control" name="userID" id="userID" placeholder="User ID" v-model="userName">
                        </div>
                        <div class="form-group">
                            <label for="password" class="text-uppercase float-left">Password</label>
                            <input type="password" class="form-control" id="password" name="password" placeholder="Password" v-model="password">
                        </div>
                        <div class="form-group">
                            <input type="button" class="btn btn-login btn-block" id="btnLogin" 
                                value="Login" @click="LoginCust">
                        </div>
                        <div class="form-group"> 
                            <label class="form-check-label float-left textRed">
                                <a href="javascript:void(0)"  data-toggle="modal" data-target="#modalForgotPass"
                                    class="removeBlueLink ">
                                    Forgot Password
                                </a>
                            </label>
                            <label class="form-check-label float-right textKsm">
                                <a href="/RegisterUsers"  
                                    class="removeBlueLink ">
                                    Register
                                </a>
                            </label>
                           
                        </div>
                    </form>
                    <div class="g-signin2 mt-4"  
                        data-height="35" data-longtitle="true" data-onsuccess="onSignIn">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal Forgot Password -->
    <div class="modal fade" id="modalForgotPass" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content roundedCarousel">
        <div class="modal-body ">
            <div class="row">
                <div class="col-12">
                    <label class="d-flex justify-content-center"><strong>FORGOT PASSWORD</strong></label>
                </div>
                <div class="col-12">
                    <div class="form-group text-left">
                        <label for="emailForgotPass" >Your Email</label>
                        <input type="email" id="emailForgotPass" class="form-control form-control-sm roundedAll"
                            v-model="emailForgotPassword" placeholder="admin@kartikasari.co.id"/>
                    </div>
                    <button class="btn btn-block btn-sm btn-ksm text-white" @click="eventSendNotif">Send My Code</button>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>

import { nytMixin } from "../assets/js/myMixin.js";
import { loadingController } from '@ionic/vue';
import $ from 'jquery';
import { Plugins, Capacitor,
        PushNotificationActionPerformed, 
        PushNotificationToken,
        Geolocation } from '@capacitor/core';
const { PushNotifications, LocalNotifications, Device } = Plugins;
export default {
    mixins: [nytMixin],
    data(){
        return {
            userName: '',
            password: '',
            dataUser: [],
            emailForgotPassword: '',
            deviceInfo: {
                DeviceID: '',
                DeviceName: '',
                DeviceOS: ''
            },
        }
    },
    beforeMount(){
        if(localStorage.Kode)
        {
            //router.push("/");
            window.location.href="/";
        }
        this.getInfoDevice();
    },
    methods:{
        async getInfoDevice()
        {
            const info = await Device.getInfo();
            this.deviceInfo.DeviceID = info.uuid;
            this.deviceInfo.DeviceName = info.model;
            this.deviceInfo.DeviceOS = info.osVersion;
        },
        async eventSendNotif()
        {
            if(this.emailForgotPassword)
            {
                var form_data = {};   
                form_data['emailCust'] = this.emailForgotPassword;
                const resultData = await this.FuncPost(this.urlSite + "/HttpApi/changePassword", form_data);
                console.log("result login", resultData.data.result);
                if(resultData.data.result.search("Email Sent") >= 0)
                {
                    alert("Periksa Link Ganti Password Di Email Anda");
                    $("#modalForgotPass").modal("hide");
                }
                else
                {
                    alert("Failed, Check Koneksi Anda!!!");
                }
               
            }
            else
            {
                alert("Isi Email Anda");
            }
        },
        async LoginCust(){
            const loading = await loadingController.create({
                message: 'Please Wait...',
                spinner: 'circles'
            });
            loading.present();

            var deviceOsRes = '';
            if( this.deviceInfo.DeviceName.search("iPhone") >= 0 )
            {
                deviceOsRes = 'iOS';
            }
            else
            {
                deviceOsRes = 'Android';
            }

            var form_data = {};   
			form_data['userID'] = this.userName;
            form_data['password'] = this.password; 
            form_data['DeviceID'] = this.deviceInfo.DeviceID;
            form_data['DeviceName'] = this.deviceInfo.DeviceName;
            form_data['DeviceOS'] = deviceOsRes;
            const resultData = await this.FuncPost(this.urlSite +"/HttpApi/LoginUser", form_data);
            //console.log("result login", JSON.stringify(resultData));

            loading.dismiss();
            
            if(!resultData.data){
                alert("User atau Password Salah");
            }
            else
            {
                //console.log("ada data");
                
                if(localStorage.tokenFirebase != resultData.data.tokenFirebase)
                {
                    form_data = {};
                    form_data['Kode'] = resultData.data.Kode;
                    form_data['TokenFirebase'] = localStorage.tokenFirebase;
                    const resultFB = await this.FuncPost(this.urlSite + "/HttpApi/UpdateTokenFB", form_data);
                    //console.log("result token login", JSON.stringify(resultFB));
                    //console.log("localstorage", localStorage.tokenFirebase);
                    if(resultFB.data.search("Success") >= 0)
                    {
                        this.dataUser = resultData.data;
                        localStorage.Kode = this.dataUser.Kode;
                        localStorage.CalonKode = this.dataUser.CalonKode;
                        localStorage.Nama = this.dataUser.NamaCust;
                        localStorage.Email = this.dataUser.Email;
                        localStorage.Telp = this.dataUser.Telp;
                        localStorage.Alamat = this.dataUser.Alamat;
                        localStorage.Kota = this.dataUser.Kota;
                        localStorage.TglLahirOwner = this.dataUser.TglLahirOwner;

                        if(resultData.data.CalonKode != null || resultData.data.CalonKode.trim() != '')
                        {
                            this.getNotif();
                        }

                        window.location.href="/";
                    }
                    else
                    {
                        alert("Please Check Your Connection");
                    }
                }
                else
                {
                    this.dataUser = resultData.data;
                    localStorage.Kode = this.dataUser.Kode;
                    localStorage.CalonKode = this.dataUser.CalonKode;
                    localStorage.Nama = this.dataUser.NamaCust;
                    localStorage.Email = this.dataUser.Email;
                    localStorage.Telp = this.dataUser.Telp;
                    localStorage.Alamat = this.dataUser.Alamat;
                    localStorage.Kota = this.dataUser.Kota;
                    localStorage.TglLahirOwner = this.dataUser.TglLahirOwner;


                    if(resultData.data.CalonKode != null || resultData.data.CalonKode.trim() != '')
                    {
                        this.getNotif();
                    }

                    window.location.href="/";
                }
                
            }
            
        },
        async getNotif()
        {
            var form_data = {KodeCust : localStorage.CalonKode};
            var resultDataNotif = await this.FuncPost(this.urlSite + "/HttpApi/GetAllNotif", form_data);
            localStorage.SettingNotif = JSON.stringify(resultDataNotif.data);
        },
        Logout: function()
        {
            const dataReturn = this.funcLogout();
            if(dataReturn)
            {
                window.location.href="/";
            }
        }
    }
}
</script>
<style>
.match-parent {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
</style>