<template>
<div class="content-wrapper">
    <content-loader v-if="!myData"
        :speed="1"
        :animate="true"
    >
    </content-loader>
    <facebook-loader v-if="!myData"
        :speed="1"
        :animate="true"
    ></facebook-loader>
    <Code-loader v-if="!myData"
        :speed="1"
        :animate="true"
    ></Code-loader>
    <List-loader v-if="!myData"
        :speed="1"
        :animate="true"
    ></List-loader>
    <BulletList-loader v-if="!myData"
        :speed="1"
        :animate="true"
    ></BulletList-loader>
    <div v-else class="my-real-content">
      <p>{{myData}}</p>
    </div>
  </div>
</template>

<script>
import { ContentLoader, FacebookLoader, CodeLoader, ListLoader, BulletListLoader } from 'vue-content-loader';

export default {
  components: {
    ContentLoader, FacebookLoader, CodeLoader, ListLoader, BulletListLoader
  },

  data() {
    return {
      myData: null
    }
  },

  mounted() {
    setTimeout(() => {
      this.myData = 'Example Data';
    }, 10000);
  }
}
</script>