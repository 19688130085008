<template>
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 110px;' : ''">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <a href="#">
                    <img class="d-block w-100" src="https://www.toyota.astra.co.id/themes/custom/responsive/images/social-media/banner-soscialmedia.jpg" alt="Image Data">
                </a>
            </div>	
        </div>
    </div> 

    <div class="row mt-5 ml-2 mr-2">
        <div v-for="(medsos, index) in sosmed" :key="index" class="col-sm-12 col-md-4">
            <p v-if="medsos.Judul != 'Preloader'" class="text-white" v-html="medsos.LinkEmbed"></p>
        </div>
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    data(){
        return{
            sosmed: [],
            curScreen: screen.width,
        }
    },
    setup() {
        
    },
    beforeMount(){
        this.getSocialMedia();
    },
    methods:{
        async getSocialMedia()
        {
            var form_data = {}; 
            const getData = await this.FuncGet("/SocialMedia", form_data);
            this.sosmed = getData.data;
            //console.log(this.sosmed);
        }
    }
}
</script>