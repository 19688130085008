<template>
    <div class="row ">
        <div class="col-12">
            <section class="section pt-0 mt-5" 
                :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 120px !important;' : ''">
                <div class="container text-left">
                    <h2><strong>Booking Service</strong></h2>
                    <p>Untuk melakukan servis kendaraan Anda, silakan isi Form dibawah ini, representatif KARTIKASARI akan menghubungi Anda segera.</p>
                    <!-- <form method="post" action="#" > -->
                        <div class="form-group form-row">
                            <div class="col">
                                <label for="Nama"><strong>Nama</strong></label> 
                                <input type="text" v-model="formData.Nama" class="form-control Nama" id="Nama" name="Nama" placeholder="Nama" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                            <div class="col">
                                <label for="Phone"><strong>Phone</strong></label> 
                                <input type="text" v-model="formData.Phone" class="form-control Phone" id="Phone" name="Phone" placeholder="+62 xxx xxx xxx" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                            <div class="col">
                                <label for="Email"><strong>Email</strong></label> 
                                <input type="text" v-model="formData.Email" class="form-control Email" id="Email" name="Email" placeholder="Name@kartika.co.id">
                                <em class="text-danger">*optional</em>
                            </div>
                        </div>
                        <div class="form-group form-row">
                            <div class="col">
                                <label for="Tanggal"><strong>Tanggal</strong></label> 
                                <input type="date" v-model="formData.Tanggal" @change="diffDateBooking"
                                    class="form-control Tanggal" id="Tanggal" name="Tanggal"
                                    :min="minDate" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                            <div class="col">
                                <label for="Jam"><strong>Jam</strong></label> 
                                <input type="time" v-model="formData.Jam" class="form-control Jam" id="Jam" 
                                    name="Jam" min="08:30" max="16:30" @change="changeTime" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                            <div class="col">
                                <label for="Nopol"><strong>Nopol</strong></label> 
                                <input type="text" v-model="formData.Nopol" class="form-control Nopol" id="Nopol" name="Nopol" placeholder="S19xxHT" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="form-group form-row">
                            <div class="col">
                                <label for="Unit"><strong>Nama Unit</strong></label> 
                                <input type="text" v-model="formData.Unit" class="form-control Unit text-uppercase" id="Unit" placeholder="AGYA" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                            <div class="col">
                                <label for="Odoo"><strong>Odoo Meter</strong></label> 
                                <input type="number" v-model="formData.Odoo" class="form-control Odoo" id="Odoo" name="Odoo" placeholder="10000" required>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="form-group form-row">
                            <div class="col">
                                <label for="JenisService"><strong>Jenis Service</strong></label>
                                <div class="row ml-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" v-model="formData.JenisService" type="radio" 
                                            name="JenisService" id="JenisService" value="WORKSHOP" checked>
                                        <label class="form-check-label" for="JenisService">WORKSHOP</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" v-model="formData.JenisService" type="radio" 
                                            name="JenisService" id="JenisService2" value="KSC">
                                        <label class="form-check-label" for="JenisService2">KSC</label>
                                    </div>
                                </div>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                            <div class="col">
                                <label for="Service"><strong>Type Service</strong></label> 
                                <div class="row ml-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" v-model="formData.TypeService" type="radio" id="Service" name="TypeService" value="SERVICE" checked>
                                        <label class="form-check-label" for="Service">Service</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" v-model="formData.TypeService" type="radio" id="AutoDetailing" name="TypeService" value="AUTODETAILING">
                                        <label class="form-check-label" for="AutoDetailing">AUTODETAILING</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="RemapeEcu" name="TypeService" value="REMAP ECU">
                                        <label class="form-check-label" for="RemapeEcu">REMAPE ECU</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" v-model="formData.TypeService" type="radio" id="Towing" name="TypeService" value="TOWING">
                                        <label class="form-check-label" for="Towing">TOWING</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" v-model="formData.TypeService" type="radio" id="Waranty" name="TypeService" value="WARANTY">
                                        <label class="form-check-label" for="Waranty">WARANTY</label>
                                    </div>
                                </div>
                                <em class="text-danger">*wajib di isi</em>
                            </div>
                        </div>
                        <div class="form-group form-row">
                            <label for="Keterangan"><strong>Keterangan</strong></label>
                            <textarea v-model="formData.Keterangan" class="form-control Keterangan" id="Keterangan" name="Keterangan" rows="3" required></textarea>
                            <em class="text-danger">*wajib di isi</em>
                        </div>
                        
                        <button class="btn btn-ksm float-right " @click="bookingService" >
                            Submit
                        </button>
                    <!-- </form> -->
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
import $ from 'jquery'
export default {
    mixins: [nytMixin],
    setup() {
        
    },
    data(){
        return{
            formData: {
                Nama: '',
                Phone: '',
                Email: '',
                Tanggal: '',
                Jam: '',
                Nopol: '',
                Unit: '',
                Odoo: '',
                JenisService: 'WORKSHOP',
                TypeService: 'Service',
                Keterangan: '',
            },
            minDate: '',
            curScreen: screen.width,
        }
    },
    methods:{
        changeTime: function()
        {
            var getMin = document.getElementById("Jam").min;
            var getMax = document.getElementById("Jam").max;
            
            var currentD = new Date();
            currentD.setHours(parseInt(this.formData.Jam.substring(0,2)),parseInt(this.formData.Jam.substring(3,5)),0);
            var startHappyHourD = new Date();
            startHappyHourD.setHours(parseInt(getMin.toString().substring(0,2)),parseInt(getMin.toString().substring(3,5)),0); 
            var endHappyHourD = new Date();
            endHappyHourD.setHours(parseInt(getMax.toString().substring(0,2)),parseInt(getMax.toString().substring(3,5)),0);
            if(currentD < startHappyHourD || currentD > endHappyHourD)
            {
                this.formData.Jam = "08:00";
                alert("Jam Service Dimulia 08:00 - 16:30");
            }
        },
        async bookingService()
        {
            if(this.formData.Nama != "" && this.formData.Phone != "" && this.formData.Email != ""
                && this.formData.Tanggal != "" && this.formData.Jam != "" && this.formData.Nopol != ""
                && this.formData.Unit != "" && this.formData.Odoo != "" && this.formData.Keterangan != "" )
            {
                console.log(this.formData);
                const insertBooking = await this.FuncPost(this.urlSite + "/HttpApi/AddBookingService", this.formData);
                console.log(insertBooking);
                
                if(insertBooking.data.search("Success") >= 0)
                {
                    window.location.href="/";
                }
                else
                {
                    alert(insertBooking.data);
                }
                
            }
            else
            {
                alert("Lengkapi Form Anda");
            }
        },
        diffDateBooking: function(event)
        {
            var dateBooking = event.target.value;
			var today = new Date();
			var nextDay = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+(today.getDate() + 1);
			var diffInMs = new Date(dateBooking) - new Date(today);
			var TotalDays = Math.ceil(diffInMs / (1000 * 3600 * 24));
			if(TotalDays < 1)
			{
				this.formData.Tanggal = nextDay;
				alert("Tanggal Booking Minimal Besok (Hari Ini + 1)");
			}
        }
    },
    beforeMount() {
        var today = new Date();
		//this.formData.Tanggal = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+(today.getDate() + 1); 

         /* set min date test drive */
        var d = new Date();
        var mm = String(d.getMonth() + 1).padStart(2, '0');
        var dd = String(d.getDate() + 1).padStart(2, '0');
        var yy = d.getFullYear();
        var myDateString = yy + '-' + mm + '-' + dd ;
        this.minDate = myDateString;
    }
}
</script>