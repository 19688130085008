<template>
    <div class="row w-100 p-0 m-0 text-left">
        <div v-if="!isMobile()" class="col-12 d-flex justify-content-center mt-3">
            <img v-if="type == 'NewsAndEvent' || type == 'TipsTrick'" class="img-responsive" style="height: auto; width: 50%;"
					    :src="this.urlSite + news.LinkImage">
            <img v-if="type == 'Promo'" class="img-responsive" style="height: auto; width: 50%;"
					    :src="this.urlSite + news.Link">
        </div>
        <div class="col-12 d-flex justify-content-between w-100 mt-2 mb-5 mx-0 p-0 overflow-auto"
            style="min-height: 700px;" >
            <article class="white-panel w-100 mt-3 mb-5 p-0" :class="!isMobile() ? 'mx-5' : 'mx-1'"> 
                <h4><a class="removeBlueLink" href="#">{{news.Judul}}</a></h4>
                <span v-if="type == 'NewsAndEvent' || type == 'TipsTrick'" v-html="news.Detail"></span>
                <span v-if="type == 'Promo'" v-html="news.IsiPromo"></span>
            </article>
        </div>
        <div class="col-12" style="min-height: 20px;">
        </div>
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";

export default {
  mixins: [nytMixin],
  data() {
    return{
      news: [],
      paramNews: this.$route.params.param,
      type: this.$route.params.type.replace(/\s/gi,""),
    }
    
  },
  beforeMount() {
    this.GetDetailNews();
  },
  mounted(){
  },
  created(){
    console.log("paramNews", this.paramNews);
  },
  methods: {
    async GetDetailNews(){
      const resNews = await this.getDetailNews(this.paramNews, this.type);
      this.news = resNews.data;
      console.log(this.news);
      //console.log(resNews.data);
    },
  },
};
</script>