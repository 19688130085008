<template>
    <div class="col-12 w-100" style="padding-top: 15px;">
        <p class="text-left"><strong>NEWS AND EVENT</strong></p>
    </div>
    <a v-for="nn in news" :key="nn.IdNews" :href="'/DetailNews/' + nn.IdNews + '/News And Event'">
        <div class="card card-block shadow bg-white rounded w-100 mx-0 my-2 p-0 text-left" >
            <div class="row w-100 m-0 p-0">
                <div class="col-4" style="margin-top: auto; margin-bottom: auto;" >
                    <img class="img-fluid" 
                        :src="this.urlSite + nn.LinkImage" >	
                </div> 
                <div class="col-8 w-100 m-0 p-0">
                    <p class="pt-1"><small><em>{{ nn.CreateDate.substring(0,10) }}</em></small></p>
                    <p style="color: black;"><small>{{ nn.Judul }}</small></p>
                </div>
            </div>
        </div>
    </a>
    <div v-if="isMobile()" style="min-height: 75px;" ></div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";

export default {
  mixins: [nytMixin],
  data() {
    return{
      news: [],
    }
    
  },
  beforeMount() {
    this.GetNews();
    
  },
  mounted(){
    
  },
  methods: {
    async GetNews()
    {
        var form_data = {};
        form_data['param'] = "News";
        var resData = await this.FuncGet('/GetNews', form_data);
        if(resData.data.length > 0)
        {
            this.news = resData.data;
        }
        //console.log(this.resultNews);
    }
  },
};
</script>