<template>
<div style="min-height: 75px;"></div>
<p>asdf</p>

<input type="text" placeholder="Search in here!!!" class="form-control" list="cityname" @change="selectData"/>
<datalist id="cityname">
  <option value="Boston">boston </option>
  <option value="Cambridge">cambridge </option>
</datalist>
<p>batasan</p>

</template>
<script>

export default {
  data() {
    return {
      search: ''
    }
  },
  methods:{
    selectData(event)
    {
      console.log(event.target.value);
    }
  },
  
  
}
</script> 