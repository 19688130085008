<template>
    <div class="row" style="margin-top: 15px;" 
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 110px;' : ''"></div>
    <div class="row w-100 p-0 m-0">
        <div class="col-12 text-center" style="padding-bottom: 75px;">
            <p v-html="value.Detail"></p>
        </div>
    </div>
    <div class="row bt-warning" style="margin-bottom: 75px;" ></div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    setup() {
        
    },
    data() {
        return{
            value:[],
            curScreen: screen.width,
        }
    },
    methods: {
        async getPaP(){
            var form_data = {};
            const resultData =  await this.FuncGet("/GetPaP", form_data);
            this.value = resultData.data.results;
        }
    },
    beforeMount() {
        this.getPaP();
    }
}
</script>