<template>
    <div style="min-height: 15px;" ></div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-right">
                <a class="btnRounded  mx-1 px-1" :class="menuActive == 'SettingNotif' ? 'bg-ksm text-white' : 'bg-white text-dark border'" @click="setActive('SettingNotif')"> Setting Notif </a>
                <a class="btnRounded  mx-1 px-1" :class="menuActive == 'HistoryNotif' ? 'bg-ksm text-white' : 'bg-white text-dark border'" @click="setActive('HistoryNotif')"> History Notif </a>
            </div>
        </div>
        <div v-if="menuActive == 'SettingNotif'">
            <div class="row card border m-2 p-2 rounded shadow">
                <div v-for="(notif, index) in notifs" :key="index" class="col-12 text-left">
                    <div class="custom-control custom-checkbox ">
                        <input type="checkbox" class="custom-control-input " :id="notif.Kode" 
                            v-model="notif.IsActive">
                        <label class="custom-control-label " :for="notif.Kode">{{ notif.NamaNotif }}</label>
                    </div>
                </div>
                <button class="btn btn-sm btn-ksm mt-2" @click="saveSetting">Save Setting</button>
            </div>
        </div>
        <div v-if="menuActive == 'HistoryNotif'" class="row mt-2">
            <!-- <div v-if="notifIsnEmpty" class="col-12 mb-2">
                <button class="btn btn-ksm btn-sm float-right" @click="clearAll">Clear All</button>
            </div> -->
            <div v-if="notifIsnEmpty" v-for="(notif, index) in myNotif" :key="index" class="col-12 mb-2">
                <div v-if="notif.Judul != ''" class="card roundedAll bg-white">
                    <div class="card-header my-0 py-0">
                        <p class="float-left my-1 py-1"><strong>{{ notif.Judul }}</strong></p><br>
                        <p class="float-right"><small>{{ notif.CreateDate }}</small></p>
                       
                    </div>
                    <div class="card-body shadow my-0 py-0 ">
                        <p class="my-0 py-0 mb-2">{{ notif.Pesan }}</p>
                    </div>
                </div>
            </div>
            <div v-else class="col-12 ">
                <p class="text-center"><em>Notifikasi Kosong</em></p>
            </div>
        </div>
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    data()
    {
        return{
            myNotif:[],
            notifIsnEmpty: false,
            menuActive: 'SettingNotif',
            notifs: [],
        }
    },
    beforeMount() 
    { 
        this.getNotif();
        this.getHistoryNotif();
    },
    methods:{
        async getHistoryNotif()
        {
            var form_data = {KodeCust: localStorage.Kode, KodeCalon : localStorage.CalonKode};
            var resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetHistoryNotif", form_data);
            this.myNotif = resultData.data;
            if(this.myNotif.length > 0)
            {
                this.notifIsnEmpty = true;
            }
        },
        async saveSetting()
        {
            localStorage.SettingNotif = JSON.stringify(this.notifs);
            for(let nn in this.notifs)
            {
                this.notifs[nn] = {
                    IsActive : this.notifs[nn].IsActive,
                    Kode : this.notifs[nn].Kode,
                    NamaNotif : this.notifs[nn].NamaNotif,
                    NewKode: nn
                };

            }

            var getStringJson = JSON.stringify(this.notifs);
            var replaceToBit = getStringJson.replaceAll("true", 1);
            replaceToBit = replaceToBit.replaceAll("false", 0);
            //replaceToBit = replaceToBit.replaceAll('"', "'");
            var form_data = {KodeCust : localStorage.CalonKode, json : replaceToBit};
            var resultData = await this.FuncPost(this.urlSite + "/HttpApi/InsertDetailNotif", form_data);
            if(resultData.data.search("Success") >= 0)
            {
                this.$toast.info("Berhasil Update Notifikasi"); //hasil query masih agak rewel
            }
            else
            {
                alert("Gagal Update Notifikasi, Periksa Koneksi Anda");
            }
            
        },
        async getNotif()
        {
            var form_data = {KodeCust : localStorage.CalonKode};
            var resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetAllNotif", form_data);
            this.notifs = resultData.data;
            localStorage.SettingNotif = JSON.stringify(resultData.data);
        },
        setActive(curActive)
        {
            this.menuActive = curActive;
        },
        /*
        clearAll()
        {
            localStorage.removeItem("listNotif");
            window.location.href="/HISTORYNOTIF";
        }
        */
    }
}
</script>