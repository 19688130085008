<template>
     <div class="modal fade right" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-backdrop="true">
      <div class="modal-dialog modal-dialog-centered modal-side modal-bottom-right modal-notify modal-danger" role="document">
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header bg-light my-0 py-0">
            <p class="heading text-ksm my-0 py-0">Confirm</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="text-danger">&times;</span>
            </button>
          </div>
          <!--Body-->
          <div class="modal-body">
            <div class="row">
              <div class="col-12 d-flex justify-content-center"> 
                <i class="fa-solid fa-circle-question fa-2x"></i>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <h5 class="text-ksm text-center">Are You Sure To Submit From This Page?</h5>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <p class="text-secondary text-center">
                    <small>Press OK to continue, or Cancel to stay on the current page</small>
                </p>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="modal-footer d-flex justify-content-center my-0 py-0">
            <a href="javascript:void(0)" class="btn btn-danger btn-sm" @click="returnWarning(true)">OK</a>
            <a type="button" class="btn btn-outline-danger waves-effect btn-sm" 
                data-dismiss="modal" @click="returnWarning(false)">Cancel</a>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
    setup() {
        
    },
    data(){
        return{

        }
    },
    beforeMount(){

    },
    mounted() {

    },
    methods:{
        showModal() {
            $("#exampleModal").modal("show");
        },
        hideModal() {
            $("#exampleModal").modal("hide");
        },
        returnWarning(retData)
        {
            this.$emit('name', retData)
        }    
    }
}
</script>