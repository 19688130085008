<template>
    <div v-if="mobile == false" class="row w-100 p-0 mx-0 mt-5" 
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 120px !important;' : 'margin-top: 70px !important;'">
        <div class="col-lg-4 col-md-12 col-sm-12 webMode">
		    <div class="row w-100 m-0 p-0 text-left">
                <div class="col-lg-12 col-md-12 col-sm-12  w-100 shadow p-1 mb-3 bg-white roundedAll " >
                    <div class="card-header">
                        <h5> <strong>Car Type</strong> </h5>
                    </div>
                    <ul id="getDetailClick" class="list-group list-group-flush  overflow-auto " >
                        <li v-for="c in car" :key="c.Kode" 
                            :data-kode="c.Kode" 
                            @click="filterCar(c.Keterangan, c.Kode)"
                            :class="curActive == c.Kode ? 'list-group-item filterCarType rounded active': 'list-group-item filterCarType rounded'" >
                            {{ c.Keterangan }}
                        </li>
                        <div class="pb-2"></div>
                    </ul>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mt-3 pl-0 pr-0 "> 
                    <div class="embed-responsive embed-responsive-16by9 roundedAll shadow p-1 mb-3 bg-white">
                        <iframe class="embed-responsive-item" 
                            :src="video" allowfullscreen></iframe>
                    </div>
			    </div> 
		    </div>
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12 col-12">
            <div class="row roundedAll shadow bg-white m-0 p-0 text-left">
                <div id="idKeterangan" class="col-lg-2 col-md-4 col-sm-3 mt-2">
                    <input v-if="spec.length > 0" type="hidden" id="idDetailCatalogue" :value="spec[0].IdDetailCatalogue">
                    <p class="w-100 mb-0 "><strong>Harga </strong></p>
                    <h6 >
                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="carPrice">Rp.{{ parseInt(dataClickCar.PriceList).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }}</small>
                        <small v-else-if="car.length > 0" id="carPrice">{{ car[0].PriceList }}</small>
                    </h6>
                </div>
                <div id="transmission" class="col-lg-2 col-md-2 col-sm-3 mt-2">
                    <p class="w-100 mb-0"><strong>Transmission</strong></p>
                    <h6 >
                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Transmission">{{ dataClickCar.Transmission }}</small>
                        <small v-else-if="spec.length > 0" id="Transmission">{{ spec[0].Transmission }}</small>
                    </h6>
                </div>
                <div id="engine" class="col-lg-4 col-md-6 col-sm-5 mt-2">
                    <p class="w-100 mb-0"><strong>Engine</strong></p>
                    <h6 >
                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.Engine }}</small>
                        <small v-else-if="spec.length > 0" id="Engine">{{ spec[0].Engine }}</small>
                    </h6>
                </div>
                <div id="cc" class="col-lg-1 col-md-1 col-sm-3 mt-2">
                    <p class="w-100 mb-0"><strong>Cc</strong></p>
                    <h6 >
                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Displacement">{{ dataClickCar.VehicleCC }}</small>
                        <small v-else-if="spec.length > 0" id="Displacement">{{ spec[0].Displacement }}</small>
                    </h6>
                </div>
                <div id="rpm" class="col-lg-2 col-md-3 col-sm-7 mt-2">
                    <p class="w-100 mb-0"><strong>RPM and Torque</strong></p>
                    <h6 >                        
                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="RpmAndTorque">{{ dataClickCar.Rpm + ' ' + dataClickCar.Torque }}</small>
                        <small v-else-if="spec.length > 0" 
                            id="RpmAndTorque">{{ spec[0].Rpm + ' ' + spec[0].Torque }}</small>
                    </h6>
                </div>
                <!-- carousel car -->
                <div id="Overview" :class="menuDetail == '' || menuDetail == 'Overview' ? 'col-lg-12 col-md-12 col-sm-12 m-0 p-0 roundedAll' : 'col-lg-12 col-md-12 col-sm-12 m-0 p-0 roundedAll hideContent' ">
                    <header>
                    <div id="carouselExampleIndicators" class="carousel carouselDetailCar w-100" 
                        data-interval="false">
                        <ol class="carousel-indicators">
                            <li v-for="(col, index) in colors" :key="index" 
                                data-target="#carouselExampleIndicators" 
                                :data-slide-to="index" 
                                :class="index == 0 ? 'active': ''"
                                style="height: 5%; opacity: 1; border-radius: 50%;">
                                <div style="border : solid 3px #ff2f4f; border-radius: 50%; ">
                                    <img class="colorCar d-block w-100 " style="border-radius: 50%;" 
                                        :data-image="col.ketGambar"
                                        :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+ col.ketGambar +'&size=1000'">
                                </div>
                            </li>
                        </ol>
                        <div class="carousel-inner " role="listbox">
                        
                            <div v-for="(col, index) in colors" :key="index" 
                                :class="index == 0 ? 'carousel-item active' : 'carousel-item'">
                                <img class="d-block w-100 h-100 " 
                                    :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+col.gambar+'&size=1000'" 
                                    :alt="col.gambar" 
                                    >
                            </div>
                        
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                        
                    </div>
                    </header>
                </div>
                <!-- interior -->
                <div id="Interior-Exterior" :class="menuDetail == 'Interior Exterior' ? 'col-lg-12 col-md-12 col-sm-12 p-0 m-0 roundedAll shadow p-1 mb-3 bg-white' : 'col-lg-12 col-md-12 col-sm-12 hideContent p-0 m-0 roundedAll shadow p-1 mb-3 bg-white' "  >
                    <div class="containerCarGalery "> 
                        <img v-for="(intExt, index) in splitIntExt" :key="index" 
                            :src="intExt" class="img-fluid" alt="Responsive image">       
                    </div>
                </div>
                <!-- detial spesifikasi -->
                <div id="Detail-Spesifikasi" :class="menuDetail == 'Detail Spesifikasi' ? 'col-lg-12 col-md-12 col-sm-12 p-0 m-0 roundedAll shadow p-1 mb-3 bg-white' : 'col-lg-12 col-md-12 col-sm-12 hideContent p-0 m-0 roundedAll shadow p-1 mb-3 bg-white' " >
                    <div class="containerCarGalery "> 
                        <table class="table" id="table_catalogue">
                            <tbody>
                                <tr><td>Fuel Capacity:</td>
                                    <td id="fuelCapacity">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.FuelCapacity }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].FuelCapacity }}</small>
                                        <small v-else >-</small>
                                    </td>
                                </tr>
                                
                                <tr><td>Fuel :</td>
                                    <td id="fuel">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.Fuel }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].Fuel }}</small>
                                        <small v-else >-</small>
                                    </td>
                                </tr>
                                <tr><td>Length:</td>
                                    <td id="length">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ Object.keys(this.dataClickCar).length }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].Length }}</small>
                                        <small v-else >-</small>
                                    </td>
                                </tr>
                                
                                <tr><td>Width:</td>
                                    <td id="width">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.Width }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].Width }}</small>
                                        <small v-else >-</small>
                                    </td>
                                </tr>
                                
                                <tr><td>Height:</td><td id="height">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.Height }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].Height }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                
                                <tr><td>Wheel Base:</td><td id="wheelBase">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.Wheelbase }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].Wheelbase }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                
                                <tr><td>Tread Front:</td><td id="treadFront">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.TreadFront }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].TreadFront }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                
                                <tr><td>Tread Rear:</td><td id="treadRead">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.TreadRead }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].TreadRead }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                
                                <tr><td>Front Suspension:</td><td id="frontSuspension">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.FrontSuspension }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].FrontSuspension }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                
                                <tr><td>Rear Suspension:</td><td id="rearSuspension">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.RearSuspension }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].RearSuspension }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                
                                <tr><td>Steering Gear:</td><td id="steeringGear">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.SteeringGear }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].SteeringGear }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                
                                <tr><td>Tire Size:</td><td id="tireSize">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.TireSize }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].TireSize }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                
                                <tr><td>Bore And Stroke:</td><td id="boreStroke">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.BoreStroke }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].BoreStroke }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                <tr><td>Maximal Output:</td><td id="maxOutput">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.MaxOutput }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].MaxOutput }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                <tr><td>Front Brake:</td><td id="frontBrake">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.FrontBrake }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].FrontBrake }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                <tr><td>Rear Brake:</td><td id="rearBrake">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0" id="Engine">{{ dataClickCar.RearBrake }}</small>
                                        <small v-else-if="spec.length > 0" >{{ spec[0].RearBrake }}</small>
                                        <small v-else >-</small>
                                    </td></tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- navbar button -->
                <div class="row roundedAll shadow bg-white w-100 pb-2 pt-0 px-0 m-0 ">
                    <div class="col-lg-12 col-xl-7 col-md-12 w-100 m-0 p-0">
                        <nav class="navbar navbar-expand navbar-light m-0 p-0 w-100">
                            <div class="collapse navbar-collapse m-0 p-0 w-100 " id="navbarSupportedContent">
                                <ul class="navbar-nav m-0 p-0 w-100 d-flex justify-content-center mt-2">
                                    <li @click="clickMenuDetail" :class="menuDetail == 'Overview' || menuDetail == '' ? 'nav-item menuDetail active' : 'nav-item menuDetail'">
                                        <a class="nav-link mb-0 pb-0 mt-0 pt-0 btn ">Overview</a>
                                    </li>
                                    <li @click="clickMenuDetail" :class="menuDetail == 'Interior Exterior' ? 'nav-item menuDetail active' : 'nav-item menuDetail'">
                                        <a class="nav-link mb-0 pb-0 mt-0 pt-0 btn ">Interior Exterior</a>
                                    </li> 
                                    <li @click="clickMenuDetail" :class="menuDetail == 'Detail Spesifikasi' ? 'nav-item menuDetail active' : 'nav-item menuDetail'">
                                        <a class="nav-link mb-0 pb-0 mt-0 pt-0 btn ">Detail Spesifikasi</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div class="col-lg-12 col-xl-5 col-md-12 w-100 d-flex justify-content-center">
                        <form method="post" action="#" class="w-100 p-0 w-0" >
                            <div class="form-row w-100 m-0 p-0">
                                <input name="idDetailCarCatalogue" type="hidden" id="carId" value="">
                                <input name="carName" type="hidden" id="carName" value="">
                                <input name="carPrice" type="hidden" id="carPriceSubmit" value="">
                                <input name="carColor" type="hidden" id="carColor" value="">
                                <div class="form-group mt-2 row w-100 m-0 p-0">
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-inline m-0 p-0 w-100">
                                        <a href="javascript:;" @click="downloadBrosur" class="btn btn-sm btn-block pull-right backBlue"> <small>Download Brosur</small> </a>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-inline m-0 p-0 w-100">
                                        <a type="submit" class="btn btn-sm btn-block pull-right backRed"
                                            @click="addChart()"> 
                                            <small>+ Add To Chart</small> 
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- 360 view 
       <div class="col-12">
            <vue-three-sixty
            :amount="2"
            imagePath="https://kartikasari.co.id/assets/img/360/"
            fileName="{this.data360}"
            />
        </div>-->
    </div>

    <!-- isMobile -->
    <div v-else class="col-12 w-100 m-0 p-0">
        
        <div class="row roundedTopLeft w-100 m-0 p-0 ">
            <div class="col-12 my-1 ml-0 mb-0 pb-0">
                <p class="ml-0 float-left"><strong>Car Type</strong></p>
            </div>
            <div class="col-12 w-100 m-0 p-0" > 
                <div class="row ml-0 mr-0 d-flex flex-row flex-nowrap overflow-auto mt-1 ">
                    <div v-for="(c,index) in car" :key="index" class="col-8 px-1">
                        <button
                            @click="filterCar(c.Keterangan, c.Kode)" 
                            :class="curActive == c.Kode ? 'btn btn-sm btn-ksm btn-block text-light btnList btnRounded p-0 m-0' : 'btn btn-sm btn-ksmDis btn-block text-light btnList btnRounded p-0 m-0'">
                            <small>{{ c.Keterangan }}</small>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-2 w-100 m-0 p-0">
                <div class="card roundedAll shadow mb-3 w-100" style="max-width: 40px; " >
                    <div class="card-body overflow-auto" style="max-height: 155px; " >
                        <div class="row ">
                            <div v-for="(col, index) in colors" :key="index" 
                                class="col-12 d-flex justify-content-center">
                                <img data-target="#carouselExampleControls" :data-slide-to="index" 
                                    style="max-height: 20px; " 
                                    :data-image="col.ketGambar"
                                    :data-warna="col.kodeWarna"
                                    @click="colorActive(col.ketGambar, col.kodeWarna)"
                                    class="colorCar roundedAll mb-2 border border-dark ml-0 mr-0 p-0" 
                                    :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+col.ketGambar+'&size=500'" 
                                    :alt="col.ketGambar">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- car image -->
            <div class="col-10 w-100 m-0 p-0">
                <div id="carouselExampleControls" class="carousel background-danger" >
                    <div class="carousel-inner">
                        <div v-for="(col, index) in colors" :key="index" 
                            :class="index == 0 ? 'carousel-item active' : 'carousel-item'"
                            style="height: 150px;">
                            <img class="d-block w-100" 
                                :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+col.gambar+'&size=500'"
                            :alt="'Slide ' + index"> 
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- pricelist and like -->
            <div class="col-12 w-100 pb-3">
                <p class="d-inline">
                    <strong v-if="Object.keys(this.dataClickCar).length > 0" id="carPrice">Rp.{{ parseInt(dataClickCar.PriceList).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }}</strong>
                    <strong v-else-if="car.length > 0" id="carPrice">{{ car[0].PriceList }}</strong>
                    <strong v-else id="carPrice">0</strong> 
                </p>
                <a v-if="curLike.length > 0" @click="actionLike('0')">
                    <i class="d-inline fas fa-heart fa-1x float-right mx-2"></i>
                </a>
                <a v-else @click="actionLike('1')">
                    <i class="d-inline far fa-heart fa-1x float-right mx-2"></i>
                </a>
                <button @click="addChart" class="btn btn-sm btn-ksm my-0 py-0 float-right mx-2">
                    <i class="fa-solid fa-cart-plus mr-1"></i>Add Chart
                </button>
                <button @click="funcBandingkan" class="btn btn-sm btn-ksm-red my-0 py-0 float-right mx-2">
                    <i class="fa-solid fa-compress mr-1"></i>Compare
                </button>
                
            </div>

            <!-- Coupon Point -->
            <div v-if="curPoint.length > 0" class="col-12 my-3 py-3 mx-0 w-100" style="background: #dedede;">
                <div class="coupon bg-white rounded d-flex justify-content-between ">
                    <div class="kiri ">
                        <div class="icon-container ">
                            <div class="icon-container_box ">
                                <img src="@/assets/images/racesuit.png" 
                                    height="65" alt="-" class="align-middle ml-2"
                                    style="position: absolute; top: 20%;" />
                            </div>
                        </div>
                    </div>
                    <div class="tengah d-flex w-100 justify-content-center">
                        <div class="text-center">
                            <span class="badge badge-success ">Valid</span>
                            <p class="lead my-0 py-0"><small>Dapatkan Point</small></p>
                            <p class="text-muted my-0 py-0">
                                <small>
                                {{ parseInt(curPoint[0].Nominal).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }}
                                </small>
                            </p>
                        </div>
                    </div>
                    <div class="kanan ">
                        <div class="info d-flex align-items-center">
                            <div class="w-100">
                                <div class="block"> 
                                    <span class="time font-weight-light">
                                        <small>
                                            <p class="p-0 m-0 " style="position: absolute; top: 25%; left: 10%">
                                                <small>{{ curPoint[0].TglAkhir }}</small>
                                            </p> 
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div> 
        
        <!-- download button -->
        <div class="col-12 w-100 mx-0 px-0">
            <div class="row ml-0 mr-0 d-flex flex-row flex-nowrap overflow-auto mt-1">
                <div  class="col-5 px-1 ">
                    <button 
                        @click="clickMenuDetailBtn" :class="menuDetail == 'Detail Spesifikasi' || menuDetail == '' ? ' btn btn-sm btn-ksm btn-block text-light btnList btnRounded p-0 m-0' 
                            : 'btn btn-sm btn-ksmDis btn-block text-light btnList btnRounded p-0 m-0'">
                        Detail Spesifikasi
                    </button>
                </div>
                <div  class="col-5 px-1 ">
                    <button 
                        @click="clickMenuDetailBtn" :class="menuDetail == 'Interior Exterior' ? ' btn btn-sm btn-ksm btn-block text-light btnList btnRounded p-0 m-0' 
                            : 'btn btn-sm btn-ksmDis btn-block text-light btnList btnRounded p-0 m-0'">
                        Interior Exterior
                    </button>
                </div>
                
                <div  class="col-5 px-1 ">
                    <button 
                        @click="downloadBrosur" :class="menuDetail == 'Download Brosur' ? ' btn btn-sm btn-ksm btn-block text-light btnList btnRounded p-0 m-0' 
                            : 'btn btn-sm btn-ksmDis btn-block text-light btnList btnRounded p-0 m-0'">
                        Download Brosur
                    </button>
                </div>
                
                <div v-if="curBook.length > 0" class="col-5 px-1 ">
                    <button @click="showManualBook"
                         :class="menuDetail == 'Download Manual Book' ? ' btn btn-sm btn-ksm btn-block text-light btnList btnRounded p-0 m-0' 
                            : 'btn btn-sm btn-ksmDis btn-block text-light btnList btnRounded p-0 m-0'">
                        Download Manual Book
                    </button>
                </div>
                
            </div>
        </div>
        
        <!-- Interior Exterior -->
        <span v-if="menuDetail == 'Interior Exterior'">
            <div class="col-12 mt-2 ml-0">
                <p class="ml-0 text-left"><strong >Exterior & Interior </strong></p>
            </div>
            <!-- interior -->
            <div id="Interior-Exterior" class = "roundedAll shadow p-1 mb-3 bg-white"  >
                <div v-if="splitIntExt.length > 0" class="containerCarGalery "> 
                    <img v-for="(intExt, index) in splitIntExt" :key="index" 
                        :src="intExt" class="img-fluid" alt="Responsive image">       
                </div>
            </div>
        </span>

        <!-- key spec -->
        <span v-if="menuDetail == 'Detail Spesifikasi' || menuDetail == ''">
            <div class="col-12 mt-2 ml-0">
                <p class="ml-0 text-left"><strong >Key Specs</strong></p>
            </div>
            <div class="col-12 pl-0 pr-0 m-0">
                <div class="d-flex flex-row flex-nowrap overflow-auto pb-2" style="overflow: hidden;">	
                    <div class="card ml-1 mr-2 shadow p-1 mb-3 bg-white roundedAll" style="min-width: 250px;" >
                        <div class="card-body text-center d-flex flex-column">
                            <div class="row h-100">
                                <div class="col-12 pl-0 pr-0 pt-0 pb-0 d-flex justify-content-center">
                                    <img  style="max-height: 50px;" :src="this.urlSite + '/assets/img/engine.png'">
                                </div>
                                <div class="col-12 pl-0 pr-0 pt-0 pb-0 d-flex justify-content-center mt-auto">
                                    <p class="text-center" id="Engine">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.Engine }} </small> 
                                        <small v-else-if="carMobile.length > 0" >{{carMobile[0].Engine}}</small>
                                        <small v-else> - </small> 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card ml-2 mr-2 shadow p-1 mb-3 bg-white roundedAll " style="min-width: 200px;" >
                        <div class="card-body">
                            <div class="row h-100">
                                <div class="col-12 pl-0 pr-0 pt-0 pb-0 d-flex justify-content-center">
                                    <img  style="max-height: 50px;" :src="this.urlSite + '/assets/img/speedometer.png'">
                                </div>
                                <div class="col-12 pl-0 pr-0 pt-0 pb-0 d-flex justify-content-center">
                                    <p class="text-center" id="RpmAndTorque">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.Rpm + ' && ' + dataClickCar.Torque }} </small> 
                                        <small v-else-if="carMobile.length > 0" >{{carMobile[0].Rpm + ' && ' + carMobile[0].Torque}}</small>
                                        <small v-else> - </small> 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card ml-2 mr-2 shadow p-1 mb-3 bg-white roundedAll" style="min-width: 150px;" >
                        <div class="card-body">
                            <div class="row h-100">
                                <div class="col-12 pl-0 pr-0 pt-0 pb-0  d-flex justify-content-center">
                                    <img  style="max-height: 50px;" :src="this.urlSite + '/assets/img/pistons.png'">
                                </div>
                                <div class="col-12 pl-0 pr-0 pt-0 pb-0  d-flex justify-content-center">
                                    <p class="text-center" id="Displacement">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.VehicleCC }} </small> 
                                        <small v-else-if="carMobile.length > 0" >{{carMobile[0].Displacement}}</small>
                                        <small v-else> - </small> 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card ml-2 mr-2 shadow p-1 mb-3 bg-white roundedAll" style="min-width: 150px;" >
                        <div class="card-body">
                            <div class="row h-100">
                                <div class="col-12 pl-0 pr-0 pt-0 pb-0  d-flex justify-content-center">
                                    <img  style="max-height: 50px;" :src="this.urlSite + '/assets/img/transmission.png'">
                                </div>
                                <div class="col-12 pl-0 pr-0 pt-0 pb-0  d-flex justify-content-center">
                                    <p class="text-center" id="Transmission">
                                        <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.Transmission }} </small> 
                                        <small v-else-if="carMobile.length > 0" >{{carMobile[0].Transmission}}</small>
                                        <small v-else> - </small> 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            <!-- spesifikasi detail -->
            <div class="col-12 mt-2 shadow  mb-3 bg-white roundedAll pb-5">
                <p class="ml-2 mt-2 text-left"><strong>Spesification</strong></p>
                <div id="Detail-Spesifikasi" class="col-12 containerCarGalery pr-0 pl-0">
                    <table class="table text-left" id="table_catalogue">
                        <tbody>
                            <tr><td>Fuel Capacity:</td>
                                <td id="fuelCapacity" >
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.FuelCapacity }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].FuelCapacity}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Fuel :</td>
                                <td id="fuel">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.Fuel }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].Fuel}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Length:</td>
                                <td id="length">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ Object.keys(this.dataClickCar).Length }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].Length}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Width:</td>
                                <td id="width">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.Width }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].Width}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Height:</td>
                                <td id="height">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.Height }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].Height}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                        
                            <tr><td>Wheel Base:</td>
                                <td id="wheelBase">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.Wheelbase }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].Wheelbase}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Tread Front:</td>
                                <td id="treadFront">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.TreadFront }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].TreadFront}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Tread Rear:</td>
                                <td id="treadRear">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.TreadRead }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].TreadRead}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Front Suspension:</td >
                                <td id="frontSuspension">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.FrontSuspension }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].FrontSuspension}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Rear Suspension:</td>
                                <td id="rearSuspension">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.RearSuspension }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].RearSuspension}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Steering Gear:</td>
                                <td id="steeringGear">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.SteeringGear }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].SteeringGear}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Tire Size:</td>
                                <td id="tiresSize">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.TireSize }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].TireSize}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Bore And Stroke:</td>
                                <td id="boreStroke">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.BoreStroke }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].BoreStroke}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Maximal Output:</td>
                                <td id="maxOutput">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.MaxOutput }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].MaxOutput}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Front Brake:</td>
                                <td id="frontBrake">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.FrontBrake }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].FrontBrake}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                            
                            <tr><td>Rear Brake:</td>
                                <td id="rearBrake">
                                    <small v-if="Object.keys(this.dataClickCar).length > 0"> {{ dataClickCar.RearBrake }} </small> 
                                    <small v-else-if="carMobile.length > 0" >{{carMobile[0].RearBrake}}</small>
                                    <small v-else> - </small>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </span>
    </div>
    
    <div  class="col-12 w-100 m-0 p-0"
         style="background-image: url('https://admin.kartikasari.co.id/assets/img/360/background360.jpg');
                    background-size : 100% auto;
                    background-repeat: no-repeat;
                    background-position: center; 
                    height: 300px;"
                    >
        <div v-if="isMobile() == false" class="360viewerCar mx-auto d-block m-0 p-0 w-100" id="threesixty"
            style="background-size : 35% auto; 
                    background-repeat: no-repeat;
                    background-position: top;"
                    >
        </div>
        <div v-if="isMobile() == true" class="360viewerCar mx-auto d-block m-0 p-0 w-100" id="threesixty"
            style="background-size : 100% auto; 
                    background-repeat: no-repeat;
                    background-position: top;"
                    >
        </div>
    </div>
    <div v-if="isMobile() == true" style="height: 50px;" ></div>

    

<!-- Modal Download Manual Book -->
<div class="modal fade" id="modalManualBook" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header my-0 py-0">
        <p class="modal-title " id="exampleModalLabel">Download Manual Book</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="list-group">
            <li v-for="(file, index) in curFile" :key="index" class="list-group-item my-1 py-1">
                <a :href="this.urlSite + '/assets/ManualBook/' + file.replaceAll(' ', '_')" class="my-0 py-0" target="_blank">{{file}}</a>
            </li>
        </ul>
        
      </div>
      
    </div>
  </div>
</div>

</template>

<script>

import { nytMixin } from "../assets/js/myMixin.js";
import $ from 'jquery';
import ThreeSixty from '@mladenilic/threesixty.js';
export default {
    components: {
        ThreeSixty
    },
    mixins: [nytMixin],
    data() {
        return {
            car: [],
            video: '',
            curActive : '',
            menuDetail: '',
            spec: [],
            colors: [],
            parameter: this.$route.params.param,
            splitIntExt: [],
            getUrlSite : '',
            dataClickCar: [],
            data360 : [],
            mobile: false,
            carMobile: [],
            carLike: [],
            curLike: [],
            carColorActive: '',
            curScreen: screen.width,
            pointUnits: [],
            colorCodeActive : '',
            curPoint: [],
            manualBooks: [],
            curBook: [],
            curFile: [],
        }
    },
    beforeMount(){
        this.getPointUnit();

        this.getDetailCar(this.parameter);
        this.mobile = this.isMobile();
        //this.getLikeCar();

        //this.GetPosition();

    },
    mounted(){
        //console.log(localStorage);
        this.threeSixty();
        
    },
    updated() {
        
    },
    created() {
        this.getUrlSite = this.urlSite;
        
    },
    methods:{
        showManualBook() {
            if(this.curBook.length > 0)
            {
                this.curFile = this.curBook[0].NamaFile.slice(0, -3).split(" && ");
            }
            $("#modalManualBook").modal("show");
        },
        async getPointUnit()
        {
            var form_data = {};   
            const resultData = await this.FuncGet("GetPointUnit", form_data);
            this.pointUnits = resultData.data;
            
        },
        filterManualBook(unit = null)
        {
            this.curBook = this.manualBooks.filter(x => x.Kode == unit);
        },
        filterPoint(unit = null, color = null)
        {
            this.curPoint = this.pointUnits.filter(x => x.Unit == unit).filter(y => y.Warna == color);
        },
        downloadBrosur: function()
        {
            var getLinkBrosur = this.spec.filter(x => x.Kode == this.curActive);
            var replaceBrosur =  getLinkBrosur[0].LinkBrosur.replace("img/","");
            console.log(replaceBrosur);
            replaceBrosur = this.urlSite + replaceBrosur.replace("https://kartikasari.co.id/","");
            window.open(replaceBrosur, '_blank').focus();

        },
        async getDetailCar(param) {
            const dataCar = await this.GetDetailCar(param);
            this.car = dataCar.data.getDetail;
            this.video = dataCar.data.videoCar;
            this.spec = dataCar.data.detailSpec;
            this.colors = dataCar.data.colorCar;
            this.manualBooks = dataCar.data.manualBooks;
            if(dataCar.data.detailSpec[0].LinkIntExt != "-")
            {
                this.splitIntExt = dataCar.data.detailSpec[0].LinkIntExt.split("&&");
            }
            this.curActive = this.car[0].Kode;
            this.carMobile = dataCar.data.detailSpec.filter(x => x.Aktif == "1").filter(x => x.CustOpenOrder == "1");
            
            if(this.colors) 
            { 
                this.carColorActive = this.colors[0].ketGambar; 
                this.colorCodeActive = this.colors[0].kodeWarna;
            } 

            this.getLikeCar();
            
            this.filterPoint(this.curActive, this.colorCodeActive);
            this.filterManualBook(this.curActive);

            this.splitIntExt = this.splitIntExt.filter(element => {
                return element !== ' ';
            });
        },
        async filterCar(event, activeCode){
            this.curActive = activeCode;     
            var form_data = {};   
			form_data['carType'] = event; 
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/DetailCarJs", form_data);
            this.dataClickCar = resultData.data.result;
            
            console.log("data click car", Object.keys(this.dataClickCar).length, this.dataClickCar);

            this.getLikeCar(this.curActive);

            this.filterPoint(this.curActive, this.colorCodeActive);
            this.filterManualBook(this.curActive);

        },
        async threeSixty(){    
            var form_data = {};   
			form_data['idCatalogue'] = this.parameter; 
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetImage360", form_data);
            if(resultData.data.Link360 != null)
            {
                var raw360 = resultData.data.Link360.split("&&");
                var http360 = [];
                var fuckingVar = this.urlSite;
                raw360.forEach(function(value, key)
                {
                    value = value.replace(" ", "");
                    http360.push(fuckingVar + "/assets/img/360/"+value);
                });
                this.data360 = http360;
                
                if(this.data360.length > 0 && this.isMobile() == false)
                {
                   
                    const threesixtyCar = new ThreeSixty(document.getElementById('threesixty'), {
                        image: this.data360,
                        speed: 100,
                        count: this.data360.length,
                        perRow: 1,
                        width: 500,
                        height: 500, 
                    });

                    //threesixtyCar.play();
                            
                }
                
                if(this.data360.length > 0 && this.isMobile() == true)
                {
                    
                    const threesixtyCar = new ThreeSixty(document.getElementById('threesixty'), {
                        image: this.data360,
                        speed: 100,
                        count: this.data360.length,
                        perRow: 1,
                        width: 500, 
                        height: 250, 
                    });

                    //threesixtyCar.play();
                            
                }

                
            }
        },
        clickMenuDetailBtn(event)
        {
            var filterDetail = event.target.innerText;
            this.menuDetail = filterDetail;
        },
        clickMenuDetail: function(event)
        {
            //var kodeData = event.target.dataset.kode;
            var filterDetail = event.target.text;
            this.menuDetail = filterDetail;

        },
        async addChart()
        { 
            if(localStorage.Kode)
            {
                var resDetail = this.spec.filter(x => x.Kode == this.curActive);
                var form_data = {};   
                form_data['idDetailCarCatalogue'] = resDetail[0].IdDetailCatalogue; 
                form_data['carName'] = resDetail[0].Keterangan; 
                form_data['carPrice'] = parseInt(resDetail[0].PriceList); 
                form_data['carColor'] = this.carColorActive; 
                form_data['code'] = localStorage.Kode; 
                form_data['kodePoint'] = '';

                if(this.curPoint.length > 0)
                {
                    form_data['kodePoint'] = this.curPoint[0].Kode;
                }

                const resultAddChart = await this.FuncPost(this.urlSite + "/HttpApi/AddToChartCar", form_data);
                if(resultAddChart.data.search("Success") >= 0)
                {
                    localStorage.context="Car List";
                    window.location.href="/ORDERSTATUS";
                }
            }
            else
            {
                window.location.href="/LoginUser";
            }
        },
       
        async getLikeCar(param = null)
        {
            if(localStorage.Kode)
            {
                var kodeFilter = null;
                if(param == null)
                {
                    kodeFilter = this.car[0].Kode;
                }
                else
                {
                    kodeFilter = param;
                }

                var form_data = {};   
                form_data['jenis'] = this.parameter; 
                form_data['idCust'] = localStorage.Kode; 
                const resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetLikeCar", form_data);
                this.carLike = resultData.data;
                this.curLike = this.carLike.filter(x => x.Kode == kodeFilter).filter(x => x.Status == "1");
            }
        },
        async actionLike(status)
        {
            if(localStorage.Kode)
            {
                var form_data = {};   
                form_data['Jenis'] = "car";
                form_data['IdJenis'] = this.curActive; 
                form_data['IdCust'] = localStorage.Kode; 
                form_data['StatusPhone'] = '1'; 
                form_data['Status'] = status; 
                const resultData = await this.FuncPost(this.urlSite + "/HttpApi/InsertUpdateLike", form_data);
                
                var objIndex = this.carLike.findIndex((obj => obj.Kode == this.curActive));
                if(objIndex >= 0)
                {
                    this.carLike[objIndex].Status = status;
                    this.curLike = this.carLike.filter(x => x.Kode == this.curActive).filter(x => x.Status == "1");
                }
                else
                {
                   this.getLikeCar(this.curActive);
                }
                
            }
            else
            {
                window.location.href="/LoginUser";
            }
        },
        funcBandingkan: function(){
            this.insertUnitCompare(this.parameter);
            window.location.href="/CompareCar";
        },
        colorActive: function(ketGambar, kodeWarna){
            this.carColorActive = ketGambar;
            this.colorCodeActive = kodeWarna;
            this.filterPoint(this.curActive, this.colorCodeActive);
        }
        
    }
}
</script>
<style>
.coupon .kanan {
    border-left: 1px dashed #ddd;
    width: 40% !important;
    position:relative;
}

.coupon .kanan .info::after, .coupon .kanan .info::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #dedede;
    border-radius: 100%;
}
.coupon .kanan .info::before {
    top: -10px;
    left: -10px;
}

.coupon .kanan .info::after {
    bottom: -10px;
    left: -10px;
}
.coupon .time {
    font-size: 1.6rem;
}
</style>