<template>
    <div class="row " style="margin-top: 15px;"></div>
    <div v-if="units.length > 0" class="row w-100 m-0 p-0 ">
        <div v-for="(unit, index) in units" :key="index" class="col-12 w-100 m-0 p-0 " >
            <div class="card w-100 m-0 p-0" @click="showDEC(unit.NoRangka)">
                <div class="card-body m-1 p-0">
                    <div class="row m-0 p-0">
                        <div class="col-10 text-left">
                            <!--<label><strong>{{unit.Name}}</strong> ({{unit.NoPol}})</label><br>-->
                            <label class="my-0 py-0"><strong>{{unit.NamaKendaraan}}</strong></label><br>
                            <label class="my-0 py-0"><small>Nopol: {{unit.NoPol}}</small></label> <br>
                            <label class="my-0 py-0"><small>NoRangka: {{unit.NoRangka}}</small></label>
                        </div>
                        <div class="col-2">
                            <a v-if="unit.ReleasedByOwner == '0'" href="javascript:void(0)" @click="deleteUnit(unit.NoRangka, unit.NoPol)">
                                <i class="fa-solid fa-trash-can align-middle float-right"></i>
                            </a>
                            <label v-else>(Deleted)</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="row w-100 m-0 p-0 d-flex justify-content-center">
        <p ><em class="">Tidak Ada Unit</em></p>
    </div>
    <div style="min-height: 75px;"></div>

<!-- Modal Detail DEC -->
<div class="modal fade" id="modalDec" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content roundedCarousel">
            <div class="modal-head"><label><strong>{{detailDec.Keterangan}}</strong></label></div>
            <div class="modal-body ">
                <div class="row">
                    <div class="col-12">
                        <table class="table">
                            <tbody>
                                <tr> <td>NoRangka</td> <td>{{detailDec.NoRangka}}</td> </tr>
                                <tr> <td>Penerima</td> <td>{{detailDec.Penerima}}</td> </tr>
                                <tr> <td>Phone Penerima</td> <td>{{detailDec.PhonePenerima}}</td> </tr>
                                <tr> <td>Driver</td> <td>{{detailDec.Driver}}</td> </tr>
                                <tr> <td>Ketepatan Waktu</td> <td>{{detailDec.Waktu}}</td> </tr>
                                <tr> <td>Kebersihan Unit</td> <td>{{detailDec.Kebersihan}}</td> </tr>
                                <tr> <td>Etika Driver</td> <td>{{detailDec.Etika}}</td> </tr>
                                <tr> <td>Penjelasan Feature</td> <td>{{detailDec.Feature}}</td> </tr>
                                <tr> <td>Penjelasan Garansi</td> <td>{{detailDec.Garansi}}</td> </tr>
                                <tr> <td>Penjelasan Service Berkala</td> <td>{{detailDec.ServiceBerkala}}</td> </tr>
                                <tr> <td>Ttd</td> <td><img style="height: 50px;" :src="detailDec.Ttd" alt="ttd" /></td> </tr>
                                <tr> <td>Photo</td> <td><img style="height: 50px;" :src="detailDec.Photo" alt="photo" /></td> </tr>
                            </tbody>
                        </table>
                        <label class="text-danger"><small>
                        *: Sangat Buruk, 
                        **: Buruk, 
                        ***: Sedang, 
                        ****: Bagus, 
                        *****: Sangat Bagus</small></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import $ from 'jquery';
import { nytMixin } from "../assets/js/myMixin.js";
import { loadingController } from '@ionic/vue';
export default {
    mixins: [nytMixin],
    setup() {
        
    },
    data(){
        return{
            units: [],
            detailDec: [],
        }
    },
    beforeMount(){
        this.GetUnitsCust();
    },
    methods:{
        async showDEC(noRangka)
        {
            var form_data = {};   
            form_data['NoRangka'] = noRangka;
            const resultData = await this.FuncPost(this.urlSite + '/HttpApi/GetDecByNoka', form_data);
            if(resultData.data != null)
            {
                this.detailDec = resultData.data;
                $("#modalDec").modal("show");
            }
            else
            {
                alert("Data Kosong");
            }
        },
        async GetUnitsCust()
        {
            /*
            var form_data = {};
            form_data['token'] = this.token;
            form_data['kode'] = 'GETCUSTUNIT'; 
            form_data['params[0]'] = '';
            form_data['params[1]'] = localStorage.Kode; 
            const resultData = await this.FuncPostSecond(this.urlApiLoad, form_data);
            console.log(resultData);
            this.units = resultData.data.result;
            */
            const loading = await loadingController.create({
                message: 'Please Wait...',
                spinner: 'circles'
            });
            
            loading.present();
            if(localStorage.Kode)
            {
                var form_data = {};   
                form_data['codeCust'] = localStorage.Kode;
                const resultData = await this.FuncPost(this.urlSite + '/HttpApi/GetUnitCustomer', form_data);
                this.units = resultData.data;
                
            }
            loading.dismiss();
        },
        async deleteUnit(NoRangka, NoPol)
        {
            var form_data = {};
            form_data['NoRangka'] = NoRangka;
            form_data['NoPol'] = NoPol; 
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/DeleteUnitCust", form_data)
            if(resultData.data.search("Success") >= 0)
            {
                window.location.href="/UNITS";
            }
            else
            {
                alert("Gagal Delete Unit");
            }

        }
    }
}
</script>