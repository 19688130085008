<template>
  <div :class="this.isMobile() ? 'mt-1': 'mt-5' " 
    class="row w-100 p-0 mx-0" 
    :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 110px !important;' : ''"> 
    
      <div :class="this.isMobile() ? 'd-flex justify-content-left': 'd-flex justify-content-center'" 
            class="col-12 w-100 mt-3 mx-0 overflow-auto" > 
          <button class="btn btn-sm mx-2 btn-light filterVehicle" v-on:click="searchValue = 'all'" 
                    :class="{ active: searchValue == 'all' }">All</button>
          <button class="btn btn-sm mx-2 btn-light filterVehicle " v-for="fil in filters" :key="fil" 
                    v-on:click="searchValue = fil" 
                    :class="{ active: searchValue == fil }">{{ fil }}
          </button>
      </div>
      <div class="col-12 w-100 my-0 mx-0 p-0" >
        <section id="portfolio" class="portfolio section-bg w-100 m-1 p-1">
          <div class="row portfolio-container w-100 m-0 p-0">
            
            <div v-for="ctg in filterCar" :key="ctg.IdCatalogue"
              v-bind:class="'col-6 col-xl-3 col-md-3 col-sm-4 portfolio-item filter w-100 px-1 mx-1' + ctg.Model">
              <div class="portfolio-wrap ">
                <img v-bind:src="this.urlSite+ctg.LinkImage" class="img-fluid" alt="">
                <div v-if="linkLogin == '/BROCHURE'" class="portfolio-info"> 
                  <h4 style="margin-top:5px;">{{ctg.Nama}}</h4>
                  <a :href="ctg.LinkBrosur" target="_blank" 
                      class="Download"  title="Download Brosur">
                      <i class="fa-solid fa-download"></i>
                  </a>
                </div>
                <div v-else class="portfolio-info">
                  <p v-if="this.isMobile()" style="margin-top:5px;">{{ctg.Nama + ' (' + ctg.Model + ')' }}</p>
                  <h5 v-else style="margin-top:5px;" class="text-white">{{ctg.Nama + ' (' + ctg.Model + ')' }}</h5>
                  <p v-if="this.isMobile()"><small>Starting At: <br>IDR.  {{ parseInt(ctg.PriceList).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }},-</small></p>
                  <h5 v-else class="text-white"><small>Starting At:</small> <br>IDR.  {{ parseInt(ctg.PriceList).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }},-</h5>
                  <div class="portfolio-links">
                    <a v-bind:href="this.urlSite+ctg.LinkImage" 
                      data-gall="portfolioGallery" class="venobox" 
                      v-bind:title="ctg.Nama"><i class="fa-solid fa-eye"></i></a>
                    <a v-bind:href="'/DetailCar/'+ctg.Nama" title="More Details">
                      <i class="fa-solid fa-link"></i></a>
                    <a href="javascript:void(0)" class="Bandingkan"  @click="funcBandingkan(ctg.Nama)"
                      title="Bandingkan"><i class="fa-solid fa-weight-scale"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    setup() {
        
    },
    data() {
        return{
            catalogues: [],
            searchValue: 'all',
            filters:[],
            linkLogin: '',
            curScreen: screen.width,
        }
    },
    beforeMount(){
        this.linkLogin = window.location.pathname;
        this.GetCarCatalogue();
    },
    methods: {
        async GetCarCatalogue(){
            const resCatalogue = await this.HomeCarCatalogue();
            this.catalogues = resCatalogue.data.result;
            this.filters = resCatalogue.data.filters;
        },
        funcBandingkan: function(type)
        {
          this.insertUnitCompare(type);
          window.location.href="/CompareCar";
          //this.$router.push({ name: '/CompareCar', params: this.dataUnitCompare });
        },
    },
    computed: {
      filterCar() {
        let filCatalogues = this.catalogues;
        if(this.searchValue == 'all')
        {
          filCatalogues = this.catalogues;
        }
        else
        {
            filCatalogues = this.catalogues.filter(x => x.Model == this.searchValue);
        }
        return filCatalogues
      }
    }

}
</script>