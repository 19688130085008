<template >
  <div v-if="thisMobile == false" class="home " >
    <div v-if="curScreen <= 991" style="margin-top: 100px;"></div>
    <div id="carouselExampleIndicators" class="carousel slide w-100 p-0 m-0" data-interval="false">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators"
            v-for="(item, index) in carousels" :key="item.IdPromo" 
            v-bind:data-slide-to="index" v-bind:class="index ? '' : 'active'" >
          </li>
        </ol>
        <div class="carousel-inner mt-5" role="listbox"> 
          <div v-for="(item, index) in carousels" :key="item.IdPromo"  
              v-bind:class="index ? 'carousel-item' : 'carousel-item active'" 
              :style="curScreen > 991 && item.Nama.search('Video') >= 0 ? 'height: 650px;': 'height: 425px;'">
            
            <span v-if="item.Nama.search('Video') >= 0" class="w-100 " v-html="item.LinkImage"> </span>
            <a v-else href="javascript:void(0)">
              <img class="d-block w-100" 
                v-bind:src="this.urlSite + '/assets/img/uploadImage/' + item.LinkImage" 
                alt="Image Data">
            </a> 

          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
        
    </div>

    <!-- car catalogue -->
    <section id="portfolio" class="portfolio section-bg">
      <div class="container" data-aos="fade-up" data-aos-delay="100">
        <div class="section-title">
          <h2><i class="icofont-brand-toyota"></i> Toyota Vehicles</h2>
        </div>

        <div class="row"> 
          <div class="col-lg-12 w-100 d-flex justify-content-center m-2">
            <button class="btn btn-sm mx-2 btn-light filterVehicle" v-on:click="searchValue = 'all'" 
                  :class="{ active: searchValue == 'all' }">All</button>
            <button class="btn btn-sm mx-2 btn-light filterVehicle" v-for="fil in filters" :key="fil" 
                  v-on:click="searchValue = fil" 
                  :class="{ active: searchValue == fil }">{{ fil }}
            </button>
          </div>
        </div>
        
        <div class="row portfolio-container">
          <div v-for="ctg in filterCar" :key="ctg.IdCatalogue"
            v-bind:class="'col-lg-4 col-md-6 portfolio-item filter' + ctg.Model">
            <div class="portfolio-wrap">
              <img v-bind:src="this.urlSite + ctg.LinkImage" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4 style="margin-top:5px;">{{ctg.Nama}}</h4>
                <p>Starting At: <br>IDR.  {{ parseInt(ctg.PriceList).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }},-</p>
                <div class="portfolio-links">
                  <a v-bind:href="this.urlSite + ctg.LinkImage" data-gall="portfolioGallery" class="venobox" v-bind:title="ctg.Nama"><i class="fa-solid fa-eye"></i></a>
                  <a v-bind:href="'/DetailCar/'+ctg.Nama" title="More Details"><i class="fa-solid fa-link"></i></a>
                  <a href="#" class="Bandingkan"  @click="funcBandingkan(ctg.Nama)"
                    title="Bandingkan"><i class="fa-solid fa-weight-scale"></i></a>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>

    <!-- News -->
    <section id="about" class="about">
      <div class="container">
        <div class="col-lg-12 d-flex flex-column justify-content-center about-content" 
          style="background-image: url('https://admin.kartikasari.co.id/assets/img/1t.jpg');">
          <div class="section-title" data-aos="fade-up" data-aos-delay="100">
            <h2>About Us</h2>
            <h3><b>We are Best Toyota Dealer in East Java</b></h3>
            <p>
              &nbsp;
            </p>
            <p>
              <b>PT. KARTIKA SARI MULIA. Merupakan salah satu dealer resmi TOYOTA di kota Malang yang telah berdiri sejak Tahun 1994, selain showroom juga terdapat bengkel yang luas untuk menampung kendaraan-kendaraan yang hendak servis ataupun servis berkala. Ditunjang dengan peralatan dan sparepart yang lengkap serta mekanik - mekanik yang handal sehingga pengerjaan servis dan servis berkala dapat berjalan dengan cepat dan tepat.</b>
            </p>
            <p>
              <b>Di Kartika Sari selain menyediakan Showroom Toyota untuk pembelian unit Toyota baru dan Bengkel yang melayani Service mobil Toyota beserta dengan Sparepart asli Toyota, kami juga menyediakan Jasa KSC (Kartika Sari Care) yang mana fungsinya untuk melakukan service di luar bengkel (Service di Rumah/Kantor anda). Selain KSC kami juga ada Night Service yang mana kami adakan setiap hari Jumat, fungsinya agar para pemilik Toyota yang sibuk bekerja dapat melakukan service setelah pulang kerja dan kendaraan Toyota anda siap untuk ber Weekend tanpa ada gangguan.</b>
            </p>
            <p>
              <b>Untuk Kenyamanan customer kami juga menyediakan ruang tunggu yang luas dan juga Cafe yang mana kita telah bekerja sama dengan Golden Heritage. Kami juga ada beberapa fasilitas yang lain, yaitu Body Repair baik asuransi maupun non asuransi, Auto Detailing (Untuk Mempercantik kendaraan Toyota anda), Remap Ecu atau proses kalibrasi ulang atau perubahan data ecu kendaraan Toyota anda yang berguna untuk meningkatkan HP (Horse Power) Torsi dan efensiensi bahan bakar & Mobil Towing untuk membantu menderek mobil Toyota anda yang mengalami masalah di perjalanan.</b>
            </p>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
				<div class="col-lg-6 video-box">
					<img :src="this.urlSite + '/assets/img/about2.jpg'" class="img-fluid" alt="">
					<a href="https://youtu.be/VrwzF7hJkJY" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
				</div>
				<div class="col-lg-6 d-flex flex-column justify-content-center about-content" 
          style="background-image: url('https://admin.kartikasari.co.id/assets/img/2t.jpg');">
					<div class="icon-box" data-aos="fade-up" data-aos-delay="200">
						<h4 class="title"><a style="cursor: pointer;user-select: none;">Visi - Toyota Kartika Sari Malang</a></h4>
						<p>
							&nbsp;
						</p>
						<p>
							<i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;<b>Menjadi salah satu perusahaan besar dan unggul dibidang jasa penjualan unit dan service.</b>
						</p>
						<br></div>
					<div class="icon-box" data-aos="fade-up" data-aos-delay="300">
						<h4 class="title"><a style="cursor: pointer;user-select: none;">Misi - Toyota Kartika Sari Malang</a></h4>
						<p>
							&nbsp;
						</p>
						<p>
							<i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;<b>Memenuhi kebutuhan pelanggan akan unit Toyota dan Service.</b>
						</p>
						<p>
							<i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;<b>Memberikan pelayanan terbaik kepada customer dengan cepat dan tepat.</b>
						</p>
						<p>
							<i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;<b>Memberikan pelayanan berkualitas dengan harga bersaing.</b>
						</p>
						<br></div>
				</div>
			</div>
    </section>

    <!-- Keunggulan -->
    <section class="counts section-bg">
			<div class="container">				
				<div class="row">
					<div class="col-lg-3 col-md-6 text-center" data-aos="fade-up">
						<div class="count-box">
							<img :src="this.urlSite + '/assets/ico/HargaTerbaruTerbaik.png'" class="img-fluid w-75 m-0 p-0" alt="">
							<p>
								<b>Harga Terbaru & Terbaik</b>
							</p>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay="200">
						<div class="count-box">
							<img :src="this.urlSite + '/assets/ico/KreditMurahCepat.png'" class="img-fluid w-75 m-0 p-0" alt="">
							<p>
								<b>Kredit Murah & Cepat</b>
							</p>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay="400">
						<div class="count-box"> 
							<img :src="this.urlSite + '/assets/ico/NightServiceKSC.png'" class="img-fluid w-75 m-0 p-0" alt="">
							<p>
								<b>Night Service & KSC</b>
							</p>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay="600">
						<div class="count-box">
							<img :src="this.urlSite + '/assets/ico/MaintenanceExpress.png'" class="img-fluid w-75 m-0 p-0" alt="">
							<p>
								<b>Maintenance Express</b>
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
    <!-- ======= Services Section ======= -->
		<section id="services" class="services">
			<div class="container">
        <div class="section-title">
            <h2>Facility</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/BodyRepair.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Body Repair</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/Towing.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Towing</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/Fogging.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Fogging</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/EnginePerformance.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Engine Performance</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/PickUpService.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Pick-up Service</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/ServiceAC.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Service AC</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="600">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/KartikaSariCare.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Kartika Sari Care</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="700">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/RematchDiskBrake.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Rematch Disk Brake</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="800">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/NightService.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Night Service</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="900">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/AutoDetailling.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Auto Detailing</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="1000">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/Washing.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Washing</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="1100">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/SpooringBallancing.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Spooring & Balancing</a></h4>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="1200">
              <div class="icon">
                <p>
                  <img :src="this.urlSite + '/assets/ico/KartikaSariCafe.png'" class="img-fluid w-25" alt="">
                </p>
              </div>
              <h4 class="title"><a style="cursor: pointer;user-select: none;">Kartika Sari Cafe</a></h4>
            </div>
          </div>
      </div>
		</section>

    <!-- News and Event -->
    <section id="services" class="services">
      <div class="container">
        <div class="section-title">
            <h2>News And Event</h2>
        </div>	
        <div class="row d-flex justify-content-center">
          <article v-for="(nn, index) in news" :key="index"  >
            <div class="item-wrapper">
              <a :href="'/DetailNews/' + nn.IdNews + '/NewsAndEvent'">
                <figure>
                  <div class="image" :style="'background-image:url(https://admin.kartikasari.co.id'+nn.LinkImage+');'"></div>
                  <div class="lighting"></div>
                </figure>
              </a>
              <div class="item-content">
                <h5>{{ nn.Judul }}</h5>
                <p class="text-white" v-html="nn.Detail.substring(0,100) + '... '"></p>
                <a class="btn btn-ksm btn-sm " href="#">Read More</a>
                <div class="author"> {{ nn.CreateDate.substring(0,10) }} </div>
              </div>
              
            </div>
          </article>
        </div>
      </div>
    </section>

    <!-- ======= Our Team Section ======= -->
		<section id="team" class="team">
			<div class="container">
        <div class="section-title">
            <h2>Our Team</h2>
            <h3><b>Sales Division</b></h3>
        </div>
        <div class="row">
          <div v-for="sale in sales.filter(x => x.TitleJabatan == 'Counter Sales' || x.TitleJabatan == 'Customer Service')" :key="sale.NamaLengkap" 
              class="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up">
            <div class="member">
              <div class="pic">
                <img :src="sale.GambarPath == '' ? 'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/' 
                  + sale.NamaLengkap + '.jpg&size=500' : 'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/' 
                  + sale.GambarPath + '&size=500'" class="img-fluid" alt="" style="max-height: 250px;">
              </div>
              <div class="member-info">
                <h4>{{sale.NamaLengkap}}</h4>
                <span>{{sale.TitleJabatan}}</span>
                <div class="social">
                    <a :href="'https://wa.me/' + sale.Telp" target="_blank" class="m-1">
                        <i class="fa-brands fa-whatsapp"></i>
                    </a>
                    <a href="" class="m-1"><i class="fa-brands fa-facebook"></i></a>
                    <a href="" class="m-1"><i class="fa-brands fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-title">
          <h3><b>Service Division</b></h3>
        </div>
        <div class="row">
          <div v-for="sale in sales.filter(x => x.TitleJabatan == 'Service')" :key="sale.NamaLengkap" 
              class="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up">
            <div class="member">
              <div class="pic" style="min-height: 260px;">
                <img :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/' 
                  + sale.NamaLengkap + '.jpg&size=500'" class="img-fluid" alt="" style="max-height: 250px;">
              </div>
              <div class="member-info">
                <h4>{{sale.NamaLengkap}}</h4>
                <span>{{sale.TitleJabatan}}</span>
                <div class="social">
                    <a :href="'https://wa.me/' + sale.Telp" target="_blank" class="m-1">
                        <i class="fa-brands fa-whatsapp"></i>
                    </a>
                    <a href="" class="m-1"><i class="fa-brands fa-facebook"></i></a>
                    <a href="" class="m-1"><i class="fa-brands fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="section-title">
        <h2>Contact Us</h2>
      </div>
      <div class="row">
        <div class="col-lg-6 d-flex align-items-stretch" data-aos="fade-up">
          <div class="info-box">
            <i class="bx bx-map"></i>
            <h3>Our Address</h3>
            <p><a href="https://www.google.com/maps/place/Toyota+Malang+-+Kartika+Sari+Mulia/@-7.93676,112.626422,20z/data=!4m5!3m4!1s0x0:0x621bd25c60acaac6!8m2!3d-7.9367599!4d112.6264217?hl=en-US" target="_blank">Jl. Puncak Borobudur No.1 Mojolangu, Kecamatan Lowokwaru<br>Kota Malang, Jawa Timur 65142</a></p>
          </div>
        </div>
        <div class="col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
          <div class="info-box">
            <i class="bx bx-envelope"></i>
            <h3>Email Us</h3>
            <p><a href="mailto:kartikasari.sosmed@gmail.com" target="_blank">kartikasari.sosmed@gmail</a></p>
          </div>
        </div>
        <div class="col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
          <div class="info-box ">
            <i class="bx bx-phone-call"></i>
            <h3>Call Us</h3>
            <p><a href="tel:+62341-479000" target="_blank">0341 - 479000 (Hunting) </a><br><a href="https://wa.me/6285105069000" target="_blank">0851 - 05069000 (WA)</a></p>
          </div>
        </div> 
        <div class="col-lg-12" data-aos="fade-up" data-aos-delay="300">
          <div class="info-box ">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d246.9750025567017!2d112.62639920123047!3d-7.936772286340935!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31e3725bffffffed%3A0x621bd25c60acaac6!2sToyota+Kartika+Sari+Mulia!5e0!3m2!1sen!2sid!4v1535690626017" width=100% height=450 frameborder=0 style=border:0 allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- carousel mobile -->
  <div v-else class=" mt-n2 mb-3">
    <button class="btn btn-sm btn-ksm" @click="cekIsLogin" 
      style="position: absolute; top: 100px; z-index: 9; left: 40%; right: 40%;">
      <i v-if="returnCust == 'Login'" class="fa-solid fa-user"></i>
      {{returnCust}}
    </button>
    
    <div id="carouselExampleControls" class="carousel slide carousel-fade " data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li> 
      </ol>
      <div class="carousel-inner carousel-fluid " id="carActive">
        <div v-if="custUnits.length > 0">
          <div v-for="(unit, index) in custUnits" :key="index" 
            :class="index == 0 ? 'carousel-item active' : 'carousel-item'">
            <a href="javascript:void(0)">
              <img class="d-block w-100 h-100" 
                :src="unit.Gambar1 
                ?'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+unit.Gambar1+'&size=500' 
                : 'https://admin.kartikasari.co.id/assets/MasterMobileKSM/img/notFoundImage.png'" 
                alt="First slide">
              <div class="carousel-caption " >
                <div class="row justify-content-center">
                  <div class="col-12 w-100 m-0 p-0 bg-ksm" style="border-radius: 15px;">
                    <p class="m-0 p-0 text-white" >
                      <strong v-if="unit.NamaKendaraan" >{{unit.NamaKendaraan}}</strong>
                      <strong v-else>-</strong>
                    </p>
                  </div>
                  <div class="col-4 w-100 m-0 p-0" >
                    <div class="card w-100 m-0 p-0" style="border-radius: 15px;" >
                      <div class="card-header m-0 p-0 bg-ksm" style="border-radius: 15px;">
                        <p class="m-0 p-0"><small>STNK EXPIRY</small></p>
                      </div>
                      <div class="card-body m-0 p-0">
                        <p class="m-0 p-0" style="color: #000;">
                          <small v-if="unit.STNKValidUntil">{{unit.STNKValidUntil}}</small>
                          <small v-else>-</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 w-100 m-0 p-0" >
                    <div class="card w-100 m-0 p-0" style="border-radius: 15px;" >
                      <div class="card-header m-0 p-0 bg-ksm" style="border-radius: 15px;">
                        <p class="m-0 p-0"><small>LICENSE PLATE</small></p>
                      </div>
                      <div class="card-body m-0 p-0">
                        <p class="m-0 p-0" style="color: #000;">
                          <small v-if="unit.NoPol">{{unit.NoPol}}</small>
                          <small v-else>-</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 w-100 m-0 p-0" >
                    <div class="card w-100 m-0 p-0" style="border-radius: 15px;" >
                      <div class="card-header m-0 p-0 bg-ksm" style="border-radius: 15px;">
                        <p class="m-0 p-0"><small>LAST SERVICE</small></p>
                      </div>
                      <div class="card-body m-0 p-0">
                        <p class="m-0 p-0" style="color: #000;">
                          <small v-if="unit.TglLastService">{{unit.TglLastService}}</small>
                          <small v-else>-</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div v-else>
          <div class="carousel-item active">
            <a href="#">
              <img class="d-block w-100" :src="this.urlSite + '/assets/MasterMobileKSM/img/notFoundImage.png'" 
                alt="First slide">
            </a>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <i class="fas fa-chevron-circle-left bg-dark p-1 rounded"></i>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <i class="fas fa-chevron-circle-right bg-dark p-1 rounded"></i>
        <span class="sr-only">Next</span>
      </a>
    </div>
    
    <!-- Confirmasi Email -->
    <div v-if="custConfirm.KonfEmail == '0'" class="alert alert-danger mt-0 d-flex justify-content-center" role="alert">
      <strong><a class="removeBlueLink text-ksm" @click="ConfirmEmail" href="javascript:void(0)" >Verification Your Email</a></strong>
    </div>

    <!-- promos -->
    <div v-if="promos.length > 0" class = "customTitle d-block" >
      <h5 class="d-inline float-left mainTitle">LATEST PROMO</h5> 
      <a class="d-inline float-right border border-dark pl-3 pr-3 btnViewAll" href="/Promos">View All</a>
    </div>
    <div v-if="promos.length > 0" class="d-flex flex-row flex-nowrap overflow-auto w-100">	
      <a v-for="(promo, index) in promos" :key="index" :href="'/DetailPromo/'+promo.Nama"
        class="mx-1 px-1"> 
        <div class="card card-block cardLatestPromo shadow p-1 mb-3 bg-white rounded" style="min-width: 305px;" >
          <img class="card-img-top cover w-100 " 
            :src="this.urlSite + promo.Link" 
            alt="Card image cap">
          
        </div>
      </a>
    </div>

    <!-- recomended -->
    <div class = "customTitle d-block" >
      <h5 class="d-inline float-left mainTitle">RECOMENDED</h5> 
      <a class="d-inline float-right border border-dark pl-3 pr-3 btnViewAll" href="/Recomended">View All</a>
    </div>
    <div class="d-flex flex-row flex-nowrap overflow-auto w-100">
      <div v-for="rec in recomendeds" :key="rec.Kode" class="card card-block mx-2 coverRecom shadow p-1 mb-3 bg-white rounded" 
        style="min-width: 200px;" >
					<img class="card-img-top " 
            v-bind:src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+rec.Image+'&size=500'" alt="Card image cap">
          <div class="card-body text-center d-flex flex-column">	
						<p class="card-title"><strong>{{rec.Keterangan}}</strong></p>
						<p class="card-text mt-auto">	START FROM <br> {{rec.Price}} </p>
						<a v-bind:href="'/DetailCar/' + rec.Jenis" 
              class="btn btn-block btnCustom mt-auto">View Detail</a>
					</div>
      </div>
    </div>

    <!-- spareparts -->
    <div class="col-12 w-100 text-left mx-0 px-0 my-2 py-2 ">
      <h5 class="mainTitle d-inline ml-2">SPAREPART</h5>
      <a class="d-inline float-right border border-dark pl-3 pr-3 mr-2 btnViewAll" href="/Shoping">View All</a>
    </div>
    <div class="col-12 m-0 p-0 w-100">
      <div class="roundedAll bg-light w-100">
        <div class="row m-0 p-0 w-100">
          <div class="col-12 w-100 m-0 p-0" >
            <div class="row card w-100 m-0 p-0 ">
              <table id="tblspareparts" class="table w-100">
                <thead>
                  <tr>
                    <th>IdSparepart</th>
                  </tr>
                </thead>
                <tbody class="row ml-0 mr-0 pl-0 pr-0">
                  <tr v-for="spare in spareparts" :key="spare.IdSparepart" 
                        class="col-6 ml-0 mr-0 pl-0 pr-0">
                    <td > 
                      <div class="card shadow ml-0 mr-0 w-100" >
                        <img class="card-img-top" v-bind:src="this.urlSite + spare.Image" alt="Card image cap">
                        <div class="card-body text-center d-flex flex-column pt-0 mt-0">
                          <p class="card-title"><strong>{{ spare.Keterangan }}</strong></p>
                          <p class="card-text mt-auto">	START FROM <br> <strong>Rp. {{ spare.Harga.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }},- </strong>	</p>
                          <a v-bind:href="'/DetailProduct/'+spare.IdSparepart"  
                            class="linkBorder pt-1 pb-1 btnViewAll mt-auto">View Detail</a>
                        </div>
                        <a @click="eventLike('sparepart', spare.IdSparepart)" >
                            <i :id="spare.IdSparepart" :class="spare.Status == null || spare.Status == '0' ? 'far fa-heart fa-2x btnLoveProduct':'fas fa-heart fa-2x btnLoveProduct'">
                            </i>
                        </a>
                      
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p id="beta"></p>
    
    <!-- news and event -->
    <div class = "customTitle d-block" >
      <h5 class="d-inline float-left mainTitle">NEWS AND EVENT</h5> 
      <a class="d-inline float-right border border-dark pl-3 pr-3 btnViewAll" href="/NewsAndEvent">View All</a>
    </div>
    <div class="d-flex flex-row flex-nowrap overflow-auto w-100" style="padding-bottom: 0px;">
      <a v-for="nn in news" :key="nn.IdNews" 
        :href="'/DetailNews/' + nn.IdNews + '/News And Event'"> 
        <div class="card card-block cardNewsAndEvent shadow  mb-3 bg-white rounded " 
          style="min-width: 305px;" >
          <img class="card-img-top coverEvent" :src="this.urlSite + nn.LinkImage" 
            alt="Card image cap">
          <div class="left-centered" >
            <span style="color:#0f2f56; background: #f2bdc5;
                          padding-right: 5px; padding-left: 5px;
                          border-radius: 5px;" v-html="nn.Detail.substring(0,100) + '... '"></span>
            <br><button class="btn btnCustom btn-sm " >Read More</button>
          </div> 
        </div>
      </a>
    </div>

    <!-- career -->
    <div v-if="careers.length > 0" class = "customTitle d-block" >
      <h5 class="d-inline float-left mainTitle">CAREER</h5> 
    </div>
    <div v-if="careers.length > 0" class="d-flex flex-row flex-nowrap overflow-auto w-100" style="padding-bottom: 100px;">
      <a v-for="nn in careers" :key="nn.Kode" 
        href="javascript:void(0)" @click="showImageCareer(nn.Image)"> 
        <div class="card card-block cardNewsAndEvent shadow  mb-3 bg-white rounded " 
          style="min-width: 305px;" >
          <img class="card-img-top coverEvent" :src="this.urlSite + '/assets/img/career/'+nn.Image" 
            alt="Card image cap">
          <div class="left-centered" >
            <span style="color:#0f2f56; background: #f2bdc5; 
                          padding-right: 5px; padding-left: 5px;
                          border-radius: 5px;" v-html="nn.Nama.substring(0,100) + '... '"></span>
          </div> 
        </div>
      </a>
    </div>
    
    <div style="min-height: 5vh;"></div>

  </div>

   <modal ref="mymodal" />
  
<!-- Modal image show -->
<div class="modal fade" id="modalShowImg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
        <img :src="this.urlSite + '/assets/img/career/' + showMyImage" class="w-100 roundedAll m-0 p-0"/> 
      
  </div>
</div>


</template>

<script>
import $ from 'jquery'
import { nytMixin } from "../assets/js/myMixin.js";
import modal from "../components/ModalAddUnit.vue";
import { loadingController } from '@ionic/vue';

const axios = require('axios');

export default {
  components: { modal },
  mixins: [nytMixin],
  data() {
    return{
      //users: [],
      ascending: true,
      searchValue: 'all',
      carousels: [],
      promos: [],
      filters:[],
      catalogues : [],
      recomendeds: [],
      thisMobile: false,
      spareparts: [],
      news: [],
      sales: [],
      custUnits: [],
      returnCust: 'Login',
      custConfirm: [],
      curScreen: null,
      careers: [],
      showMyImage: '',
    }
    
  },
  beforeMount() {
    //this.getGlobalTest();
    //this.postLogin();

    this.curScreen = screen.width;

    if(localStorage.Kode)
    {
      this.returnCust = '+Add Car';
    }
    else
    {
      this.returnCust = 'Login';
    }

    this.GetHomeCarousel();
    this.GetCarCatalogue();
    this.getMobile();
    this.GetCustUnit();
    this.GetVerificationCust();
  },
  mounted(){
    
    
  },
  methods: {
    async showImageCareer(image)
    {
      this.showMyImage = image;
      $("#modalShowImg").modal("show");
    },
    async getGlobalTest() {
      const response = await this.globalTest();
      //console.log(response.data.result);
      //this.users = response.data.result;
    },
    async GetHomeCarousel(){
      const resCarousel = await this.HomeCarousel();
      this.promos = resCarousel.data.promos;
      this.carousels = resCarousel.data.result;
      this.careers = resCarousel.data.career;
    },
    async GetCustUnit(){
      if(localStorage.Kode)
      {
        var form_data = {};   
        form_data['codeCust'] = localStorage.Kode;
        const resultData = await this.FuncPost(this.urlSite + '/HttpApi/GetUnitCustomer', form_data);
        this.custUnits = resultData.data.filter( x => x.ReleasedByOwner == '0' );
        //console.log(localStorage.Kode);
        //console.log(this.custUnits.length);
      }
    },
    async ConfirmEmail()
    {
      if(localStorage.Kode)
      {  
        const loading = await loadingController.create({
          message: 'Please Wait...',
          spinner: 'circles'
        });
        
        loading.present();

        var form_data = {};   
        form_data['emailCust'] = localStorage.Email;
        const resultData = await this.FuncPost(this.urlSite + '/HttpApi/KonfirmasiEmail', form_data);
        if(resultData.data.result.search("Email Sent") >= 0)
        {
          alert("Periksa Link Konfirmasi Di Email Anda");
        }
        else
        {
          alert("Periksa Koneksi Anda!!!");
        }
        loading.dismiss();
        //console.log(resultData);
      }
    },
    async GetVerificationCust(){
      if(localStorage.Kode)
      {
        var form_data = {};   
        form_data['codeCust'] = localStorage.Kode;
        const resultData = await this.FuncPost(this.urlSite + '/HttpApi/GetVerificationCust', form_data);
        this.custConfirm = resultData.data;
        //console.log(this.custConfirm);
      }
    },
    async GetCarCatalogue(){
      const resCatalogue = await this.HomeCarCatalogue();
      //console.log(resCatalogue.data.sales);
      this.filters = resCatalogue.data.filters;
      this.catalogues = resCatalogue.data.result;
      this.recomendeds = resCatalogue.data.recomendeds;
      this.news = resCatalogue.data.newsAndEvent;
      this.sales = resCatalogue.data.sales;
      //console.log(this.sales.NamaLengkap);
    },
    async getMobile(){
      const dataMobile = await this.isMobile();
      this.thisMobile = dataMobile;
    },
    async getSparepart() {
      var paramCUst = null;
      if(localStorage.Kode)
      {
        paramCUst = localStorage.Kode.replace("/", "-"); 
      }
      const dataSpareparts = await this.GetSparepart(paramCUst);
      this.spareparts = dataSpareparts.data.spareparts;
    },
    funcBandingkan: function(type)
    {
      this.insertUnitCompare(type);
      window.location.href="/CompareCar";
      //this.$router.push({ name: '/CompareCar', params: this.dataUnitCompare });
    },
    cekIsLogin: function()
    {
      if(localStorage.Kode)
      {
        this.$refs.mymodal.showModal();
      }  
      else {
        window.location.href="/LoginUser";
      }
    },
    eventLike: function(jenis, idSpare)
    {
      if(localStorage.Kode)
      {
        var dataLike = this.spareparts.filter(x => x.IdSparepart == idSpare);
        var objIndex = this.spareparts.findIndex((obj => obj.IdSparepart == idSpare));
        if(dataLike[0].Status == null || dataLike[0].Status == '0')
        {
          $("#"+idSpare).addClass( "fas" ); 
          this.spareparts[objIndex].Status = '1';
        }
        else
        {
          $("#"+idSpare).addClass( "far" );
          this.spareparts[objIndex].Status = '0';
        }
        var form_data = {};   
        form_data['Jenis'] = jenis;
        form_data['IdJenis'] = idSpare; 
        form_data['IdCust'] = localStorage.Kode; 
        form_data['StatusPhone'] = '1'; 
        form_data['Status'] = this.spareparts[objIndex].Status; 
        const resultData = this.FuncPost(this.urlSite + "/HttpApi/InsertUpdateLike", form_data);

      }
      else
      {
        window.location.href='/LoginUser';
      }
    },
    
    
  },
  created() {
    this.getSparepart();
  },
  updated(){
    $("#tblspareparts thead").hide();
    setTimeout(function() 
    { 
      $("#tblspareparts").DataTable({
        "lengthChange": false,
        "autoWidth": false,
        "retrieve": true,
      }); 
    }, 2000); //harus ada ini untuk datatable
  },
  computed:{
    filterCar() {
        let filCatalogues = this.catalogues;
        if(this.searchValue == 'all')
        {
          filCatalogues = this.catalogues;
        }
        else
        {
            filCatalogues = this.catalogues.filter(x => x.Model == this.searchValue)
        }
        
        return filCatalogues
    }
  }
};

</script>

