<template>
    <div style="padding-bottom: 15px;">
    </div>
    <div v-if="isShowHomeService" class="row w-100 m-0 p-0">
       
        <div class="col-12 w-100 my-1 p-0">
            <vue-google-autocomplete
                ref="address"
                id="map"
                classname="form-control form-control-sm"
                placeholder="Please type your address"
                v-on:placechanged="getAddressData"
                country="id"  
                @change="clickedAddress" 
            >
            </vue-google-autocomplete>
        </div>
        <div class="col-12 w-100 p-0 m-0">
            <GoogleMap api-key="AIzaSyDMP1_zMlTEy15t1s85ja6EPtxcp5IOBRU" 
                style="width: 100%; height: 550px" :center="clickLocation" :zoom="17"
                @click="getLocation" :clickableIcons="true"> 
                <Marker :options="{ position: center, url: 'https://cdn-icons-png.flaticon.com/512/814/814108.png', 
                    label: 'L', title: 'LADY LIBERTY' }" />
                <InfoWindow :options="{ position: clickLocation, content: resultLocation }" />
                
                <Marker :options="{ position: clickLocation, url:'@assets/images/shapes.svg' }" />
            </GoogleMap>
        </div>
        <div class="col-12 w-100 m-0 p-0 fixed-bottom"> 
            <button @click="nextOrder" class="btn btn-info btn-block text-white">NEXT</button>
        </div>
        
    </div>

</template>


<script>
import { defineComponent } from "vue";
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";
import { nytMixin } from "../assets/js/myMixin.js";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default defineComponent({
  mixins: [nytMixin],
  components: { GoogleMap, Marker, VueGoogleAutocomplete, InfoWindow },
  data(){
      return {
          clickLocation: [],
          resultLocation: 'Center',
          address: "",
          resLat: null,
          resLon: null,
          isShowHomeService : true,
          metodeBooking: 'APP-HOME',
      }
  },
  setup() {
    const center = { lat: -7.936601925602069, lng: 112.62635350227356 };
    
    return { center };
  },
  methods: {
    sayHi()
    {
        alert("hai");
    },
      /**
       * When the location found
       * @param {Object} addressData Data of the found location
       * @param {Object} placeResultData PlaceResult object
       * @param {String} id Input container ID
       */
      getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData;
        this.resLat = addressData.latitude;
        this.resLon = addressData.longitude;

        this.resultLocation = placeResultData.formatted_address;
      },
      
      getLocation(event){
            //console.log(event);
            var newLat = event.latLng.lat();
            var newLon = event.latLng.lng();
            this.clickLocation = { lat: newLat, lng: newLon };
            this.getAddress(newLat, newLon);

      },
      async getAddress(lat, lon)
      {
          const resultAddrCust = await this.getAddressCust(lat, lon);
          this.resultLocation = resultAddrCust.data.results[0].formatted_address;
          this.$refs.address.update(this.resultLocation);

        
      },
      clickedAddress(){
          if(this.resLat != null && this.resLon != null)
          {
            this.clickLocation = { lat: this.resLat, lng: this.resLon };
          }
      },
      nextOrder()
      {
          if(this.resultLocation)
          {
              this.isShowHomeService = false;
          }
          else
          {
              alert("Lengkapi Alamat Anda");
          }
      }

        
  },
  updated(){
  },
  mounted(){
      this.$refs.address.focus();
  },
  beforeMount(){
      this.clickLocation = { lat: -7.936601925602069, lng: 112.62635350227356 };
  }
});
</script>
