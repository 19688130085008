<template>
    <div class="row m-0 p-0 w-100"> 
        <div class="col-12 m-0 p-0 w-100"> 
            <img v-for="(img, index) in facilities" :key="index" 
                :src="img.LinkImage" :alt="img.LinkImage"  class="w-100"  >
        </div>
    </div>
    <div class="row w-100 " style="padding-bottom: 70px;"></div>
</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    setup() {
        
    },
    data() {
        return {
            facilities: [],
        }
    },
    beforeMount() {
        this.getFacilities();
    },
    methods: {
        async getFacilities(){
            var form_data = {};   
            const resultData = await this.FuncGet('Facilities', form_data);
            //console.log(resultData.data.results);
            this.facilities = resultData.data.results;
            console.log(this.facilities);
        },
    }
}
</script>