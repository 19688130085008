<template>
  <div class="row w-100 m-0 p-0">
    <div class="col-12 w-100 p-0 m-0">
      <GoogleMap api-key="AIzaSyDMP1_zMlTEy15t1s85ja6EPtxcp5IOBRU" 
          style="width: 100%; height: 250px;" 
          :center="center" :zoom="15">
          <Marker :options="{ position: center }" />
      </GoogleMap>
    </div> 
     
    <div class="col-12 w-100 p-0 m-0"> 
        <div class="card w-100 px-0 mx-0 py-2 my-2"> 
            <div class="row w-100 p-0 m-0">
                <div class="col-4 d-flex justify-content-center ">
                <a class="removeBlueLink" href="https://wa.me/6285105069000" target="_blank">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center"> 
                        <img src="@/assets/images/ic_phone_red.png" style="width: 35px;" />
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <label>Telephone</label> 
                    </div>
                  </div>
                </a>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <a class="removeBlueLink" target="_blank" href="https://www.google.com/maps/place/Toyota+Malang+-+Kartika+Sari+Mulia/@-7.9366244,112.6248072,17z/data=!4m5!3m4!1s0x31e3725bffffffed:0x621bd25c60acaac6!8m2!3d-7.9366085!4d112.6263629"> 
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center"> 
                                <img src="@/assets/images/ic_direction.png" style="width: 35px;" />
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label>Location</label> 
                            </div>
                        </div>
                    </a>
                </div> 
                <div class="col-4 d-flex justify-content-center">
                    <a class="removeBlueLink" target="_blank" href="https://kartikasari.co.id">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center"> 
                                <img src="@/assets/images/ic_website_red.png" style="width: 35px;" />
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label>Website</label> 
                            </div>
                        </div>
                    </a>
                </div> 
                <div class="col-4 d-flex justify-content-center">
                    <a class="removeBlueLink" target="_blank" href="https://www.facebook.com/tytksm/">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center"> 
                                <img src="@/assets/images/ic_facebook_red.png" style="width: 35px;" />
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label>Facebook</label> 
                            </div>
                        </div>
                    </a>
                </div> 
                <div class="col-4 d-flex justify-content-center">
                    <a class="removeBlueLink" target="_blank" href="https://www.instagram.com/kartikasarimulia/">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center"> 
                                <img src="@/assets/images/ic_ig_red.png" style="width: 35px;" />
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label>Instagram</label> 
                            </div>
                        </div>
                    </a>
                </div> 
                <div class="col-4 d-flex justify-content-center">
                    <a class="removeBlueLink" target="_blank" href="mailto: kartikasari.sosmed@gmail.com">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center"> 
                                <img src="@/assets/images/ic_email_red.png" style="width: 35px;" />
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label>Email</label> 
                            </div>
                        </div>
                    </a>
                </div>  
            </div>
        </div>
    </div>
    
    <!-- jam operasional -->
    <div class="col-12 w-100 p-0 m-0">
      <div class="card w-100 px-0 mx-0 py-2 my-2"> 
        <div class="card-body w-100 m-0 p-0">
          <div class="row w-100 m-0 p-0 text-left">
            <div class="col-2"><img src="@/assets/images/address.png" width="20" height="20" 
                    class="d-flex justify-content-center ml-1" alt=""></div> 
            <div class="col-10 ">Jl. Puncak Borobudur No.1 Malang</div> 
            <div class="col-2"><img src="@/assets/images/clock.png" width="25" height="25" 
                    class="d-flex justify-content-center" alt=""></div> 
            <div class="col-10">Senin: 08:00 - 16:30</div>
            <div class="col-10 offset-2">Selasa: 08:00 - 16:30</div> 
            <div class="col-10 offset-2">Rabu: 08:00 - 16:30</div> 
            <div class="col-10 offset-2">Kamis: 08:00 - 16:30</div> 
            <div class="col-10 offset-2">Jum'at: 08:00 - 16:30</div> 
            <div class="col-10 offset-2">Sabtu: 08:00 - 15:00</div> 
            <div class="col-10 offset-2">Minggu: 09:00 - 15:00</div> 
          </div>
        </div>
      </div>
    </div>
 
    <!-- Sales person -->
    <div class="col-12 w-100 p-0 m-0">
      <div class="card w-100 px-0 mx-0 py-2 my-2">
        <div class="card-body">
            <div class="row">
                <div class="col-9 "> 
                    <input v-model="valueSearch" @change="btnSearch" 
                        class="form-control form-control-sm roundedAll" 
                        type="text" name="searchSales" placeholder="Search" />
                </div>
                <div class="col-3 ml-0 pl-0"> 
                    <button @click="btnSearch" class="form-control form-control-sm btn-ksm">Search</button>
                </div>
                <div class="col-12 my-2" style="height: 400px; overflow: scroll;"> 
                    <div v-for="(person, index) in sales" :key="index" 
                        class="card shadows bg-white rounded w-100 p-0 mx-0 my-2">
                        <div class="row w-100">  
                            <div class="col-3 d-flex justify-content-center">
                                <img v-if="person.GambarPath" class="colorCar d-block w-75 m-2" style="border-radius: 50%;" 
                                    :data-image="person.GambarPath"
                                    :src="'https://apps.kartikasari.co.id/picture.php?source=api-integration/images/promoAltius/'+ person.GambarPath +'&size=1000'">                             
                                <img v-else class="colorCar d-block w-75 m-2" 
                                    style="border-radius: 50%;" 
                                    src="https://kartikasari.co.id/assets/img/ic_profile_picture.png">                            
                                </div> 
                                
                            <div class="col-9 d-flex justify-content-left">
                                <div class="row">
                                    <label class="col-12 w-100 m-0 p-0 text-left" ><small>{{person.Nama}}</small></label>
                                    <p class="col-12 w-100 m-0 p-0  text-left" ><small><em>{{person.Telp}}</em></small></p>
                                </div>
                            </div>     
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    
    <!-- Modal -->
    <div class="modal fade " id="phoneModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 my-1">
                            <a href="https://wa.me/6285105069000" target="_blank" class="btn btn-block btn-secondary btn-rounded btn-whatsapp">
                                <i class="fa-brands fa-whatsapp"></i>
                                Whatsapp
                            </a>
                        </div>
                        <div class="col-12 my-1">
                            <a href="https://wa.me/6285105069000" target="_blank" class="btn btn-block btn-ksm " >
                                <i class="fa-solid fa-phone"></i>
                                Phone
                            </a>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row w-100 " style="padding-bottom: 70px;"></div>

  </div>
</template>
<script>
import { GoogleMap, Marker } from "vue3-google-map";
import { nytMixin } from "../assets/js/myMixin.js";
import $ from 'jquery';
export default {
  components: { GoogleMap, Marker },
  mixins: [nytMixin],
  setup() {
    const center = { lat: -7.936601925602069, lng: 112.62635350227356 };

    return { center };
  },
  data(){
      return {
          sales: [],
          valueSearch: '',
          rawEmployes:[],
      }
  },
   methods: {
    showPhone()
    {
        $("#phoneModal").modal("show");
    },
    async GetSalesPerson()
    {
        var form_data = {};   
        const resultData = await this.FuncGet("/Branch", form_data);
        this.sales = resultData.data.results;
        this.rawEmployes = resultData.data.results;
    },
    btnSearch: function(){
        if(this.valueSearch == "")
        {
            this.sales = this.rawEmployes;
        }
        else
        {
            var search = new RegExp(this.valueSearch , 'i');
            this.sales = this.rawEmployes.filter(x => search.test(x.Nama));
        }
    },
        
  },
  beforeMount(){
      this.GetSalesPerson();
  }
}
</script>