<template>
<div class="container mt-3">
	<div class="row d-flex justify-content-center">
		<div class="col-12 ">
			<h3 class="text-center">FORM PENILAIAN DRIVER</h3>
			<p class="text-center ">Mohon berikan penilaian terhadap performa 
				& pelayanan driver</p>
			
		</div>
		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 " >
			<div class="card shadow w-100" style="border-radius: 20px;">
				<div class="card-header">
					<label class="float-left"><strong>{{ namaStaff }}</strong></label>
					<i v-if="namaStaff != 'Staff'" href="javascript:void(0)" 
                        class=" bg-ksm px-2 rounded text-white float-right " @click="logout">Logout</i>
					<i v-else href="javascript:void(0)" 
                        class=" bg-ksm px-2 rounded text-white float-right " @click="loginModal">Login</i>
				</div>
				<div class="card-body text-left">
					<form method="post" >
                        <div class="form-group">
                            <label for="status"><strong>Unit Kirim<em class="text-danger">*</em></strong></label>
                            <select class="form-control" v-model="form.NoRangka" @change="changeData($event)">
                                <option v-for="(sikk, index) in bstb" :key="index" :value="sikk.NoRangka" >
                                    {{ sikk.NamaSTNK + ' (' + sikk.NoRangka + ', ' + sikk.Keterangan + ')' }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="status"><strong>Status Kepemilikan<em class="text-danger">*</em></strong></label>
                            <div class="row mx-2">
                                <div class="col-8">
                                    <input class="form-check-input " type="radio" value="PRIBADI" 
                                        id="status1" :checked = "statusKepemilikan == 'PRIBADI'" @click="changeStatusKepemilikan('PRIBADI')" />
                                    <label class="form-check-label " for="status1" >
                                        KEPEMILIKAN PRIBADI
                                    </label>
                                </div>
                                <div class="col-4">
                                    <input class="form-check-input " type="radio" value="OTHER" 
                                        id="status2" :checked = "statusKepemilikan == 'OTHER'" @click="changeStatusKepemilikan('OTHER')" />
                                    <label class="form-check-label " for="status2" >
                                        OTHER
                                    </label>
                                </div>
                            </div>
                        </div>
						<div v-if="statusKepemilikan == 'PRIBADI'" class="form-group">
							<label for="nip"><strong>NIP Anda<em class="text-danger">*</em></strong></label>
							<input type="number" id="nip" class="form-control form-control-sm" 
								v-model="form.Nip" placeholder="305xxxxxx"  required />
						</div>
                        <div v-if="statusKepemilikan == 'OTHER'" class="form-group">
							<label for="statusPenerima"><strong>Status Penerima<em class="text-danger">*</em></strong></label>
							<input type="text" id="statusPenerima" class="form-control form-control-sm" 
								v-model="form.StatusPenerima" placeholder="Supir"  required />
						</div>
						<div v-if="statusKepemilikan == 'OTHER'" class="form-group">
							<label for="namaPenerima"><strong>Nama Penerima<em class="text-danger">*</em></strong></label>
							<input type="text" id="namaPenerima" class="form-control form-control-sm" 
								v-model="form.NamaPenerima" placeholder="Kartika"  required />
						</div>
                        <div class="form-group">
							<label for="phonePenerima"><strong>Phone Penerima<em class="text-danger">*</em></strong></label>
							<i href="javascript:void(0)" class="bg-ksm text-white float-right px-1 rounded" @click="addPhone">Add Phone</i>
							<input type="telp" id="phonePenerima" class="form-control form-control-sm" 
								v-model="form.PhonePenerima" placeholder="+62"  required />
							
						</div>
						<div class="form-group" v-for="(phone, index) in jmlPhone" :key="index">
							<div class="row m-0 p-0">
								<input  type="telp"  :id="'phoneAdditional'+index" class="form-control form-control-sm col-10" 
									placeholder="+62" v-model="jmlPhone[index]"/>
								<i href="javascript:void(0)" class="text-right px-1 rounded col-2 text-danger" @click="removePhone(index)" ><small>Remove</small></i>
							</div>
						</div>
						<div class="form-group">
							<label for="pekerjaanPasangan"><strong>Nama Driver<em class="text-danger">*</em></strong></label>
							<div v-for="(driver, index) in drivers" :key="index" class="form-check" @click="selectDriver(driver.Kode)">
								<input class="form-check-input changeTambahan" type="radio" :value="driver.Kode"  
									name="driver" :id="'driver'+driver.Kode"  >
								<label class="form-check-label" :for="'driver'+driver.Kode" >
									{{ driver.Nama }}
								</label>
							</div>
							
						</div>
						<div class="form-group ">
							<label for="waktu"><strong>Ketepatan Waktu<em class="text-danger">*</em></strong></label>
							<div class="form-control m-0 p-0 d-flex justify-content-center">
								<div class="rate mt-n2" >
									<input type="radio" id="star5" name="waktu" v-model="form.Waktu" value="5" />
									<label for="star5" title="text">5 stars</label>
									<input type="radio" id="star4" name="waktu" v-model="form.Waktu" value="4"  />
									<label for="star4" title="text">4 stars</label>
									<input type="radio" id="star3" name="waktu" v-model="form.Waktu" value="3"  />
									<label for="star3" title="text">3 stars</label>
									<input type="radio" id="star2" name="waktu" v-model="form.Waktu" value="2"  />
									<label for="star2" title="text">2 stars</label>
									<input type="radio" id="star1" name="waktu" v-model="form.Waktu" value="1"  />
									<label for="star1" title="text">1 star</label>
								</div>
							</div>
							<label class="text-danger"><small>*:terlambat lebih dari 30 menit, 
                                **:terlambat lebih dari 15 menit, 
                                ***:terlambat kurang dari 15 menit, 
                                ****: tepat waktu, 
                                *****: datang kurang dari 10 menit sebelum waktu penjemputan</small></label>
						</div>
						<div class="form-group">
							<label for="kebersihan"><strong>Kebersihan Kendaraan<em class="text-danger">*</em></strong></label>
							<div class="form-control m-0 p-0 d-flex justify-content-center">
								<div class="rate mt-n2">
									<input type="radio" id="star10" name="kebersihan" v-model="form.Kebersihan" value="5" />
									<label for="star10" title="text">5 stars</label>
									<input type="radio" id="star9" name="kebersihan" v-model="form.Kebersihan" value="4" />
									<label for="star9" title="text">4 stars</label>
									<input type="radio" id="star8" name="kebersihan" v-model="form.Kebersihan" value="3" />
									<label for="star8" title="text">3 stars</label>
									<input type="radio" id="star7" name="kebersihan" v-model="form.Kebersihan" value="2" />
									<label for="star7" title="text">2 stars</label>
									<input type="radio" id="star6" name="kebersihan" v-model="form.Kebersihan" value="1" />
									<label for="star6" title="text">1 star</label>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="etika"><strong>Etika Dan Keramahan Driver<em class="text-danger">*</em></strong></label>
							<div class="form-control m-0 p-0 d-flex justify-content-center">
								<div class="rate mt-n2">
									<input type="radio" id="star15" name="etika" v-model="form.Etika" value="5" />
									<label for="star15" title="text">5 stars</label>
									<input type="radio" id="star14" name="etika" v-model="form.Etika" value="4" />
									<label for="star14" title="text">4 stars</label>
									<input type="radio" id="star13" name="etika" v-model="form.Etika" value="3" />
									<label for="star13" title="text">3 stars</label>
									<input type="radio" id="star12" name="etika" v-model="form.Etika" value="2" />
									<label for="star12" title="text">2 stars</label>
									<input type="radio" id="star11" name="etika" v-model="form.Etika" value="1" />
									<label for="star11" title="text">1 star</label>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="feature"><strong>Penjelasan Feature Kendaraan<em class="text-danger">*</em></strong></label>
							<div class="form-control m-0 p-0 d-flex justify-content-center">
								<div class="rate mt-n2">
									<input type="radio" id="star20" name="feature" v-model="form.Feature" value="5" />
									<label for="star20" title="text">5 stars</label>
									<input type="radio" id="star19" name="feature" v-model="form.Feature" value="4" />
									<label for="star19" title="text">4 stars</label>
									<input type="radio" id="star18" name="feature" v-model="form.Feature" value="3" />
									<label for="star18" title="text">3 stars</label>
									<input type="radio" id="star17" name="feature" v-model="form.Feature" value="2" />
									<label for="star17" title="text">2 stars</label>
									<input type="radio" id="star16" name="feature" v-model="form.Feature" value="1" />
									<label for="star16" title="text">1 star</label>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="garansi"><strong>Penjelasan Garansi<em class="text-danger">*</em></strong></label>
							<div class="form-control m-0 p-0 d-flex justify-content-center">
								<div class="rate mt-n2">
									<input type="radio" id="star25" name="garansi" v-model="form.Garansi" value="5" />
									<label for="star25" title="text">5 stars</label>
									<input type="radio" id="star24" name="garansi" v-model="form.Garansi" value="4" />
									<label for="star24" title="text">4 stars</label>
									<input type="radio" id="star23" name="garansi" v-model="form.Garansi" value="3" />
									<label for="star23" title="text">3 stars</label>
									<input type="radio" id="star22" name="garansi" v-model="form.Garansi" value="2" />
									<label for="star22" title="text">2 stars</label>
									<input type="radio" id="star21" name="garansi" v-model="form.Garansi" value="1" />
									<label for="star21" title="text">1 star</label>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="service"><strong>Penjelasan Service Berkala<em class="text-danger">*</em></strong></label>
							<div class="form-control m-0 p-0 d-flex justify-content-center">
								<div class="rate mt-n2">
									<input type="radio" id="star30" name="service" v-model="form.ServiceBerkala" value="5" />
									<label for="star30" title="text">5 stars</label>
									<input type="radio" id="star29" name="service" v-model="form.ServiceBerkala" value="4" />
									<label for="star29" title="text">4 stars</label>
									<input type="radio" id="star28" name="service" v-model="form.ServiceBerkala" value="3" />
									<label for="star28" title="text">3 stars</label>
									<input type="radio" id="star27" name="service" v-model="form.ServiceBerkala" value="2" />
									<label for="star27" title="text">2 stars</label>
									<input type="radio" id="star26" name="service" v-model="form.ServiceBerkala" value="1" />
									<label for="star26" title="text">1 star</label>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="firstService"><strong>Jadwal Maksimal Service Pertama</strong></label>
							<input class="form-control" name="firstService" v-model="FirstService" readonly />
						</div>
						<div class="form-group" >
							<label for="ownerAgreement"><strong>Owner Agreement<em class="text-danger">*</em></strong></label>
							<div class="border rounded" style="background: #f5dcdf; ">
								<small>
								<p class="ml-1">Wajib Dilakukan Agar Hak Pemilik Toyota Tidak Gugur:</p>
								<ul class="ml-4">
									<li>Service ke 1 atau pertama WAJIB dilakukan di dealer penjual agar tambahan waranty tidak gugur.</li>
									<li>Laksanakan service berkala TEPAT WAKTU kelipatan 6 bulan (Kelihapatan 10K) mana yang tercapai dahulu agar free jasa dan free part tidak gugur.</li>
									<li>Laksanakan perawatan berkala di dealer penjual agar tambahan gratis biaya jasa dan free part ke 8 tidak gugur.</li>
								</ul>
								
								<p class="ml-1">Keuntungan Bagi Pemilik Kendaraan:</p>
								
								<ul class="ml-4">
									<li>Mendapat free part service berkala 3 tahun/ 6 kali.</li>
									<li>mendapat tambahan warranty 1 tahun/ 20.000 KM</li>
									<li>mendapat free jasa dan part service ke 7/ 70.000 KM tahun 3.5 tahun di KARTIKA SARI TOYOTA.</li>
								</ul>
								
								<p class="ml-1">Dengan menandatangani persetujuan ini, 
									pemilik kendaraan setuju untuk melakukan dan mematuhi syarat 
									dan ketentuan tertulis diatas.</p>
								</small>
								
							</div>
							<div class="ml-4">
								<input type="checkbox" class="form-check-input" v-model="form.Accept" id="ownerAgreement">
								<label class="form-check-label" for="ownerAgreement">Saya Setuju Dengan Agreement</label>
							</div>
						</div>
						<div class="form-group">
							<label for="signature-pad"><strong>Tanda Tangan Customer<em class="text-danger">*</em></strong>
                            </label>
							<!--
							<i href="javascript:void(0)" @click="setBackground">Test Background</i>
							-->
                            <i href="javascript:void(0)" 
                                class=" bg-ksm px-2 rounded text-white float-right " @click="clearSignature">Clear</i>
							<VueSignaturePad
                                class="border"
                                id="signature"
                                width="100%"
                                height="250px"
                                ref="signaturePad"
                                :options="options"
								
                            />
                            
						</div>

                        <div class="form-group ">
                            <label for="takePicture"><strong>Picture<em class="text-danger">*</em></strong></label>
                            <label for="takePicture" class="float-right bg-ksm rounded text-white px-1">Take Picture</label>
                            <input type="file" id="takePicture" accept="image/*" 
                                capture="camera"
                                @change="onFilePicked"
                                style="
                                opacity: 0;
                                position: absolute;
                                z-index: -1;" />
                            <input type="text" class="float-left form-control" :value="nameFile" readonly />
                        </div>
                        <div class="form-group">
                            <i class=" form-control text-white btn btn-sm btn-block btn-info" 
								style="margin-top: 50px;" @click="submitDec">SUBMIT</i>
                        </div>
					</form>
                    
				</div>
			</div>
		</div>
	</div>
    <div class="row " style="min-height: 75px;"></div>
</div>

<!-- Modal Login -->
<div class="modal fade" id="modalLogin" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content roundedCarousel">
        <div class="modal-body ">
            <div class="row">
                <div class="col-12">
                    <label class="d-flex justify-content-center"><strong>LOGIN</strong></label>
                </div>
                <div class="col-12">
                    <div class="form-group text-left">
                        <label for="id" >Your Phone</label>
                        <input type="text" id="id" 
							class="form-control form-control-sm roundedAll"
                            v-model="login.id" placeholder="admin@kartikasari.co.id"/>
                    </div>
					<div class="form-group text-left">
                        <label for="password" >Password</label>
                        <input type="password" id="password" 
							class="form-control form-control-sm roundedAll"
                            v-model="login.password" placeholder="Password" />
                    </div>
                    <button class="btn btn-block btn-sm btn-ksm text-white" @click="loginDEC">Login</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from 'jquery';
import { nytMixin } from "../assets/js/myMixin.js";
export default{
    mixins: [nytMixin],
    data() {
        return {
            drivers: [],
            nameFile: '',
            statusKepemilikan: 'PRIBADI',
            bstb: [],
			jmlPhone: [],
			form: {
				NoRangka: '',
				Nip: '',
				NamaPenerima: '',
				StatusPenerima: '',
				PhonePenerima: '',
				Driver: '',
				Waktu: '',
				Kebersihan: '',
				Etika: '',
				Feature: '',
				Garansi: '',
				ServiceBerkala: '',
				Ttd: '',
				Photo: '',
				Staff: '',
				StatusKepemilikan: '',
				Accept: false,
				AdditionalPhone: '',
			},
			login: {
				id: '',
				password: '',
			},
			namaStaff : 'Staff',
			FirstService: '',
        }
    },
    beforeMount(){
		this.getDrivers();
        var today = new Date();
		var dd = String(today.getDate() ).padStart(2, '0');
		var mm = String(today.getMonth() + 2).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		this.FirstService = mm + '/' + dd + '/' + yyyy;
		
    },
    mounted(){
		if(localStorage.loginDec != undefined)
        {
			const getLoginData = JSON.parse(localStorage.loginDec);
			this.form.Staff = getLoginData.KodeStaff;
			this.namaStaff = getLoginData.NamaLengkap;
		}
		else
		{
			$("#modalLogin").modal("show");
		}
    },
    updated() {
        
    },
    created() {
        
    },
    methods:{
		changeData(event)
		{
			const filterData = event.target.value;
			const getFilter = this.bstb.filter(x => x.NoRangka == filterData);
			if(getFilter[0].CpKode == null || getFilter[0].CpKode == 'null')
			{
				this.$toast.info("Customer Belum Install App");
			}
		},
		removePhone(index)
		{
			this.jmlPhone.splice(index,1);
		},
		addPhone()
		{
			this.jmlPhone.push('');
		},
		setBackground()
		{
			this.$refs.signaturePad.fromDataURL('https://irtaxconsulting.com/storage/2021/07/783px-Test-Logo.svg_.png');
		},
		loginModal()
		{
			$("#modalLogin").modal("show");
		},
		logout()
		{
			localStorage.clear();
			window.location.reload();
		},
		async loginDEC()
		{
			if(this.login.id != "" && this.login.password != "")
			{
				const resultData = await this.FuncPost(this.urlSite + '/HttpApi/LoginDec', this.login);
				localStorage.loginDec = JSON.stringify(resultData.data);
				const getLoginData = JSON.parse(localStorage.loginDec);
				this.form.Staff = getLoginData.KodeStaff;
				this.namaStaff = getLoginData.NamaLengkap;
				$("#modalLogin").modal("hide");
			}
			else
			{
				alert("Lengkapi Form Anda!");
			}
		},
		selectDriver(idDriver)
		{
			this.form.Driver = idDriver;
		},
		async submitDec()
		{
			const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
			if(isEmpty == false)
			{
				this.form.Ttd = data;
				this.form.AdditionalPhone = this.jmlPhone.toString();
				
				if(this.form.Staff == '' || this.form.Staff == undefined)
				{
					$("#modalLogin").modal("show");
				}
				else
				{

					if(this.form.NoRangka != '' && this.form.PhonePenerima != '' && this.form.Driver != ''
						&& this.form.Waktu != '' && this.form.Kebersihan != '' && this.form.Etika != ''
						&& this.form.Feature != '' && this.form.Garansi != '' && this.form.ServiceBerkala != '' 
						&& this.form.Ttd && this.form.Accept == true 
						&& this.form.Photo != '' && this.form.Staff != '' && this.form.StatusKepemilikan != '')
					{
						if(this.form.StatusKepemilikan == 'PRIBADI' && this.form.Nip == '')
						{
							alert("Lengkapi Form Anda");
						}
						else if(this.form.StatusKepemilikan == 'OTHER' && (this.form.StatusPenerima == '' || this.form.NamaPenerima == '') )
						{
							alert("Lengkapi Form Anda");
						}
						else
						{
							const resultData = await this.FuncPost(this.urlSite + '/HttpApi/InsertDec', this.form);
							if(resultData.data.search("Success") >= 0)
							{
								window.location.reload();
							}
							else
							{
								alert("Gagal Insert DEC, Cek Koneksi Anda!");
							}
						}
					}
					else
					{
						alert("Lengkapi Form Anda!");
					}
				}
			}
			else 
			{
				alert("Lengkapi Tanda Tangan Anda!");
			}
		},
        changeStatusKepemilikan(status)
        {
            this.statusKepemilikan = status;
			this.form.StatusKepemilikan = status;
        },
        onFilePicked (event) {
            const files = event.target.files;
            let filename = files[0].name;
            this.nameFile = filename;
            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
                this.form.Photo = fileReader.result;
            })
            fileReader.readAsDataURL(files[0]);
            //this.image = files[0];
			//console.log(files[0]);
        },
        clearSignature() {
            this.$refs.signaturePad.clearSignature();
        },
        async getDrivers()
        {
            var form_data = {};   
            const resultData = await this.FuncPost(this.urlSite + '/HttpApi/GetDrivers', form_data);
            this.drivers = resultData.data.drivers;
            this.bstb = resultData.data.bstb;
        }
    }
}
</script>

<style>
	*{
		margin: 0;
		padding: 0;
	}
	.rate {
		float: left;
		height: 46px;
		padding: 0 10px;
	}
	.rate:not(:checked) > input {
		position:absolute;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	.rate:not(:checked) > label {
		float:right;
		width:1em; 
		overflow:hidden;
		white-space:nowrap;
		cursor:pointer;
		font-size:30px;
		color:#ccc;
	}
	.rate:not(:checked) > label:before {
		content: '★ ';
	}
	.rate > input:checked ~ label {
		color: #ffc700;    
	}
	.rate:not(:checked) > label:hover,
	.rate:not(:checked) > label:hover ~ label {
		color: #deb217;  
	}
	.rate > input:checked + label:hover,
	.rate > input:checked + label:hover ~ label,
	.rate > input:checked ~ label:hover,
	.rate > input:checked ~ label:hover ~ label,
	.rate > label:hover ~ input:checked ~ label {
		color: #c59b08;
	}
    

</style>