import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import ShopingKSM from '../views/ShopingKSM.vue'
import DetailCar from '../components/DetailCar.vue'
import LoginUser from '../components/LoginUser.vue'
import WorkShopService from '../components/WorkShopService.vue'
import HomeService from '../components/HomeService.vue'
import Catalogue from '../components/Catalogue.vue'
import CompareCar from '../components/CompareCar.vue'
import DetailProduct from '../components/DetailProduct.vue'
import Recomended from '../components/Recomended.vue'
import NewsEvent from '../components/NewsEvent.vue'
import DetailNews from '../components/DetailNews.vue'
import OrderStatus from '../components/OrderStatus.vue'
import Branch from '../components/Branch.vue'
import Setting from '../components/Setting.vue'
import Facilities from '../components/Facilities.vue'
import About from '../components/About.vue'
import TOS from '../components/ThermOfService.vue'
import PAP from '../components/PrivacyAndPolicy.vue'
import Profile from '../components/Profile.vue'
import CreditSimulation from '../components/CreditSimulation.vue'
import UnitsCust from '../components/UnitsCust.vue'
import News from '../components/News.vue'
import TestDrive from '../components/TestDrive.vue'
import Booking from '../components/Booking.vue'
import Team from '../components/OurTeam.vue'
import SocialMedia from '../components/SocialMedia.vue'
import Konsultasi from '../components/Konsultasi.vue'
import Geo from '../components/Geolocator.vue'
import Coba from '../components/coba.vue'
import Notif from '../notification/notif.vue'
import NotifApp from '../notification/notifApp.vue'
import TestMap from '../notification/testMap.vue'
import ToPdf from '../notification/convertPdf.vue'
import HistoryNotif from '../components/HistoryNotif.vue'
import RegisterUser from '../components/RegisterUser.vue'
import DetailPromo from '../components/DetailPromo.vue'
import Promo from '../components/Promo.vue'
import TemplatePdfInvoice from '../components/TemplatePdfInvoice.vue'
import ContentLoader from '../components/ContentLoader.vue'
import NewTestMap from '../maps/testmap.vue'
import Dec from '../components/DEC.vue'
import Career from '../components/Career.vue'
import Voc from '../components/voc.vue'
import TestDriveNew from '../components/TestDriveNew.vue'
import MapTestDrive from '../maps/MapTestDrive.vue'
import TestJWT from '../notification/testWebJson.vue'
import ListQrSales from '../components/ListQrSales.vue'

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'home', component: HomePage },
  { path: '/shoping', name: 'shoping', component: ShopingKSM },
  { path: '/DetailCar/:param',name: 'Detail Car',component: DetailCar },
  { path: '/DetailPromo/:param',name: 'Detail Promo',component: DetailPromo },
  { path: '/LoginUser',name: 'Login User',component: LoginUser },
  { path: '/WORKSHOP',name: 'Workshop Service',component: WorkShopService },
  { path: '/CATALOGUE',name: 'Catalogue',component: Catalogue },
  { path: '/BROCHURE',name: 'Brochure',component: Catalogue, },
  { path: '/HOMESERVICE',name: 'Home Service',component: HomeService },
  { path: '/CompareCar',name: 'Bandingkan Mobil',component: CompareCar, },
  { path: '/DetailProduct/:param',name: 'Detail Product',component: DetailProduct },
  { path: '/Recomended',name: 'recomended',component: Recomended },
  { path: '/NewsAndEvent',name: 'NewsAndEvent',component: NewsEvent },
  { path: '/DetailNews/:param/:type',name: 'Detail News',component: DetailNews },
  { path: '/ORDERSTATUS',name : 'Order Status',component: OrderStatus },
  { path: '/BRANCH',name: 'Our Branch',component: Branch },
  { path: '/SETTING',name: 'Setting',component: Setting },
  { path: '/FACILITIES',name: 'Facilites',component: Facilities },
  { path: '/ABOUT',name: 'About',component: About },
  { path: '/TOS',name: 'Therm Of Service',component: TOS },
  { path: '/PAP',name: 'Privacy And Policy',component: PAP },
  { path: '/Profile',name: 'Profile',component: Profile },
  { path: '/CreditSimulation',name: 'Credit Simulation',component: CreditSimulation },
  { path: '/UNITS',name: 'Unit Customer',component: UnitsCust },
  { path: '/News/:param',name: 'News',component: News },
  { path: '/TestDrive',name: 'Test Drive',component: TestDrive },
  { path: '/BookingService',name: 'Booking Service',component: Booking },
  { path: '/OurTeam',name: 'Our Team',component: Team },
  { path: '/SocialMedia',name: 'Social Media',component: SocialMedia },
  { path: '/KonsultasiService',name: 'Konsultasi Service',component: Konsultasi },
  { path: '/KonsultasiUnit',name: 'Konsultasi Unit',component: TestDrive },
  { path: '/Geo',name: 'Geolocator',component: Geo },
  { path: '/Coba',name: 'Coba Aja',component: Coba },
  { path: '/Notif',name: 'Notif',component: Notif },
  { path: '/NotifApp',name: 'Notif App',component: NotifApp },
  { path: '/HISTORYNOTIF',name: 'History Notif',component: HistoryNotif },
  { path: '/RegisterUsers',name: 'Register User',component: RegisterUser },
  { path: '/Promos',name: 'Promos',component: Promo },
  { path: '/ToPdf',name: 'To Pdf',component: ToPdf },
  { path: '/TestMap',name: 'Test Map',component: TestMap },
  { path: '/TemplatePdfInvoice/:param/:param2',name: 'Generate Invoice PDF',component: TemplatePdfInvoice },
  { path: '/Loader',name: 'Loader',component: ContentLoader },
  { path: '/NewTestMap',name: 'New Test Map',component: NewTestMap },
  { path: '/Dec',name: 'Dec',component: Dec },
  { path: '/Career',name: 'Career',component: Career },
  { path: '/Voc',name: 'Voc',component: Voc },
  { path: '/TestDriveNew',name: 'Test Drive New',component: TestDriveNew },
  { path: '/MapTestDriveNew',name: 'Map Test Drive',component: MapTestDrive },
  { path: '/TestJwt',name: 'test jwt',component: TestJWT },
  { path: '/ListQrSales',name: 'Data QR Sales',component: ListQrSales },
]
 
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
